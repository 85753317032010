// context definition

import * as React from "react";
import MessagesApi from "../helpers/e-courrier/requests/messages";
import { guardCtx } from "./Guard";
import { socketCtx } from "./io";
import { notificationCtx } from "./notification";
import { sessionCtx } from "./session";

const messagesCtx = React.createContext({});

const MessagesContext = ({ children }) => {
  const notifsContext = React.useContext(notificationCtx);

  const [totalMessages, setTotalMessages] = React.useState(0);
  const [messages, setMessages] = React.useState({});

  const isUserAuthenticanted = React.useContext(guardCtx)?.isUserAuthenticanted;
  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const refreshNotifcations = notifsContext?.refreshNotifcations;

  React.useEffect(() => {
    if (isUserAuthenticanted) {
      if (currentApp?.toLowerCase() === "vizioo gec") {
        (async () => {
          let totalCount = 0;

          await MessagesApi.getTotalCount({
            userId: sessionStorage.getItem("userId"),
          })
            .then((result) => {
              totalCount = result?.totalCount;
            })
            .catch((error) => {
              console.log(
                "an error has occured when getting total messages count",
                error
              );
            });

          setTotalMessages(totalCount);
        })();
      }
    }
  }, [currentApp, isUserAuthenticanted]);

  React?.useEffect(() => {
    const notifAudio = new Audio("/sounds/notification.mp3");

    const messagesHandler = (payload) => {
      console.log("new message event here", payload, messages);

      if (payload?.from?.id?.toString() !== sessionStorage?.getItem("userId")) {
        notifAudio.play();
        refreshNotifcations();
      }

      setMessages({
        ...messages,
        [payload?.from?.id?.toString()]: [
          ...(messages[payload?.from?.id?.toString()] || []),
          payload,
        ],
      });
    };

    socketCtx.on("NEW_ANNOTATION", messagesHandler);

    return () => {
      socketCtx.off("NEW_ANNOTATION");
    };
  }, [messages]);

  return (
    <messagesCtx.Provider
      value={{
        totalMessages,
        messages,
        setMessages,
      }}
    >
      {children}
    </messagesCtx.Provider>
  );
};

export { messagesCtx };
export default MessagesContext;
