// context definition

import * as React from "react";
import SnackMessage from "../components/SnackMessage";
import NotificationsApi from "../helpers/e-courrier/requests/notifications";
import { courriersCtx } from "./courriers";
import { guardCtx } from "./Guard";
import { sessionCtx } from "./session";

const notificationCtx = React.createContext({});

const NotificationContext = ({ children }) => {
  const [messageParams, setMessageParams] = React?.useState({
    severity: "",
    text: "",
    visible: false,
  });

  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const showError = (message) => {
    setMessageParams({
      text: message,
      visible: true,
      severity: "error",
    });
  };

  const showWarning = (message) => {
    setMessageParams({
      text: message,
      visible: true,
      severity: "warning",
    });
  };

  const showInfo = (message) => {
    setMessageParams({
      text: message,
      visible: true,
      severity: "info",
    });
  };

  const showSuccess = (message) => {
    setMessageParams({
      text: message,
      visible: true,
      severity: "success",
    });
  };

  const handleClose = (event) => {
    event?.preventDefault();

    setMessageParams({
      visible: false,
      text: "",
      severity: "",
    });
  };

  const [notifications, setNotifications] = React.useState([]);

  const refreshNotifcations = (appName) => {
    (async () => {
      let _notifications = [];

      let includes = [];

      switch (appName) {
        case "vizioo e-signature":
          includes.push("new-signature");
          break;

        case "vizioo gec":
          includes.push("new-annotation", "new-courrier");
          break;

        default:
          break;
      }

      await NotificationsApi.getAll({
        userId: sessionStorage.getItem("userId"),
        includes,
      })
        .then((notifs) => {
          _notifications = notifs;
        })
        .catch((error) => {
          console.log("an error has occured getting notifications", error);
        });

      if (Array.isArray(_notifications)) {
        setNotifications(_notifications);
      }
    })();
  };

  const isUserAuthenticanted = React.useContext(guardCtx)?.isUserAuthenticanted;

  React.useEffect(() => {
    console.log("will process notifications here", isUserAuthenticanted);

    if (isUserAuthenticanted) {
      refreshNotifcations(currentApp?.toLowerCase());
    }
  }, [isUserAuthenticanted, currentApp]);

  return (
    <notificationCtx.Provider
      value={{
        messageParams,
        setMessageParams,
        showError,
        showInfo,
        showSuccess,
        showWarning,
        handleClose,
        notifications,
        refreshNotifcations,
      }}
    >
      {messageParams?.visible && <SnackMessage />}
      {children}
    </notificationCtx.Provider>
  );
};

export { notificationCtx };
export default NotificationContext;
