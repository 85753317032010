// categories data central

import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize";

class CategoryApi {
    static async getAll() {
        const categories = [];

        await lookup(`${BASE_URL}/api/categories`, {
            method: "GET",
            headers: getHeaders({})
        }).then(res => res.json().then(result =>  {
            if (Array.isArray(result?.data)) {
                categories.push(...strapiSanitize({object: result?.data}))
            }
        })).catch(error => {
            console.log("an error has occured when fetching categories", error);
            
        })

        return categories
    }

    static async remove({ id }) {

        let status = null;

        await lookup(`${BASE_URL}/api/categories/${id}`, {
            method: "DELETE",
            headers: getHeaders({})
        }).then(res => res.json().then(result => {
            if (result) {
                status = result
            }
        })).catch(error => {
            console.log("an error has occured when remoing a category", error);
            
        });

        return status
    }

    static async create(data) {
        let status = null;

        await lookup(`${BASE_URL}/api/categories`, {
            method: "POST",
            headers: getHeaders({}),
            body: JSON.stringify(data)
        }).then(res => res.json().then(result => {
            if (result) {
                status = result;

            }
        })).catch(error => {
            console.log("an error has occured when creating a category", error);
            
        })

        return status
    }
}

export default CategoryApi
