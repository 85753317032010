// apps page component defintion

import React from "react";
import { useTheme, Box, Typography, Stack, Grid, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { sessionCtx } from "../../context/session";
import { socketCtx } from "../../context/io";
import { guardCtx } from "../../context/Guard";

const Apps = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const authContext = React?.useContext(guardCtx);

  const apps = [
    {
      title: "Vizioo E-signature",
      link: "/apps/e-signature/dashboard",
      description: "Créer de nouveau documents et faire signer en un click",
    },
    {
      title: "Vizioo GEC",
      link: "/apps/e-courrier/dashboard",
      description:
        "Envoyer directement les courriers reçus aux entités traitantes directement",
    },
    {
      title: "Vizioo Archivage",
      link: "/apps/archiving/dashboard",
      description: "Numérisation et stockage des documents par entités",
    },
  ];

  const handleLogout = authContext?.handleLogout;

  const sessionContext = React?.useContext(sessionCtx);

  const setSession = sessionContext?.setSession;
  const session = sessionContext?.session;

  const checkSession = authContext?.checkSession;

  React.useEffect(() => {
    checkSession(true);
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        heigh: "100vh",
        bgcolor: `${theme?.palette?.primary}10`,
        backgroundImage: "url(/images/apps.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: "1.5rem",
        }}
      >
        <img
          src={"/images/logo-white.png"}
          style={{ maxWidth: "150px", marginTop: "1rem" }}
        />
        <Button
          onClick={handleLogout}
          startIcon={<Logout />}
          className="btn-secondary"
          sx={{
            bgcolor: `${"#EEF7F9"}`,
            fontSize: "14px",
            textTransform: "capitalize",
            "&:hover": {
              bgcolor: `${"#EEF7F9"}`,
            },
            py: ".3rem",
            color: "#227378",
            borderRadius: "1rem",
            mt: "1rem",
            px: "1rem",
            width: "max-content",
          }}
        >
          Déconnexion
        </Button>
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          width: "90%",
          alignItems: "flex-start",
          px: "5vw",
          py: "10vh",
          // boxShadow: "0px 0px 12px -4px #BDC2C6;",
          mx: "auto",
          borderRadius: "25px",
          mt: "10%",
          bgcolor: theme?.palette?.common?.white,
        }}
      >
        {/* <Typography
          sx={{
            color: theme?.palette?,
            fontSize: "18px",
            fontWeight: theme?.typography?.fontWeightBold,
          }}
        >
          APPLICATIONS
        </Typography> */}
        <Grid
          container
          sx={{
            width: "100%",
            overflow: "hidden",
            // mt: "2rem",
            height: "max-content",
            alignItems: "stretch",
          }}
        >
          {apps?.map((target) => {
            return (
              <Grid
                item
                sm={12}
                md={4}
                sx={{
                  py: ".5rem",
                  px: ".5rem",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  sx={{
                    boxShadow: "0px 0px 12px -4px #BDC2C6;",
                    p: "1rem",
                    height: "100%",
                    borderRadius: "15px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{
                      // bgcolor: theme?.palette?.primary?.main,
                      overflow: "hidden",
                      borderRadius: "10px 0px 0px 10px",
                      height: "100%",
                    }}
                  >
                    <img
                      src={"/images/illu.png"}
                      style={{
                        overflow: "hidden",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Stack
                      direction={"column"}
                      sx={{
                        width: "100%",
                        alignItems: "flex-start",
                        pl: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme?.palette?.common?.black,
                          fontWeight: theme?.typography?.fontWeightRegular,
                        }}
                      >
                        {target?.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: theme?.palette?.grey[500],
                          fontWeight: theme?.typography?.fontWeightRegular,
                          fontSize: "12px",
                        }}
                      >
                        {target?.description}
                      </Typography>
                      <Button
                        onClick={(event) => {
                          event?.preventDefault();

                          setSession({
                            ...session,
                            currentAppName: target?.title,
                          });

                          sessionStorage?.setItem(
                            "currentAppName",
                            target?.title
                          );

                          navigate(target?.link);
                        }}
                        className="btn-secodary flex-auto"
                        sx={{
                          bgcolor: `${theme?.palette?.primary?.main}`,
                          fontSize: "14px",
                          textTransform: "capitalize",
                          "&:hover": {
                            bgcolor: `${theme?.palette?.primary?.main}`,
                          },
                          py: ".3rem",
                          color: theme?.palette?.common?.white,
                          borderRadius: "1rem",
                          mt: "1rem",
                          px: "1rem",
                          boxShadow: "none",
                        }}
                      >
                        Accéder
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
      <Stack
        direction={"row"}
        sx={{
          px: "1.5rem",
          alignItems: "center",
          position: "fixed",
          right: 0,
          left: 0,
          bottom: 15,
        }}
      >
        <Typography
          sx={{
            color: theme?.palette?.grey[500],
            fontWeight: theme?.typography?.fontWeightRegular,
            fontSize: "14px",
            width: "100%",
            textAlign: "center",
          }}
        >
          Vizioo, une solution GED de Rhinoceros Software SAS
        </Typography>
      </Stack>
    </Box>
  );
};

export default Apps;
