// utility function definition

import * as React from "react";

const lookup = async (url, options) => {

    window.setLoading(true, "_guards");

    return fetch(url, options)
        .then((res) => {
            setTimeout(() => {
                window.setLoading(false, "_guards");
            }, 100);

            console.log("gotten res from lookup", res);

            return new Promise((resolve, reject) => {
                resolve(res);
            });
        })
        .catch((error) => {
            window.setLoading(false, "_guards");

            console.log("gotten error from lookup", error);

            return new Promise((resolve, reject) => {
                reject(error);
            });
        });
};

export default lookup
