// component definition
import * as React from "react";

import {
  useTheme,
  Box,
  Stack,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@mui/material";

import DragUpload from "./DragUpload";
import { rolesCtx } from "../../context/rolesCtx";
import { contactsCtx } from "../../context/contacts";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { entitiesCtx } from "../../context/entities";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { usersCtx } from "../../context/users";
import { courriersCtx } from "../../context/courriers";
import OperationApi from "../../helpers/e-courrier/requests/operations";
import { settingsCtx } from "../../context/settings";

const uploadFields = [];

const Configuration = ({
  entities = [],
  categories = [],
  tags = [],
  fieldsMap,
  setFieldsMap,
}) => {
  const theme = useTheme();

  const editedDocId = new URLSearchParams(window?.location?.search).get(
    "archive_id"
  );

  const settingsFields = [
    {
      title: "Titre",
      field: "title",
      required: true,
    },
    {
      title: "Mots clés",
      field: "tags",
      required: true,
      options: tags,
    },
    {
      title: "Catégorie",
      field: "categories",
      required: true,
      options: categories,
    },
    {
      title: "Entité",
      field: "entities",
      required: true,
      options: entities,
    },
    {
      title: "Commentaires",
      field: "comments",
      required: true,
    },
  ];

  const mapObjects = {
    entities: entities,
    categories: categories,
    tags: tags,
  };

  return (
    <Stack
      direction={"column"}
      sx={{
        alignItems: "flex-start",
        width: "100%",
        p: "1.5rem",
      }}
    >
      <Typography
        sx={{
          color: theme?.palette?.primary?.main,
          fontWeight: theme?.typography?.fontWeightBold,
        }}
      >
        Informations générales
      </Typography>
      <Grid
        container
        sx={{
          width: "100%",
        }}
      >
        {settingsFields?.map((target, index) => {
          return ["entities", "categories", "tags"]?.includes(target?.field) ? (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <Autocomplete
                onChange={(event, object) => {
                  event?.preventDefault();

                  setFieldsMap({ ...fieldsMap, [target?.field]: object });
                }}
                size={"small"}
                name={target?.field}
                id="contacts--el"
                options={target?.options}
                value={fieldsMap[target?.field] || []}
                multiple
                disableCloseOnSelect
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option?.name}</li>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={target?.title}
                    placeholder={target?.title}
                  />
                )}
              />
            </Grid>
          ) : target?.field === "entryDate" ? (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <DatePicker
                label={target?.title}
                onChange={(newValue) => {}}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          ) : target?.field === "entryTime" ? (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <TimePicker
                label={target?.title}
                onChange={(newValue) => {}}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
            </Grid>
          ) : (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <TextField
                name={target?.field}
                placeholder={target?.title}
                label={target?.title}
                type={
                  target?.field === "password"
                    ? "password"
                    : target?.field === "email"
                    ? "email"
                    : "text"
                }
                size={"small"}
                // autoComplete="new-password"
                sx={{
                  width: "100%",
                }}
                required={target?.required}
                rows={target?.field === "comments" ? 4 : 1}
                multiline={target?.field === "comments" ? true : false}
                onChange={(event) => {
                  event?.preventDefault();

                  setFieldsMap({
                    ...fieldsMap,
                    [target?.field]: event?.target?.value,
                  });
                }}
                value={fieldsMap[target?.field]}
              />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

const Upload = ({}) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        alignItems: "flex-start",
        width: "100%",
        p: "1.5rem",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
        }}
      >
        {uploadFields?.map((target, index) => {
          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <TextField
                name={target?.field}
                placeholder={target?.title}
                type={
                  target?.field === "password"
                    ? "password"
                    : target?.field === "email"
                    ? "email"
                    : "text"
                }
                size={"small"}
                // autoComplete="new-password"
                sx={{
                  width: "100%",
                }}
                required={target?.required}
                onChange={(event) => {
                  event?.preventDefault();

                  setFieldsMap({
                    ...fieldsMap,
                    [target?.field]: event?.target?.value,
                  });
                }}
                value={fieldsMap[target?.field] || null}
              />
            </Grid>
          );
        })}
      </Grid>
      <DragUpload />
    </Stack>
  );
};

const ConfigureStep = ({ step }) => {
  const theme = useTheme();

  const entitiesContext = React.useContext(entitiesCtx);

  const users = React.useContext(usersCtx)?.users;

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const settingContext = React.useContext(settingsCtx);

  const [fieldsMap, setFieldsMap] = React?.useState({});

  React?.useEffect(() => {
    window?.sessionStorage.setItem("__sae_fields__", JSON.stringify(fieldsMap));
  }, [fieldsMap]);

  const finalElement = {
    1: (
      <Configuration
        tags={settingContext?.tags}
        categories={settingContext?.categories}
        entities={entitiesContext?.entities}
        fieldsMap={fieldsMap}
        setFieldsMap={setFieldsMap}
      />
    ),
    2: <Upload />,
  };

  return finalElement[step];
};

export default ConfigureStep;
