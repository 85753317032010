// component definition
import * as React from "react";

import Layout from "./Layout";
import {
  useTheme,
  Stack,
  Box,
  Typography,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import NavigationLine from "./NavigationLine";
import { usersCtx } from "../context/users";
import getHeaders from "../utils/getHeaders";
import { useNavigate } from "react-router-dom";

import { BASE_URL } from "../constants/api";
import { rolesCtx } from "../context/rolesCtx";
import lookup from "../utils/lookup";

import { notificationCtx } from "../context/notification";
import { entitiesCtx } from "../context/entities";

const InvitedUser = ({}) => {
  const theme = useTheme();

  const showError = React?.useContext(notificationCtx)?.showError;
  const showInfo = React?.useContext(notificationCtx)?.showInfo;
  const showSuccess = React?.useContext(notificationCtx)?.showSuccess;

  const navigate = useNavigate();

  const roles = React?.useContext(rolesCtx)?.roles;

  const editMode = window?.location?.pathname?.includes("/user/edit");

  const usersContext = React?.useContext(usersCtx);
  const entitiesContext = React.useContext(entitiesCtx);

  const currUserId = new URLSearchParams(window?.location?.search)?.get("id");

  const users = usersContext?.users;

  const [defaultUser, setDefaultUser] = React?.useState(
    users?.find(
      (target) => target?.id?.toString() === currUserId?.toString()
    ) || {}
  );

  const fetchUsers = usersContext?.fetchUsers;

  React?.useEffect(() => {
    const _user =
      users?.find(
        (target) => target?.id?.toString() === currUserId?.toString()
      ) || {};

    setDefaultUser(_user);

    setEntities(
      _user?.entities?.map((target) => {
        return entitiesContext?.entities?.find((entity) => {
          return entity?.id?.toString() === target?.id?.toString();
        });
      })
    );
  }, [users]);

  const identitiesMap = [
    {
      field: "username",
      title: "Nom complet",
      required: true,
    },
    {
      field: "email",
      title: "Addresse mail",
      required: true,
    },
    {
      field: "password",
      title: "Mot de passe",
      required: false,
    },
    {
      field: "entities",
      title: "Entités",
      required: false,
      options: entitiesContext?.entities,
    },
  ];

  const rolesFields = [];

  if (editMode && identitiesMap?.every((target) => target?.field !== "role")) {
    identitiesMap?.push({
      field: "role",
      title: "Rôle",
      required: true,
    });
  }

  const [entities, setEntities] = React.useState([]);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (
      window.confirm(
        `Voulez-vous procéder à la ${editMode ? "mise à jour" : "création"} ?`
      )
    ) {
      const submitObj = {};

      const formData = new FormData(event.currentTarget);

      for (let [key, value] of formData.entries()) {
        submitObj[key] = value;

        // alert(value);
      }

      if (submitObj["password"]?.length < 3) {
        delete submitObj["password"];
      }

      submitObj["entities"] = entities?.map((target) => {
        return target?.id?.toString();
      });

      console.log("received data for user action", submitObj, editMode);

      if (editMode) {
        await lookup(`${BASE_URL}/api/users/${currUserId}`, {
          method: "PUT",
          headers: getHeaders({}),
          body: JSON.stringify(submitObj),
        })
          .then((res) =>
            res.json().then(async (res) => {
              console.log("received data after update", res);

              await fetchUsers()
                .then(() => {
                  console.log("successfully updated users list");
                })
                .catch((error) => {
                  console.log(
                    "an error has occured when updating users",
                    error
                  );

                  showError("Une erreur est survenue. Réessayez");
                });
              showSuccess("Modification effectuée");

              navigate("/settings/users");
            })
          )
          .catch((error) => {
            console.log("an error has occured when updating a user", error);
          });
      } else {
        await lookup(`${BASE_URL}/api/auth/local/register`, {
          method: "POST",
          headers: getHeaders({}),
          body: JSON.stringify(submitObj),
        })
          .then(async (res) =>
            res.json().then(async (res) => {
              console.log("received data after create", res);

              await fetchUsers()
                .then(() => {
                  console.log("successfully updated users list");
                })
                .catch((error) => {
                  console.log(
                    "an error has occured when updating users",
                    error
                  );

                  showError("Une erreur est survenue. Réessayez");
                });

              showSuccess("Utilisateur créé");
              navigate("/settings/users");
            })
          )
          .catch((error) => {
            console.log("an error has occured when creating a user", error);
          });
      }
    }
  };

  const handleDelete = async (event) => {
    event?.preventDefault();

    if (window.confirm("Voulez-vous supprimer l'utilisateur ?")) {
      await lookup(`${BASE_URL}/api/users/${currUserId}`, {
        headers: getHeaders({}),
        method: "DELETE",
      })
        .then((res) =>
          res.json().then(async (res) => {
            console.log("received data after user deletion", res);

            await fetchUsers()
              .then(() => {
                console.log("successfully updated users list");
              })
              .catch((error) => {
                console.log("an error has occured when updating users", error);

                showError("Une erreur est survenue. Réessayez");
              });

            showSuccess("Suppression effectuée");

            navigate("/settings/users");
          })
        )
        .catch((error) => {
          console.log("an error has occured when removing a user", error);
        });
    }
  };

  const Form = () => {
    return (
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: theme?.palette?.common?.white,
          width: "100%",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            alignItems: "flex-start",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: theme?.palette?.primary?.main,
                fontWeight: theme?.typography?.fontWeightBold,
              }}
            >
              Identité
            </Typography>
            <Grid
              container
              sx={{
                width: "100%",
              }}
            >
              {identitiesMap?.map((target, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    sx={{
                      p: ".5rem",
                    }}
                  >
                    {target?.field === "role" ? (
                      <FormControl
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size={"small"}
                        autoComplete="off"
                      >
                        <InputLabel>{target?.title}</InputLabel>
                        <Select
                          size={"small"}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label={target?.title}
                          placeholder={target?.title}
                          name={target?.field}
                          defaultValue={
                            (editMode ? defaultUser["role"] : null) || null
                          }
                          required={target?.required}
                          // autoComplete="off"
                        >
                          {roles?.map((role) => {
                            return (
                              <MenuItem value={role?.id}>{role?.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    ) : target?.options?.length >= 0 ? (
                      <Autocomplete
                        size="small"
                        disablePortal
                        onChange={(event, entities) => {
                          event?.preventDefault();

                          setEntities(entities);
                        }}
                        id="combo-box-demo"
                        multiple
                        options={target?.options}
                        value={entities}
                        getOptionLabel={(option) => `${option?.name}`}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>{`${option?.name}`}</li>
                        )}
                        disableCloseOnSelect
                        sx={{
                          minWidth: "12rem",
                          width: "100%",
                          maxWidth: "100%",
                        }}
                        renderInput={(params) => (
                          <TextField
                            size={"small"}
                            {...params}
                            // label={title}
                            placeholder={target?.title}
                            label={target?.title}
                            variant={"outlined"}
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        name={target?.field}
                        placeholder={target?.title}
                        label={target?.title}
                        type={
                          target?.field === "password"
                            ? "password"
                            : target?.field === "email"
                            ? "email"
                            : "text"
                        }
                        size={"small"}
                        // autoComplete="new-password"
                        sx={{
                          width: "100%",
                        }}
                        defaultValue={
                          target?.field === "password"
                            ? null
                            : (editMode
                                ? defaultUser[
                                    target?.field === "username"
                                      ? "fullName"
                                      : target.field
                                  ]
                                : null) || null
                        }
                        required={target?.required}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <button
            type="submit"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: ".4rem",
              paddingBottom: ".4rem",
              marginTop: "1rem",
              marginLeft: ".5rem",
              maxWidth: "max-content",
            }}
            className="btn-primary flex-auto"
          >
            {editMode ? "Mettre à jour" : "Créer"}
          </button>
          {editMode ? (
            <button
              onClick={handleDelete}
              type="submit"
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: ".4rem",
                paddingBottom: ".4rem",
                marginTop: "1rem",
                marginLeft: ".5rem",
                maxWidth: "max-content",
                backgroundColor: theme?.palette?.error?.main,
              }}
              className="btn-primary flex-auto"
            >
              Suprimer
            </button>
          ) : null}
        </Stack>
      </form>
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <NavigationLine
          firstTitle={"Paramètres"}
          secondTitle={
            editMode ? defaultUser["fullName"] : "Nouvel utilisateur"
          }
          backLink={"/settings/users"}
        />
        {editMode ? Object.keys(defaultUser)?.length > 0 && <Form /> : <Form />}
      </Box>
    </Layout>
  );
};

export default InvitedUser;
