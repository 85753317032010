// page component definition
import * as React from "react";

import Layout from "../../components/Layout";
import { Link, useNavigate } from "react-router-dom";

import {
  useTheme,
  Stack,
  Typography,
  Box,
  Grid,
  Chip,
  useMediaQuery,
  Avatar,
  IconButton,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import NavigationLine from "../../components/NavigationLine";
import { usersCtx } from "../../context/users";
import { rolesCtx } from "../../context/rolesCtx";
import { Search } from "react-feather";

const SettingsAll = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const usersContext = React?.useContext(usersCtx);

  const settingsList = [
    {
      title: "Documents",
      link: "/settings/documents",
      description:
        "Gestion de document. Reglages de composant liés aux documents",
    },
    {
      title: "Utilisateurs",
      link: "/settings/users",
      description:
        "Gestion des utilisateurs du logiciel, ajout, suppression, rôle, mots de passe etc.",
    },
    {
      title: "Archives",
      link: "/settings/archives",
      description:
        "Configuration des paramètres des composants liés à l'archivage",
    },
    {
      title: "Entité",
      link: "/settings/entities",
      description:
        "Graphe de differentes entité de l'organisation pour une meilleure accessibilité",
    },
  ];

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            maxHeight: !screen900 ? "calc(100vh - 160px)" : undefined,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {settingsList?.map((target) => {
            return (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                sx={{
                  alignSelf: "stretch",
                  p: ".5rem",
                }}
              >
                <Link
                  to={target?.link}
                  style={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      p: "1rem",
                      width: "100%",
                      borderRadius: "10px",
                      border: `.5px solid ${theme?.palette?.primary?.main}`,
                      overflowX: "hidden",
                      bgcolor: theme?.palette?.common?.white,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.fontWeightBold,
                        color: theme?.palette?.primary?.main,
                        mb: ".5rem",
                      }}
                    >
                      {target?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme?.typography?.fontWeightRegular,
                        color: theme?.palette?.grey[700],
                      }}
                    >
                      {target?.description}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Layout>
  );
};

export default SettingsAll;
