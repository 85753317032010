// page component definition

import React, { useRef } from "react";
import Layout from "../../../components/Layout";
import {
  Stack,
  Box,
  Grid,
  useTheme,
  TextField,
  Typography,
  Avatar,
  Autocomplete,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Add, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import InviteesModal from "../../../components/InviteesModal";
import { usersCtx } from "../../../context/users";
import ProjectApi from "../../../helpers/e-courrier/requests/projects";
import { useNavigate, useParams } from "react-router-dom";
import { notificationCtx } from "../../../context/notification";
import MessagesApi from "../../../helpers/e-courrier/requests/messages";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ProjectEditor = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const notifContext = React.useContext(notificationCtx);

  const showError = notifContext?.showError;
  const showSuccess = notifContext?.showSuccess;
  const showWarning = notifContext?.showWarning;

  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  const formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];

  const [projectContent, setProjectContent] = React.useState("");

  const handleProcedureContentChange = (content) => {
    console.log("content---->", content);

    setProjectContent(content);
  };

  const [projectComments, setProjectComments] = React.useState([]);

  const [currentComment, setCurrentComment] = React.useState({
    tos: [],
    from: sessionStorage?.getItem("userId"),
    text: "",
  });

  const handleComment = async (event) => {
    event?.preventDefault();

    const submitObj = {};
    const formData = new FormData(event?.target);

    for (const [key, value] of formData.entries()) {
      submitObj[key] = value;
    }

    console.log("computed comment data ", submitObj);

    const annotation = {
      ...currentComment,
      text: submitObj["comment"],
      from: {
        id: sessionStorage?.getItem("userId"),
        username: sessionStorage?.getItem("username"),
      },
      project: projectId?.toString(),
      createdAt: new Date()?.getTime(),
      tos: invitees?.map((target) => target?.id?.toString()),
    };

    await MessagesApi.create({ data: annotation })
      .then((status) => {
        if (status) {
          setProjectComments([annotation, ...projectComments]);
        } else {
          showError("Une erreur est survenue, Réessayez");
        }
      })
      .catch((error) => {
        console.log(
          "an error has occured when creating a project comment",
          error
        );

        showError("Une erreur est survenue, Réessayez");
      });

    event?.target?.reset();
  };

  const [invitees, setInvitees] = React?.useState([]);

  const _invitees = React?.useContext(usersCtx)?.users?.filter((user) => {
    return (
      user?.id?.toString() !== sessionStorage?.getItem("userId") &&
      !invitees?.some(
        (_target) => _target?.id?.toString() === user?.id?.toString()
      )
    );
  });

  const [isModalOpen, setISModalOpen] = React?.useState(false);
  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const handleInviteesAdd = (event, newInvitees) => {
    event?.preventDefault();

    setInvitees(newInvitees);
  };

  const [projectName, setProjectName] = React.useState("");
  const [projectOwner, setProjectOwner] = React.useState(null);
  const [projectDescription, setProjectDescription] = React.useState("");

  const params = useParams();

  const [projectId, setProjectId] = React?.useState(params?.ref);

  const editMode = parseInt(projectId) > 0;

  React.useEffect(() => {
    // get the corresponding project to the provided id

    if (params?.ref !== "new") {
      (async () => {
        await ProjectApi.getOne({ id: params?.ref })
          .then((project) => {
            if (Object.keys(project || {})?.length > 0) {
              setProjectComments(project?.comments || []);
              setProjectName(project?.title);
              setProjectDescription(project?.description);
              setProjectContent(project?.content);
              setInvitees(
                project?.contributors?.map((target) => {
                  target.fullName = target?.username;

                  return target;
                })
              );

              setProjectOwner(project?.owner);
            }
          })
          .catch((error) => {
            console.log(
              "an error has occured when fetching single project",
              error
            );
          });
      })();
    } else {
      setProjectComments([]);
      setProjectName("");
      setProjectDescription("");
      setProjectContent("");
      setInvitees([]);

      setProjectId("new");

      setProjectOwner(null);
    }
  }, [params?.ref]);

  const handleDelete = async (event) => {
    event?.preventDefault();

    if (window.confirm("Voulez-vous vraiment supprimer le projet ?")) {
      await ProjectApi.delete({ id: params?.ref })
        .then((status) => {
          if (status) {
            showSuccess("Projet supprimé !");

            navigate("/apps/e-courrier/mails/projects");
          } else {
            showError("Une erreur est survenue !");
          }
        })
        .catch((error) => {
          console.log("an error has occured when deleting a project", error);

          showError("Une erreur est survenue !");
        });
    }
  };

  const handleUpdate = async (event) => {
    event?.preventDefault();

    if (projectName?.length > 3) {
      if (window?.confirm("Voulez-vous vraiment enregistrer ?")) {
        if (editMode) {
          await ProjectApi.update({
            data: {
              title: projectName,
              description: projectDescription,
              content: projectContent,
              contributors: [
                sessionStorage.getItem("userId"),
                ...invitees?.map((target) => {
                  return target?.id?.toString();
                }),
              ],
            },
            id: params?.ref,
          })
            .then((status) => {
              if (status) {
                showSuccess("Projet mis à jour !");
              } else {
                showError("Une erreur est survenue !");
              }
            })
            .catch((error) => {
              console.log(
                "an error has occured when updating a project",
                error
              );

              showError("Une erreur est survenue !");
            });
        } else {
          await ProjectApi.create({
            data: {
              title: projectName,
              description: projectDescription,
              content: projectContent,
              contributors: [
                sessionStorage.getItem("userId"),
                ...invitees?.map((target) => {
                  return target?.id?.toString();
                }),
              ],
              owner: sessionStorage.getItem("userId")?.toString(),
            },
          })
            .then((status) => {
              if (status) {
                setProjectId(status?.id);

                showSuccess("Projet créé avec succès !");
              }
            })
            .catch((error) => {
              console.log(
                "an error has occured when creating a project",
                error
              );

              showError("Une erreur est survenue !");
            });
        }
      }
    } else {
      showWarning("Le titre du projet est trop court");
    }
  };

  return (
    <Layout>
      <InviteesModal
        open={isModalOpen}
        setOpen={setISModalOpen}
        childrenClassName={`w-[${screen900 ? 95 : 70}%]`}
        setInviteBox={setInvitees}
        inviteBox={invitees}
      >
        <Box
          className="card-box w-full p-6"
          sx={{
            mt: "1rem",
          }}
        >
          <Typography
            className="mb-4 select-none text-black"
            sx={{
              mb: "1rem",
            }}
          >
            Ajouter les co-signataires
          </Typography>
          <Box>
            <Autocomplete
              onChange={handleInviteesAdd}
              multiple
              id="checkboxes-tags-demo"
              options={_invitees}
              disableCloseOnSelect
              defaultValue={invitees}
              getOptionLabel={(option) =>
                `${option?.fullName}${
                  screen900 ? "" : ` - Dpt (${option?.department})`
                }`
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option?.fullName}${
                    screen900 ? "" : ` - Dpt (${option?.department})`
                  }`}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Signataires"
                  placeholder="Signataire"
                />
              )}
            />
          </Box>
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "flex-end",
              mt: "1rem",
            }}
          >
            <button
              type="button"
              className="btn-primary flex-auto"
              onClick={(event) => {
                event?.preventDefault();

                window?.sessionStorage?.setItem(
                  "collabs",
                  JSON.stringify(invitees)
                );

                setISModalOpen(false);
              }}
            >
              Ajouter
            </button>
          </Stack>
        </Box>
      </InviteesModal>
      <Grid
        container
        sx={{
          width: "100%",
          height: "calc(100vh - 100px)",
          minHeight: "300px",
          mt: ".5rem",
        }}
      >
        <Grid
          md={!editMode ? 12 : 9}
          sm={12}
          sx={{
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: "100%",
              p: "1rem",
              m: ".5rem",
              bgcolor: theme?.palette?.common?.white,
              border: `1px solid ${theme?.palette?.grey[300]}`,
              borderRadius: "15px",
              overflowY: "auto",
              pb: "3rem",
            }}
          >
            <Grid
              container
              sx={{
                height: "100%",
              }}
            >
              <Grid
                item
                sm={!editMode ? 8 : 9}
                xs={12}
                sx={{
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    justifyContent: "center",
                    height: "100%",
                    pb: "3rem",
                    m: ".5rem",
                  }}
                >
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="Le contenu de votre projet"
                    onChange={handleProcedureContentChange}
                    style={{ height: "100%" }}
                    value={projectContent}
                  />
                </Box>
              </Grid>
              <Grid
                item
                sm={!editMode ? 4 : 3}
                xs={12}
                sx={{
                  px: ".5rem",
                }}
              >
                <Stack
                  direction={"column"}
                  sx={{
                    m: ".5rem",
                    width: "100%",
                    alignItems: "flex-start",
                    maxWidth: "100%",
                    overflowX: "hidden",
                  }}
                >
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme?.palette.grey[700],
                        fontWeight: theme?.typography?.fontWeightRegular,
                        fontSize: "14px",
                        mb: ".3rem",
                      }}
                    >
                      Document
                    </Typography>
                    <button
                      onClick={handleUpdate}
                      type="button"
                      className="btn-primary"
                      style={{
                        width: "100%",
                        boxShadow: "none",
                        fontSize: "13px",
                        marginBottom: ".5rem",
                      }}
                    >
                      Enregistrer
                    </button>
                    {projectOwner?.id &&
                      projectOwner?.id?.toString() ===
                        sessionStorage.getItem("userId") && (
                        <button
                          onClick={handleDelete}
                          className="btn-primary"
                          type="button"
                          style={{
                            width: "100%",
                            boxShadow: "none",
                            fontSize: "13px",
                            marginBottom: ".5rem",
                            backgroundColor: theme?.palette?.secondary?.main,
                            color: theme?.palette?.primary?.main,
                          }}
                        >
                          Supprimer
                        </button>
                      )}
                  </Stack>
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      mb: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme?.palette.grey[700],
                        fontWeight: theme?.typography?.fontWeightRegular,
                        fontSize: "14px",
                        mb: ".3rem",
                      }}
                    >
                      Collaborateurs
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        justifyContent: "flex-start",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <Avatar
                        onClick={(event) => {
                          event?.preventDefault();

                          setISModalOpen(true);
                        }}
                        sx={{
                          width: "35px",
                          height: "35px",
                          bgcolor: theme?.palette?.primary?.main,
                          cursor: "pointer",
                          mr: ".3rem",
                          mb: ".3rem",
                        }}
                      >
                        <Add
                          sx={{
                            color: theme?.palette?.common?.white,
                          }}
                        />
                      </Avatar>
                      {invitees?.map((target) => {
                        return (
                          <Avatar
                            onClick={(event) => {
                              event?.preventDefault();

                              setISModalOpen(true);
                            }}
                            sx={{
                              width: "35px",
                              height: "35px",
                              bgcolor: theme?.palette?.primary?.main,
                              cursor: "pointer",
                              mr: ".3rem",
                              mb: ".3rem",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "13px",
                              }}
                            >
                              {(target?.fullName || target?.username)
                                ?.split(" ")
                                ?.map((chars) => chars[0])
                                ?.splice(0, 2)}
                            </Typography>
                          </Avatar>
                        );
                      })}
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"column"}
                    sx={{
                      width: "100%",
                      // m: ".5rem",
                      maxWidth: "100%",
                      overflowX: "hidden",
                      alignItems: "flex-start",
                    }}
                  >
                    <TextField
                      size={"small"}
                      label={"Nom du projet"}
                      variant={"outlined"}
                      name={"projectName"}
                      sx={{
                        width: "100%",
                        m: "0!important",
                        fontSize: "12px!important",
                      }}
                      value={projectName}
                      onChange={(event) => {
                        event?.preventDefault();
                        setProjectName(event?.target?.value);
                      }}
                    />
                    <TextField
                      size={"small"}
                      label={"Description du projet"}
                      variant={"outlined"}
                      name={"projectDescription"}
                      sx={{
                        width: "100%",
                        m: "0!important",
                        fontSize: "12px!important",
                        mt: ".5rem!important",
                      }}
                      multiline
                      rows={3}
                      value={projectDescription}
                      onChange={(event) => {
                        event?.preventDefault();
                        setProjectDescription(event?.target?.value);
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {editMode && (
          <Grid md={3} sm={12}>
            <Box
              sx={{
                height: "100%",
                p: "1rem",
                m: ".5rem",
                bgcolor: theme?.palette?.common?.white,
                border: `1px solid ${theme?.palette?.grey[300]}`,
                overflow: "hidden",
                borderRadius: "15px",
              }}
            >
              <Box
                component={"form"}
                onSubmit={handleComment}
                sx={{
                  height: "100%",
                }}
              >
                <TextField
                  size={"small"}
                  label={"Votre commentaire"}
                  variant={"outlined"}
                  name={"comment"}
                  sx={{
                    width: "100%",
                    mb: "1rem",
                  }}
                />
                <Stack
                  direction={"column"}
                  sx={{
                    alignItems: "flex-start",
                    maxHeight: "calc(100vh - 210px)",
                    overflowY: "auto",
                  }}
                >
                  {projectComments
                    ?.filter((target) => target?.text?.length > 0)
                    ?.map((target) => {
                      return (
                        <Stack
                          sx={{
                            width: "100%",
                            borderRadius: "15px",
                            bgcolor: theme?.palette?.secondary?.main,
                            p: ".7rem",
                            mb: ".5rem",
                          }}
                        >
                          <Typography
                            sx={{
                              color: theme?.palette?.common?.black,
                              fontSize: "14px",
                            }}
                          >
                            {target?.text} <br />
                            <Typography
                              component={"em"}
                              sx={{
                                fontSize: "12px",
                                fontStyle: "italic",
                                fontWeight: theme?.typography?.fontWeightBold,
                              }}
                            >
                              De{" "}
                              {target?.from?.username || target?.from?.fullName}{" "}
                              à{" "}
                              {new Date(target?.createdAt)?.toLocaleString(
                                "fr-FR"
                              )}
                            </Typography>
                          </Typography>
                        </Stack>
                      );
                    })}
                </Stack>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Layout>
  );
};

export default ProjectEditor;
