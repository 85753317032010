// context definition

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { guardCtx } from "./Guard";
import { socketCtx } from "./io";
import { notificationCtx } from "./notification";

const TimerWraper = ({ children }) => {
  const navigate = useNavigate();

  const notifsContext = React.useContext(notificationCtx);
  const handleLogout = React.useContext(guardCtx)?.handleLogout;

  // START MANAGING IDLE TIME

  //If session expire time logout

  const disconnect = () => {
    handleLogout();
  };

  const checkForInactivity = async () => {
    const expireTime = parseInt(sessionStorage.getItem("expireTime"));
    if (expireTime < Date.now()) {
      notifsContext.showWarning("Votre session a expiré");

      disconnect();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 150000;
    sessionStorage.setItem("expireTime", expireTime);
  };

  //useEffect to set interval to check for inactivity
  React.useEffect(() => {
    //check for inactivity every 15 seconds
    const interval = setInterval(() => {
      checkForInactivity();
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  //useEffect to update expire time for user activity
  React.useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mouseover", updateExpireTime);

    //clean up
    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mouseover", updateExpireTime);
    };
  }, []);
  // END MANAGING IDLE TIME

  return <>{children}</>;
};

export default TimerWraper;
