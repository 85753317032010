// entities data central

import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize";

class EntityApi {
    static async getAll() {
        const entities = [];

        await lookup(`${BASE_URL}/api/entities?populate[0]=employees`, {
            method: "GET",
            headers: getHeaders({})
        }).then(res => res.json().then(result =>  {
            if (Array.isArray(result?.data)) {
                entities.push(...strapiSanitize({object: result?.data})?.map(target => {
                    return {
                        ...target,
                        employees: strapiSanitize({object: target["employees"]?.data}),
                    }
                }))
            }
        })).catch(error => {
            console.log("an error has occured when fetching entities", error);     
        });

        console.log("processsed entities here", entities);
        
        return entities
    }

    static async remove({ id }) {

        let status = null;

        await lookup(`${BASE_URL}/api/entities/${id}`, {
            method: "DELETE",
            headers: getHeaders({})
        }).then(res => res.json().then(result => {
            if (result) {
                status = result
            }
        })).catch(error => {
            console.log("an error has occured when remoing a entity", error);
            
        });

        return status
    }

    static async create({data}) {
        let status = null;

        await lookup(`${BASE_URL}/api/entities`, {
            method: "POST",
            headers: getHeaders({}),
            body: JSON.stringify(data)
        }).then(res => res.json().then(result => {
            console.log("received data for entity creation", result);
            
            if (result) {
                status = result;

            }
        })).catch(error => {
            console.log("an error has occured when creating an entity", error);
            
        })

        return status
    }

    static async update({id, data}) {
        let status = null;

        await lookup(`${BASE_URL}/api/entities/${id}`, {
            method: "PUT",
            headers: getHeaders({}),
            body: JSON.stringify(data)
        }).then(res => res.json().then(result => {

            if (result) {
                status = result
            }

        })).catch(error => {
            console.log("an error has occured when updating an entity", error);
            
        })

        return status
    }
}

export default EntityApi
