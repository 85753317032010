// component definititon

import * as React from "react";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
  Avatar,
  Chip,
  useMediaQuery,
  SpeedDial,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Add,
  Archive,
  AttachFile,
  Check,
  ChevronRight,
  Download,
  DownloadDoneTwoTone,
  DownloadForOffline,
  Edit,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { documentsCtx } from "../../context/documents";
import { Search } from "react-feather";
import { appDataCtx } from "../../context/appData";
import NavigationLine from "../../components/NavigationLine";
import { filesCtx } from "../../context/files";
import { BASE_URL } from "../../constants/api";
import getHeaders from "../../utils/getHeaders";
import Layout from "../../components/Layout";
import lookup from "../../utils/lookup";
import { entitiesCtx } from "../../context/entities";
import parseRef from "../../utils/parseRef";
import mergePdf from "../../utils/pdfMerger";

const ITEM_HEIGHT = 48;

const EntitiesTab = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));
  const entitiesContext = React?.useContext(entitiesCtx);

  const columns = [
    {
      field: "name",
      headerName: "Nom",
    },
    // {
    //   field: "parent",
    //   headerName: "Parent",
    //   renderCell: ({ row }) => {
    //     return (
    //       <Typography
    //         sx={{
    //           fontSize: "14px",
    //           fontWeight: theme?.typography?.fontWeightRegular,
    //         }}
    //       >
    //         {row?.parent?.name
    //           ?.split(" ")
    //           ?.map((t) => t[0]?.toUpperCase())
    //           .join("")}
    //       </Typography>
    //     );
    //   },
    // },
    // {
    //   field: "children",
    //   headerName: "Branches",
    //   renderCell: ({ row }) => {
    //     return (
    //       <Typography
    //         sx={{
    //           fontSize: "14px",
    //           fontWeight: theme?.typography?.fontWeightRegular,
    //         }}
    //       >
    //         {row?.children
    //           ?.map((t) =>
    //             t?.name
    //               ?.split(" ")
    //               ?.map((t) => t[0]?.toUpperCase())
    //               .join("")
    //           )
    //           ?.join(" | ")}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "description",
      headerName: "Description",
    },
    {
      field: "open",
      headerName: "Ouvrir",
      renderCell: ({ row }) => (
        <Link to={`/settings/entities/${row?.id}?entityId=${row?.id}`}>
          <IconButton>
            <Visibility
              sx={{
                color: theme?.palette?.primary?.main,
              }}
            />
          </IconButton>
        </Link>
      ),
    },
  ]?.map((col) => {
    return {
      ...col,
      flex: 1,
      headerClassName: "grid--header",
    };
  });

  const [searchValue, setSearchValue] = React.useState("");
  const [searchedRows, setSearchedRows] = React.useState([]);

  React?.useEffect(() => {
    setSearchedRows(entitiesContext?.entities);
  }, [entitiesContext?.entities]);

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    const searchedEntities = [...entitiesContext?.entities]?.filter(
      (target) => {
        return Object.keys(target)?.some((key) => {
          return target[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        });
      }
    );

    setSearchedRows(searchedEntities);
  };

  return (
    <Layout>
      <div>
        <Stack
          sx={{
            pt: "1rem",
            // px: !screen900 ? "2rem" : ".5rem",
            overflowX: "hidden",
            maxWidth: !screen900 ? "100%" : undefined,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <NavigationLine
              firstTitle={"Paramètres"}
              secondTitle={"Entités"}
              backLink={"/settings/all"}
            />
            <Box
              className="text-field"
              sx={{
                py: ".3rem",
              }}
            >
              <input
                type="text"
                value={searchValue}
                onChange={handleSearch}
                placeholder={"Recherche"}
              />
              <span className="cursor-pointer">
                <Search className="stroke-black" />
              </span>
            </Box>
          </Stack>
          <Grid
            container
            sx={{
              width: "100%",
              alignItems: "stretch",
            }}
          >
            {searchedRows?.map((target) => {
              return (
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  sx={{
                    p: ".5rem",
                  }}
                >
                  <Stack
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(
                        `/settings/entities/${target?.id}?entityId=${target?.id}`
                      );
                    }}
                    direction={"column"}
                    sx={{
                      width: "100%",
                      height: "100%",
                      alignItems: "flex-start",
                      borderRadius: "1rem",
                      bgcolor: theme?.palette?.common?.white,
                      p: "1rem",
                      cursor: "pointer",
                      "&:hover": {
                        transition: ".3s all",
                        boxShadow: "0px 2px 20px -4px #cddde1",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme?.palette?.primary?.main,
                        fontWeight: theme?.typography?.fontWeightBold,
                        overflow: "hidden",
                        maxWidth: "100%",
                        textWrap: "nowrap",
                        whiteSpace: "ellipsis",
                        mb: ".5rem",
                        fontSize: "14px",
                      }}
                    >
                      {target?.name}
                    </Typography>

                    <Typography
                      sx={{
                        color: theme?.palette?.grey[500],
                        fontWeight: theme?.typography?.fontWeightRegular,
                        maxWidth: "100%",
                        fontSize: "12px",
                      }}
                    >
                      {target?.description?.slice(0, 121)} ...
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </div>
    </Layout>
  );
};

export default EntitiesTab;
