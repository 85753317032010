// component definition

import * as React from "react";
import { useContext } from "react";

import { fabric } from "fabric";
import { useAtom } from "jotai";
import { ChevronLeft, Plus, Search } from "react-feather";

import SingImgContext from "../../../../context/SingImgContext";
import { openModalAtom, signAtom } from "../../../../jotai";
import { signaturesCtx } from "../../../../context/signatures";
import {
  Stack,
  useTheme,
  Button,
  useMediaQuery,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { Check, Close, Delete } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import RejectionModal from "../../../../components/RejectionModal";
import { currDocumentCtx } from "../../../../context/currDocument";
import { appDataCtx } from "../../../../context/appData";
import { guardCtx } from "../../../../context/Guard";
import { BASE_URL } from "../../../../constants/api";

import { paraphCtx } from "../../../paraph";
import getHeaders from "../../../../utils/getHeaders";
import { notificationCtx } from "../../../../context/notification";
import { parseSignatures } from "../../../../utils/document";
import textToImage from "../../../../utils/textToImage";
import lookup from "../../../../utils/lookup";
import { sessionCtx } from "../../../../context/session";

const Seal = ({ uploadFinalFile }) => {
  // context
  const { canvas, focusCanvasIdx, getAddLocation } = useContext(SingImgContext);

  // useAtom
  const [signList] = useAtom(signAtom);
  const [, setOpenModal] = useAtom(openModalAtom);

  const theme = useTheme();
  const annexContext = sessionStorage?.getItem("annexes-data")?.length > 1;
  const courrierSignatureContext =
    sessionStorage.getItem("document-type") === "outgoing_mail";

  const clickAddSing = (
    addImg: string | HTMLCanvasElement,
    isSignature = true
  ) => {
    console.log("current data for sign add", { addImg });

    fabric.Image.fromURL(
      addImg.toString(),
      (img) => {
        console.log("current index in sign", {
          addImg,
        });

        const signPage = window?.sessionStorage?.getItem("signPage");
        const signIndex = window?.sessionStorage?.getItem("signIndex");

        // alert(`current annex context ${annexContext}`);

        if (annexContext || !isSignature) {
          // alert("Will store display objects");

          const displayObjects = JSON.parse(
            sessionStorage?.getItem("display-initials") || "[]"
          );

          if (
            displayObjects?.some(
              (target) =>
                target?.signPage?.toString() ===
                focusCanvasIdx?.toString()?.toString()
            )
          ) {
            showWarning("Un seul paraphe par page");
          } else {
            displayObjects?.push({
              signPage: focusCanvasIdx?.toString(),
              signIndex: addImg,
            });

            sessionStorage?.setItem(
              "display-initials",
              JSON.stringify(displayObjects)
            );

            canvas[focusCanvasIdx].add(img).renderAll();
          }
        } else if (courrierSignatureContext) {
          const sealsObjects = JSON.parse(
            sessionStorage?.getItem("display-seals") || "[]"
          );

          if (
            sealsObjects?.some(
              (target) =>
                target?.signPage?.toString() ===
                focusCanvasIdx?.toString()?.toString()
            )
          ) {
            showWarning("Un seul sceau par page");
          } else {
            sealsObjects?.push({
              signPage: focusCanvasIdx?.toString(),
              signIndex: addImg,
            });

            sessionStorage?.setItem(
              "display-seals",
              JSON.stringify(sealsObjects)
            );

            canvas[focusCanvasIdx].add(img).renderAll();
          }
        } else {
          if (signPage?.length > 0 && signIndex?.length > 0) {
            showWarning("Une seule signature par signataire");
          } else {
            window?.sessionStorage?.setItem(
              "signPage",
              focusCanvasIdx?.toString()
            );

            window?.sessionStorage?.setItem("signIndex", addImg);

            canvas[focusCanvasIdx].add(img).renderAll();

            // getAddLocation(true);
          }
        }
      },
      {
        top: 50,
        left: 50,
      }
    );
  };

  const navigate = useNavigate();

  const createSignURL = (
    item: string | HTMLCanvasElement,
    isSignature = true
  ) => {
    clickAddSing(item, isSignature);
  };

  const signatureContext = React?.useContext(signaturesCtx);

  const signatures = signatureContext?.signatures;

  const representationMode =
    React?.useContext(currDocumentCtx)?.representationMode;

  const [stampObj, setStampObj] = React.useState({});

  const configImages = async () => {
    const finalSignee = representationMode?.finalSignee;

    console.log("parsed signatures data for seal", signatures);

    let _stampObj = {};

    if (
      signatures?.findIndex((target) => {
        if (target?.type === "Sceau") {
          _stampObj = target;

          if (target?.signature) {
            _stampObj.sign = target?.signature;
          }

          return true;
        }

        return false;
      }) !== -1
    ) {
      // potential processing
    } else {
      let name = "NONE";

      if (representationMode?.active) {
        name = finalSignee?.fullName;
      } else {
        name = sessionStorage?.getItem("username");
      }

      _stampObj = {
        signature: textToImage({ text: name }),
        id: 1,
        createdAt: new Date()?.toISOString(),
        sign: textToImage({
          text: name,
        }),
      };
    }

    console.log("computed stamp image", _stampObj);

    setStampObj(_stampObj);
  };

  React?.useEffect(() => {
    configImages();
  }, [signatures]);

  // console.log("signatures data here for edit", signatures);

  const [rejectionPurpose, setRejectionPurpose] = React?.useState("");
  const [isPromptOpen, setIsPromptOpen] = React?.useState("");

  const injectData = React?.useContext(appDataCtx).injectData;

  const setLoadingMap = React?.useContext(guardCtx)?.setLoadingMap;

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const notifsContext = React?.useContext(notificationCtx);

  const showError = notifsContext?.showError;
  const showSuccess = notifsContext?.showSuccess;
  const showWarning = notifsContext?.showWarning;

  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  return (
    <Box className="tag-element">
      <Box
        sx={{
          py: ".5rem",
        }}
      >
        <p>Sceau</p>
        {stampObj && (
          <Box className="tag-list">
            <Box
              className="sing-tag border-solid border-black/20 bg-[#F9F9F9]"
              onClick={() => createSignURL(stampObj?.sign, !annexContext)}
            >
              <img src={stampObj["sign"]?.toString()} alt="company seal" />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Seal;
