// page component definition

import * as React from "react";
import {
  useMediaQuery,
  Stack,
  useTheme,
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Search } from "react-feather";
import Layout from "../../components/Layout";
import NavigationLine from "../../components/NavigationLine";
import { useNavigate } from "react-router-dom";
import ProjectApi from "../../helpers/e-courrier/requests/projects";

const ProjectsList = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [projects, setProjects] = React.useState([]);
  const [searchedProjects, setSearchedProjects] = React.useState([]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    // get projects list from the api

    (async () => {
      await ProjectApi.getAll({
        userId: sessionStorage.getItem("userId"),
      })
        .then((projects) => {
          setProjects(projects);
          setSearchedProjects(projects);
        })
        .catch((error) => {
          console.log(
            "an error has occured when fetching projects data",
            error
          );
        });
    })();
  }, []);

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    const searchedCourriers = [...projects]?.filter((target) => {
      return Object.keys(target)?.some((key) => {
        return target[key]
          ?.toString()
          ?.toLowerCase()
          ?.includes(_search?.toString()?.toLowerCase());
      });
    });

    setSearchedProjects(searchedCourriers);
  };

  return (
    <Layout>
      <Stack
        sx={{
          pt: "1rem",
          // px: !screen900 ? "2rem" : ".5rem",
          overflowX: "hidden",
          maxWidth: !screen900 ? "100%" : undefined,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigationLine firstTitle={"Projets"} secondTitle={"Liste"} />
          <Box
            className="text-field"
            sx={{
              py: ".3rem",
            }}
          >
            <input
              type="text"
              value={searchValue}
              onChange={handleSearch}
              placeholder={"Recherche"}
            />
            <span className="cursor-pointer">
              <Search className="stroke-black" />
            </span>
          </Box>
        </Stack>
        <Grid
          container
          sx={{
            width: "100%",
          }}
        >
          {searchedProjects?.map((target) => {
            return (
              <Grid
                md={3}
                sm={6}
                xs={12}
                sx={{
                  p: ".5rem",
                }}
              >
                <Card sx={{ maxWidth: "100%" }}>
                  <CardActionArea
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(`/apps/e-courrier/mails/projects/${target?.id}`);
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="100px"
                      image={target?.background}
                      alt={target?.title}
                      sx={{
                        width: "100%!important",
                        height: "120px!important",
                        overflow: "hidden",
                      }}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: "14px!important",
                          fontWeight: theme?.typography?.fontWeightBold,
                        }}
                      >
                        {target?.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {target?.description?.slice(0, 51) + " ..."}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Layout>
  );
};

export default ProjectsList;
