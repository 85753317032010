// helper function definition

import { BASE_URL } from "../../constants/api";
import getHeaders from "../../utils/getHeaders";
import lookup from "../../utils/lookup";

class OnlineApi {
    static async getStatus({userId}) {
        let status = null;

        await lookup(`${BASE_URL}/api/online-users`, {
            body: JSON.stringify({
                data: {
                    userId
                }
            }),
            headers: getHeaders({}),
            method: "POST"
        }).then(res => res.json().then(result => {
            if (result?.data ) {
                status = result?.data
            }
        })).catch(error => {
            console.log("an error has occured when getting user's online status", error);
            
        })

        return status
    }
}

export default OnlineApi;
