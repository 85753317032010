// notifications api central


import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize"

class NotificationsApi {
    static async getAll({ userId, includes }) {
        let notifications = [];

        await lookup(`${BASE_URL}/api/notifications?${includes?.map((slug, index) => `filters[slug][$in][${index}]=${slug}&`).join("")}filters[recipient][id][$eq][0]=${userId}&populate[0]=producer&sort=createdAt:desc`, {
            method: "GET",
            headers: getHeaders({})
        }).then(res => res.json().then(results => {
            notifications = strapiSanitize({ object: results.data })?.map(target => {
                return {
                    ...target,
                    producer: {
                       id :  target?.producer?.data?.id,
                       ...target?.producer?.data?.attributes
                    }
                }
            })
            
            console.log("received notifications data here", notifications);
        })).catch(error => {
            console.log("an error has occured when fetching notifications", error);

        })

        return notifications
    }

    static async update({ id}) {
        let status ;

        await lookup(`${BASE_URL}/api/notifications/${id}`, {
            method: "PUT",
            headers: getHeaders({}),
            body: JSON.stringify({
                data : {
                    seen : true
                }
            })
        }).then(res => res.json().then(result => {
            status = result
        })).catch(error => {
            console.log("an error has occured when updating a notification object", error);
            
        })

        console.log("updated notif result here", status, `${BASE_URL}/api/notifications/${id}`);

        return status
    }

    static async getOne({ documentId, slug="new-courrier"}) {
        let notifObj = null;

        await lookup(`${BASE_URL}/api/notifications?filters[baseDocument][id][$eq][0]=${documentId}&filters[recipient][id][$eq][1]=${sessionStorage.getItem("userId")}&filters[seen][$eq][2]=false&filters[slug][$eq][3]=${slug}`, {
            method: "GET",
            headers: getHeaders({})
        }).then(res => res.json().then(result => {

            console.log("received single notif obj", result);
            
            notifObj = result.data[0];

            
        })).catch(error => {
            console.log("an error has occured when getting single notif object", error);
            
        });

        return notifObj
    }
}

export default NotificationsApi
