// archives context

import * as React from "react";
import ArchivesApi from "../helpers/archiving/requests/archive";
import { appDataCtx } from "./appData";
import { sessionCtx } from "./session";

const archivesCtx = React.createContext({});

const ArchivesContext = ({ children }) => {
  const [archives, setArchives] = React?.useState([]);

  const appDataContext = React?.useContext(appDataCtx);

  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const refreshArchives = async () => {
    const role = sessionStorage?.getItem("role")?.toLowerCase();

    if (role === "admin") {
      // fetch everything without any limitation
      await ArchivesApi.getAll({ id: sessionStorage?.getItem("userId") })
        .then((results) => {
          setArchives(results);
        })
        .catch((error) => {
          console.log("an error has occured when getting archives", error);
        });
    } else {
      // fetch with role and entities limitations
      await ArchivesApi.getAll({
        id: sessionStorage?.getItem("userId"),
        role,
        entities: appDataContext?.entitiesIn?.map((entity) =>
          entity?.id?.toString()
        ),
      })
        .then((results) => {
          setArchives(results);
        })
        .catch((error) => {
          console.log("an error has occured when getting archives", error);
        });
    }
  };

  React.useEffect(() => {
    (async () => {
      if (currentApp?.toLowerCase() === "vizioo archivage") {
        await refreshArchives();
      }
    })();
  }, [appDataContext?.entitiesIn, currentApp]);

  return (
    <archivesCtx.Provider
      value={{
        archives,
        setArchives,
        refreshArchives,
      }}
    >
      {children}
    </archivesCtx.Provider>
  );
};

export { archivesCtx };
export default ArchivesContext;
