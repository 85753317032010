// component definition

import * as React from "react";
import { useTheme, Box, Stack } from "@mui/material";

const Loading = ({}) => {
  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        alt={"Vizioo, une solution GED de Rhinoceros Software SAS"}
        src={"/gifs/loader.gif"}
        style={{
          width: "80px",
        }}
      />
    </Stack>
  );
};

export default Loading;
