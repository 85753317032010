// utility function definition

import {
  Document,
  Page,
  Image,
  PDFDownloadLink,
  pdf,
} from "@react-pdf/renderer";
import lookup from "./lookup";

const imageToPdf = async ({ imgUrls, defaultName = "Document scanné" }) => {
  let localUrls = await Promise.all(
    imgUrls?.map((target, index) => {
      return (async () => {
        return lookup(target, {
          method: "GET",
        })
          .then((res) =>
            res.blob().then((_sign) => {
              const image = new File([_sign], `page${index}`, {
                type: "image/png",
              });

              const signLocalUrl = URL.createObjectURL(image);

              return signLocalUrl;
            })
          )
          .catch((error) => {
            console.log(
              "an error has occured when transforming remote image source to local for merge after scan",
              error
            );

            return "";
          });
      })();
    })
  );

  const doc = (
    <Document>
      {localUrls.map((target, idx: number) => {
        return (
          <Page key={idx} size="A4">
            <Image
              src={{
                uri: target,
                method: "GET",
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
            />
          </Page>
        );
      })}
    </Document>
  );

  const asPdf = pdf([]);
  asPdf.updateContainer(doc);

  const docBlobData = await asPdf.toBlob();

  console.log("blob file here after scan", { docBlobData, localUrls });

  const resultFile = new File([docBlobData], `${defaultName}`, {});

  return { file: resultFile };
};

export default imageToPdf;
