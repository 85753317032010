// utility function definition

const strapiSanitize = ({ object }) => {
    let data;

    if (Array.isArray(object)) {
        data = object?.map((target) => {
            return {
                id: target?.id,
                ...target?.attributes,
            };
        });
    } else {
        data = {
            id: object?.data?.id,
            ...object?.data?.attributes,
        };
    }

    return data;
};

export default strapiSanitize;
