// component defintion

import * as React from "react";
import {
  useTheme,
  Stack,
  Tooltip,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";

const ReferenceField = ({}) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        className="tag-element"
        sx={{
          pt: "1rem",
        }}
      >
        <p>Référence</p>
        <Box
          className="tag-list"
          sx={
            {
              // pt: "2rem",
            }
          }
        >
          <Tooltip
            arrow
            placement={"right"}
            title={"Télécharger"}
            sx={{
              lineHeight: "1rem",
              p: 0,
            }}
          >
            <Typography
              onClick={(event) => {
                event?.preventDefault();

                window.open(sessionStorage.getItem("doc_link"));
              }}
              sx={{
                color: theme?.palette?.grey[700],
                cursor: "pointer",
                fontWeight: theme?.typography?.fontWeightBold,
                width: "max-content",
                m: "0!important",
                p: 0,
              }}
            >
              {sessionStorage?.getItem("doc_ref")}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferenceField;
