// api requests central

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";
import strapiSanitize from "../../../utils/strapiSanitize";

class ProjectApi {
    static async getAll({ userId, limit, page, }) {
        let projects = [];

        await lookup(`${BASE_URL}/api/projects?filters[$or][0][owner][id][$eq]=${userId}&filters[$or][1][contributors][id][$eq][1]=${sessionStorage?.getItem("userId")}`, {
            headers: getHeaders({}),
            method: "GET"
        }).then(res => res.json().then(res => {

            console.log("received projects data hereceived projects data here", userId, res)

            if (res.data?.length > 0) {
                projects = strapiSanitize({ object: res.data }).map(target => {
                    target.background = `/wallpapers/wlpr-${parseInt(Math.random() * 10)}.jpg`;

                    return target
                })
                
            }
        })).catch(error => {
            console.log("an error has occured when fetching all projects", error);
        });

        return projects
    }

    static async getOne({ id }) {
        let project = {};

        await lookup(`${BASE_URL}/api/projects/${id}?populate[owner][populate][0]=*&populate[contributors][populate][1]=*&populate[comments][populate][2]=from`, {
            headers: getHeaders({}),
            method: "GET"
        }).then(res => res.json().then(res => {

            console.log("received data from single project", res);
            
            if (res.data) {
                project = strapiSanitize({ object: res });
                project.comments = project?.comments?.data?.map(target => {
                    return {
                        id: target?.id,
                        ...target?.attributes,
                        from: {
                            id: target?.attributes?.from?.data?.id,
                            ...target?.attributes?.from?.data?.attributes
                        }
                    }
                })
                project.contributors = project?.contributors?.data?.map(target => {
                    return {
                        id: target?.id,
                        ...target?.attributes  
                    }
                })
                project.owner = project?.owner?.data
            };

        })).catch(error => {
            console.log("an error has occured when fetching one project", error);
        });

        console.log("project data found for the provided id", project);
        

        return project
    }

    static async create({data}) {
        let status = null;

        await lookup(`${BASE_URL}/api/projects`, {
            headers: getHeaders({}),
            body: JSON.stringify({ data: data }),
            method: "POST"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when creating a project", error);
        });

        return status
    }

    static async update({data, id}) {
        let status = null;

        await lookup(`${BASE_URL}/api/projects/${id}`, {
            headers: getHeaders({}),
            body: JSON.stringify({ data: data }),
            method: "PUT"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when updating a project", error);
        });

        return status
    }

    static async delete({ id }) {
        let status = null;

        await lookup(`${BASE_URL}/api/projects/${id}`, {
            headers: getHeaders({}),
            method: "DELETE"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when deleting a project", error);
        });

        return status
    }
}

export default ProjectApi
