// component defintion

import * as React from "react";
import {
  useTheme,
  Stack,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Button,
  useMediaQuery,
  Typography,
  Avatar,
  Divider,
  Tooltip,
  ButtonBase,
} from "@mui/material";
import { notificationCtx } from "../../../context/notification";
import { currDocumentCtx } from "../../../context/currDocument";
import MessagesApi from "../../../helpers/e-courrier/requests/messages";
import { usersCtx } from "../../../context/users";

import { useNavigate } from "react-router-dom";

const Annotations = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currDoctContext = React.useContext(currDocumentCtx);

  const handleChats = (event) => {
    event?.preventDefault();

    navigate(
      `/apps/e-courrier/mails/conversations/${currDoctContext?.openedDocId}`
    );
  };

  return (
    <div>
      <div className="tag-element">
        {/* <p>Annotations</p> */}
        <Box
          sx={{
            py: "0!important",
          }}
        >
          <Button
            onClick={handleChats}
            sx={{
              bgcolor: `${theme?.palette?.primary?.main}`,
              fontSize: "12px",
              textTransform: "none",
              "&:hover": {
                bgcolor: `${theme?.palette?.primary?.main}`,
              },
              width: "max-content",
              py: ".3rem",
              px: ".5rem",
              borderRadius: "1rem",
              color: `${theme?.palette?.common?.white}`,
              mt: "1rem",
            }}
          >
            Annotations
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Annotations;
