// settings context defintion

import * as React from "react";
import SettingsApi from "../helpers/general/requests/settings";
import TagApi from "../helpers/archiving/requests/tags";
import CategoryApi from "../helpers/archiving/requests/category";

const settingsCtx = React.createContext({});

const SettingsContext = ({ children }) => {
  const [referencePrefix, setReferencePrefix] = React?.useState("");
  const [scanEndpoint, setScanEndpoint] = React?.useState("");

  const [tags, setTags] = React?.useState([]);
  const [categories, setCategories] = React?.useState([]);

  const alertSettings = [
    {
      field: "low",
      title: "Faible",
      color: "info",
    },
    {
      field: "medium",
      title: "Moyenne",
      color: "warning",
    },
    {
      field: "high",
      title: "Haute",
      color: "error",
    },
  ];

  const [alertStates, setAlertStates] = React.useState({
    low: 0,
    medium: 0,
    high: 0,
  });

  const [alertDays, setAlertDays] = React.useState({
    low: 0,
    medium: 0,
    high: 0,
  });

  const [companyObj, setCompanyObj] = React.useState({});

  const refreshSettings = () => {
    (async () => {
      await SettingsApi.getAll()
        .then((settings) => {
          console.log("received setting data here", settings);

          if (settings?.alerts?.low >= 0) {
            setAlertStates(settings?.alerts);
          }

          if (settings?.ttls?.low >= 0) {
            setAlertDays(settings?.ttls);
          }

          setReferencePrefix(settings?.referencePrefix);
          setScanEndpoint(settings?.scanEndpoint);

          setCompanyObj(settings?.company);
        })
        .catch((error) => {
          console.log("an error has occured when fetching settings", error);
        });
    })();
  };

  const refreshTags = async () => {
    await TagApi.getAll()
      .then((tags) => {
        if (Array.isArray(tags)) {
          setTags(tags);
        }
      })
      .catch((error) => {
        console.log("an error has occured when fetching tags", error);
      });
  };

  const refreshCategories = async () => {
    await CategoryApi.getAll()
      .then((categories) => {
        if (Array.isArray(categories)) {
          setCategories(categories);
        }
      })
      .catch((error) => {
        console.log("an error has occured when fetching catgories", error);
      });
  };

  React.useEffect(() => {
    (async () => {
      await Promise.allSettled([
        refreshSettings(),
        refreshCategories(),
        refreshTags(),
      ]).then((status) => {
        console.log("refreshed settings successfully", status);
      });
    })();
  }, []);

  return (
    <settingsCtx.Provider
      value={{
        alertStates,
        setAlertStates,
        alertDays,
        setAlertDays,
        alertSettings,
        refreshSettings,
        scanEndpoint,
        setScanEndpoint,
        referencePrefix,
        setReferencePrefix,
        companyObj,
        setCompanyObj,
        tags,
        setTags,
        categories,
        setCategories,
        refreshCategories,
        refreshTags,
      }}
    >
      {children}
    </settingsCtx.Provider>
  );
};

export { settingsCtx };
export default SettingsContext;
