// page component definition
import * as React from "react";

import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";

import {
  useTheme,
  Stack,
  Typography,
  Box,
  Grid,
  Chip,
  useMediaQuery,
  Avatar,
  IconButton,
  Slider,
  TextField,
  InputBase,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import NavigationLine from "../../components/NavigationLine";
import SettingsApi from "../../helpers/general/requests/settings";
import { notificationCtx } from "../../context/notification";
import { settingsCtx } from "../../context/settings";
import TagApi from "../../helpers/archiving/requests/tags";
import CategoryApi from "../../helpers/archiving/requests/category";

const Archives = ({}) => {
  const theme = useTheme();

  const [nextTag, setNextTag] = React.useState("");
  const [nextCategory, setNextCategory] = React.useState("");

  const navigate = useNavigate();
  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const notifsContext = React.useContext(notificationCtx);

  const showSuccess = notifsContext?.showSuccess;
  const showError = notifsContext?.showError;
  const showWarning = notifsContext?.showWarning;

  const settingsContext = React.useContext(settingsCtx);

  const tags = settingsContext?.tags;
  const categories = settingsContext?.categories;

  const refreshTags = settingsContext.refreshTags;
  const refreshCategories = settingsContext.refreshCategories;

  const handleRemoveTag = async (id) => {
    let status = null;

    if (window.confirm("Voulez-vous supprimer le tag ?")) {
      await TagApi.remove({ id })
        .then((result) => {
          status = result;
        })
        .catch((error) => {
          console.log("an error has occured when removing a tag", error);
        });

      if (status) {
        refreshTags();

        showSuccess("Suppression effectuée");
      } else {
        showError("Une errerur est survenue, réessayez !");
      }
    }
  };

  const handleRemoveCategory = async (id) => {
    let status = null;

    if (window.confirm("Voulez-vous supprimer la categorie ?")) {
      await CategoryApi.remove({ id })
        .then((result) => {
          status = result;
        })
        .catch((error) => {
          console.log("an error has occured when removing a tag", error);
        });

      if (status) {
        refreshCategories();

        showSuccess("Catégorie supprimé");
      } else {
        showError("Une errerur est survenue, réessayez !");
      }
    }
  };

  const createTag = async (data) => {
    let status = null;

    await TagApi.create({
      data,
    })
      .then((result) => {
        if (result) {
          status = result;
        }
      })
      .catch((error) => {
        console.log("an error has occured when creating a tag", error);
      });

    if (status) {
      refreshTags();

      showSuccess("Mot clé ajouté");
    } else {
      showError("Une erreur est survenue. Réessayez");
    }
  };

  const createCategory = async (data) => {
    let status = null;

    await CategoryApi.create({
      data,
    })
      .then((result) => {
        if (result) {
          status = result;
        }
      })
      .catch((error) => {
        console.log("an error has occured when creating a category", error);
      });

    if (status) {
      refreshCategories();

      showSuccess("Catégorie ajoutée");
    } else {
      showError("Une erreur est survenue. Réessayez");
    }
  };

  const handleTagAdd = async (event) => {
    event?.preventDefault();

    const nextVal = event?.target?.value;

    if (nextVal === " " && nextTag?.length === 0) {
      showWarning("Veuillez commencer par une letre");
    } else if (nextVal?.includes(" ") && nextVal?.length < 2) {
      showWarning("Un tag doit avoir au moins deux lettres");
    } else if (nextVal?.includes(" ")) {
      setNextTag("");

      await createTag({ name: nextVal?.trim() });
    } else {
      setNextTag(nextVal);
    }
  };

  const handleCategoryAdd = async (event) => {
    event?.preventDefault();

    const nextVal = event?.target?.value;

    if (nextVal === " " && nextCategory?.length === 0) {
      showWarning("Veuillez commencer par une letre");
    } else if (nextVal?.includes(" ") && nextVal?.length < 2) {
      showWarning("Une categorie doit avoir au moins deux lettres");
    } else if (nextVal?.includes(" ")) {
      setNextCategory("");

      await createCategory({ name: nextVal?.trim() });
    } else {
      setNextCategory(nextVal);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigationLine
            firstTitle={"Paramètres"}
            secondTitle={"Archives"}
            backLink={"/settings/all"}
          />
        </Stack>
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            alignItems: "flex-start",
            bgcolor: theme?.palette?.common?.white,
            p: "1rem",
            borderRadius: "15px",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
            }}
          >
            Mot clés
          </Typography>
          <Stack
            spacing={0.5}
            direction={"row"}
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              my: ".2rem",
              flexWrap: "wrap",
            }}
          >
            {tags?.map((target) => {
              return (
                <Chip
                  onDelete={(event) => {
                    event?.preventDefault();

                    handleRemoveTag(target?.id);
                  }}
                  label={target?.name}
                />
              );
            })}
            <InputBase
              value={nextTag}
              onChange={handleTagAdd}
              placeholder={"Nouveau"}
            />
          </Stack>
        </Stack>
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            alignItems: "flex-start",
            bgcolor: theme?.palette?.common?.white,
            p: "1rem",
            borderRadius: "15px",
            mt: "1rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
            }}
          >
            Catégories
          </Typography>
          <Stack
            spacing={0.5}
            direction={"row"}
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              my: ".2rem",
              flexWrap: "wrap",
            }}
          >
            {categories?.map((target) => {
              return (
                <Chip
                  onDelete={(event) => {
                    event?.preventDefault();

                    handleRemoveCategory(target?.id);
                  }}
                  label={target?.name}
                />
              );
            })}
            <InputBase
              value={nextCategory}
              onChange={handleCategoryAdd}
              placeholder={"Nouvelle"}
            />
          </Stack>
        </Stack>
      </Box>
    </Layout>
  );
};

export default Archives;
