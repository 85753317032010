// component definition

import * as React from "react";
import {
  useTheme,
  Stack,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Button,
  useMediaQuery,
  Typography,
  Avatar,
  Divider,
  Tooltip,
  ButtonBase,
  TextField,
  Autocomplete,
  Badge,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { usersCtx } from "../../context/users";
import { notificationCtx } from "../../context/notification";
import { currDocumentCtx } from "../../context/currDocument";
import Layout from "../../components/Layout";
import { Send } from "@mui/icons-material";
import MessagesApi from "../../helpers/e-courrier/requests/messages";
import NotificationsApi from "../../helpers/e-courrier/requests/notifications";
import { messagesCtx } from "../../context/messages";
import { courriersCtx } from "../../context/courriers";
import OnlineApi from "../../helpers/e-courrier/online";

const Conversations = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();

  const notifsContext = React.useContext(notificationCtx);
  const currDoctContext = React.useContext(currDocumentCtx);
  const msgContext = React?.useContext(messagesCtx);

  const showWarning = notifsContext?.showWarning;
  const showSuccess = notifsContext?.showSuccess;
  const showError = notifsContext?.showError;

  const users = React.useContext(usersCtx)?.users;

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = (event) => {
    event?.preventDefault();
  };

  const [messages, setMessages] = React.useState([]);

  const courriersContext = React?.useContext(courriersCtx);
  const courriersList = courriersContext?.pendingCourriers;
  const [currentCourrier, setCurrentCourriers] = React.useState(null);

  const [currentMsg, setCurrentMsg] = React.useState({
    from: {
      id: sessionStorage.getItem("userId"),
      name: sessionStorage.getItem("username"),
    },
    text: "",
  });

  const [isUserOnline, setIsUserOnline] = React.useState(false);

  const handleChat = (event) => {
    event?.preventDefault();

    setCurrentMsg({
      ...currentMsg,
      text: event?.target?.value,
    });
  };

  const handleChatSubmit = async (event) => {
    event?.preventDefault();

    if (currentMsg?.text?.length > 0) {
      setCurrentMsg({
        ...currentMsg,
        text: "",
      });

      const _messages = {
        ...msgContext?.messages,
        [currentThreadId?.toString()]: [
          ...(msgContext?.messages[currentThreadId?.toString()] || []),
          {
            ...currentMsg,
            id: Math.random(),
            tos: [{ id: currentThreadId.toString() }],
            document: {
              data: {
                id: params?.ref,
              },
            },
          },
        ],
      };

      console.log("processed messages here", _messages);

      if (Object.keys(_messages)?.length > 0) {
        msgContext?.setMessages(_messages);
      } else {
        console.log("bad formated messages");
      }

      const data = {
        text: currentMsg.text,
        from: sessionStorage.getItem("userId"),
        tos: [currentThreadId?.toString()],
        document: params?.ref?.toString(),
        date: new Date().toISOString(),
      };

      console.log("current message data", data);

      await MessagesApi.create({
        data,
      })
        .then((status) => {
          console.log("created a new msg object", status);
        })
        .catch((error) => {
          console.log("an error has occured when creating new message", error);
        });
    } else {
      showWarning("Message vide");
    }
  };

  React?.useEffect(() => {
    if (params?.ref !== "all") {
      const _currentCourrier = courriersList?.find((target) => {
        return target?.id?.toString() === params?.ref?.toString();
      });

      if (_currentCourrier) {
        setCurrentCourriers(_currentCourrier);
      }
    }
  }, [courriersList]);

  const [collaboSearch, setCollaboSearch] = React.useState("");

  const handleCollaboSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setCollaborators(
      users?.filter((user) => {
        return (
          user?.id?.toString() !==
            sessionStorage.getItem("userId")?.toString() &&
          Object.keys(user)?.some((key) => {
            return user[key]
              ?.toString()
              ?.toLowerCase()
              ?.includes(_search?.toLowerCase());
          })
        );
      })
    );

    setCollaboSearch(_search);
  };

  const [collaborators, setCollaborators] = React.useState([]);

  const [currentThreadId, setCurrentId] = React.useState(0);

  const [threadMessages, setThreadMessages] = React?.useState([]);
  const [threadsMap, setThreadsMap] = React.useState({});

  React.useEffect(() => {
    const threadsNotifsMapping = {};

    // re-order chat correspondents

    let _collaborators = [
      ...users?.filter(
        (user) =>
          user?.id?.toString() !== sessionStorage.getItem("userId")?.toString()
      ),
    ];

    notifsContext?.notifications
      ?.filter(
        (target) =>
          target?.seen === false &&
          target?.slug === "new-annotation" &&
          target?.producer?.username
      )
      ?.forEach((target) => {
        if (threadsNotifsMapping[target?.producer?.id?.toString()]) {
          threadsNotifsMapping[target?.producer?.id?.toString()] += 1;
        } else {
          threadsNotifsMapping[target?.producer?.id?.toString()] = 1;
        }
      });

    setThreadsMap(threadsNotifsMapping);

    _collaborators = _collaborators?.sort((collab1, collab2) => {
      const difference =
        (threadsNotifsMapping[collab2?.id?.toString()] || 0) -
        (threadsNotifsMapping[collab1?.id?.toString()] || 0);

      return difference;
    });

    console.log(
      "current received notifs",
      notifsContext?.notifications,
      threadsNotifsMapping,
      _collaborators
    );

    setCollaborators(_collaborators);
  }, [notifsContext?.notifications, users]);

  React?.useEffect(() => {
    if (currentCourrier?.id) {
      let _messages = [];

      (async () => {
        let documentId = null;

        console.log("current courrier for messages", currentCourrier);

        documentId = currentCourrier?.id;

        if (parseInt(documentId) > 0) {
          await MessagesApi.fromNotificationFrom({
            userId: sessionStorage.getItem("userId"),
            fromId: currentThreadId,
            documentId: documentId,
          })
            .then((results) => {
              _messages = results;
            })
            .catch((error) => {
              console.log("an error has occured when getting messages", error);
            });

          const threadsNotifsMapping = {};

          const notSeenNotifications = _messages
            ?.filter((target) => {
              if (
                !target?.notification?.seen &&
                target?.from?.id?.toString() !==
                  sessionStorage?.getItem("userId")
              ) {
                return true;
              }

              return false;
            })
            ?.map((target) => target?.notification);

          console.log(
            "not seen notifications here and threads mapping",
            notSeenNotifications
          );

          // disable notification's alerts

          if (notSeenNotifications?.length > 0) {
            await Promise.all(
              notSeenNotifications?.map((notif) => {
                return (async () => {
                  return NotificationsApi.update({
                    id: notif?.id?.toString(),
                  });
                })();
              })
            )
              .then((status) => {
                if (status) {
                  showSuccess("Alertes desactivées");

                  notifsContext?.refreshNotifcations();
                }
              })
              .catch((error) => {
                console.log(
                  "an error occured when updating message notifications",
                  error
                );

                showError("Alertes non desactivées. Recharger");
              });
          } else {
            console.log("all notification already seen");
          }

          msgContext?.setMessages({
            ...msgContext?.messages,
            [currentThreadId?.toString()]: _messages,
          });
        } else {
          console.log("no document found yet", { documentId });
        }
      })();
    } else {
      console.log("current document is null");
    }
  }, [currentThreadId, currentCourrier]);

  React.useEffect(() => {
    const _messages = msgContext?.messages[currentThreadId?.toString()];

    console.log("current message thread", _messages);

    if (Array.isArray(_messages)) {
      setThreadMessages(_messages);
    }
  }, [msgContext.messages]);

  React.useEffect(() => {
    (async () => {
      await OnlineApi.getStatus({ userId: currentThreadId })
        .then((status) => {
          setIsUserOnline(status?.isOnline);
        })
        .catch((error) => {
          console.log(
            "an error has occured when fetching online user's status",
            error
          );
        });
    })();
  }, [currentThreadId]);

  React.useEffect(() => {
    if (chatGroundRef?.current) {
      chatGroundRef.current.scrollTop = 1000000;
    }
  }, [threadMessages]);

  const chatGroundRef = React.createRef();

  return (
    <Layout>
      <Box
        sx={{
          mt: "1rem",
          width: "100%",
          bgcolor: theme.palette?.common?.white,
          p: "1rem",
          borderRadius: "25px",
          maxHeight: "calc(100vh - 100px)",
          height: "calc(100vh - 100px)",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "stretch",
            width: "100%",
            flexWrap: "wrap",
            maxHeight: "calc(100% - 0px)",
            height: "calc(100% - 0px)",
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "25%",
              borderRight: `1px solid ${theme?.palette?.grey[300]}`,
              pr: ".7rem",
              pt: ".5rem",
              maxWidth: "25%",
              overflowX: "hidden",
              maxHeight: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                py: ".3rem",
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <TextField
                size="small"
                type="search"
                value={collaboSearch}
                onChange={handleCollaboSearch}
                placeholder={"Contributeurs"}
                label={"Contributeurs"}
                sx={{
                  width: "100%",
                }}
              />
            </Box>
            <Stack
              direction={"column"}
              sx={{
                width: "100%",
                maxWidth: "100%",
                alignItems: "flex-start",
                maxHeight: "calc(100% - 70px)",
                overflowY: "auto",
                overflowX: "hidden",
                mt: "1rem",
              }}
            >
              {collaborators?.map((target, index, tab) => {
                const newMessages = threadsMap[target?.id?.toString()] > 0;

                return (
                  <ButtonBase
                    onClick={(event) => {
                      event?.preventDefault();

                      setCurrentId(target?.id);
                    }}
                    sx={{
                      width: "100%",
                      bgcolor:
                        target?.id === currentThreadId
                          ? theme?.palette?.grey[50]
                          : undefined,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      sx={{
                        alignItems: "center",
                        justifyContent: "flex-start",
                        width: "100%",
                        borderBottom: ![tab?.length - 1]?.includes(index)
                          ? `1px solid ${theme?.palette?.grey[300]}`
                          : undefined,
                        py: ".3rem",
                        cursor: "pointer",
                      }}
                    >
                      <Avatar
                        src={"/"}
                        sx={{
                          width: "35px",
                          height: "35px",
                          bgcolor: theme?.palette?.primary?.main,
                          mx: ".3rem",
                        }}
                      />
                      <Stack
                        direction={"row"}
                        sx={{
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack
                          direction={"column"}
                          sx={{
                            flexGrow: 1,
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              color: theme?.palette?.common?.black,
                              fontWeight: theme?.typography?.fontWeightMedium,
                              fontSize: "14px",
                            }}
                          >
                            {target?.username}
                          </Typography>
                          <Typography
                            sx={{
                              color: newMessages
                                ? theme?.palette?.primary?.main
                                : theme?.palette?.grey?.[700],
                              fontWeight: newMessages
                                ? theme?.typography?.fontWeightBold
                                : theme?.typography?.fontWeightLight,
                              fontSize: "13px",
                            }}
                          >
                            {newMessages
                              ? "Des nouveaux messages"
                              : "Aucun nouveau message"}
                          </Typography>
                        </Stack>
                        <Badge
                          badgeContent={threadsMap[target?.id?.toString()] || 0}
                          color="primary"
                          sx={{
                            mr: "1rem",
                          }}
                        />
                      </Stack>
                    </Stack>
                  </ButtonBase>
                );
              })}
            </Stack>

            <Box
              sx={{
                position: "absolute",
                bottom: "1rem",
                left: 0,
                right: 0,
                bgcolor: theme?.palette?.common?.white,
                p: ".1rem",
                pr: "1rem",
                pb: 0,
              }}
            >
              <Autocomplete
                size="small"
                disablePortal
                onChange={(event, courrier) => {
                  event?.preventDefault();

                  setCurrentCourriers(courrier);
                }}
                id="combo-box-demo"
                options={courriersList}
                value={currentCourrier}
                getOptionLabel={(option) => `${option?.title}`}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{`${option?.title}`}</li>
                )}
                // style={{ width: "100%" }}

                sx={{
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                  justifySelf: "flex-end",
                }}
                renderInput={(params) => (
                  <TextField
                    size={"small"}
                    {...params}
                    // label={title}
                    placeholder={"Courrier"}
                    label={"Courrier"}
                    variant={"outlined"}
                  />
                )}
              />
            </Box>
          </Stack>

          <Box
            sx={{
              width: "75%",
              maxHeight: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                  pl: ".5rem",
                }}
              >
                <Avatar
                  src={"/"}
                  sx={{
                    width: "35px",
                    height: "35px",
                    bgcolor: theme?.palette?.primary?.main,
                  }}
                />
                {collaborators?.find(
                  (collab) =>
                    collab?.id?.toString() === currentThreadId?.toString()
                )?.username?.length > 0 && (
                  <Stack
                    direction={"column"}
                    sx={{
                      alignItems: "flex-start",
                      ml: ".5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: theme.typography?.fontWeightBold,
                      }}
                    >
                      {
                        collaborators?.find(
                          (collab) =>
                            collab?.id?.toString() ===
                            currentThreadId?.toString()
                        )?.username
                      }
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: theme.typography?.fontWeightLight,
                        color: theme?.palette?.grey[700],
                      }}
                    >
                      {isUserOnline ? "Connecté" : "Deconnecté"}
                    </Typography>
                  </Stack>
                )}
              </Stack>
              <Typography
                onClick={(event) => {
                  event?.preventDefault();

                  navigate(`/apps/e-courrier/mails/${params?.ref}`);
                }}
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontWeight: theme?.typography?.fontWeightBold,
                  fontSize: "14px",
                  mr: ".5rem",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Document {currentCourrier?.title.slice(0, 90)}...
              </Typography>
            </Stack>
            <Divider
              sx={{
                my: ".5rem",
              }}
            />
            <Stack
              direction={"column"}
              sx={{
                alignItems: "flex-start",
                width: "100%",
                pl: ".7rem",
                flexGrow: 1,
                height: "calc(100% - 30px)",
                maxHeight: "calc(100% - 30px)",
              }}
            >
              <Stack
                ref={chatGroundRef}
                direction={"column"}
                sx={{
                  width: "100%",
                  maxHeight: "calc(100%- 100px)",
                  height: "calc(100% - 100px)",
                  overflowY: "auto",
                  // justifyContent: "flex-end",
                  py: ".5rem",
                  mb: "1rem",
                }}
              >
                {threadMessages?.map((target, index, tab) => {
                  const fromMe =
                    target?.from?.id?.toString() ===
                    sessionStorage.getItem("userId");
                  const color = fromMe
                    ? theme?.palette?.common?.white
                    : theme?.palette?.common?.black;
                  const bgcolor = fromMe
                    ? theme?.palette?.primary?.main
                    : `${theme?.palette?.primary?.main}10`;

                  return (
                    <Stack
                      direction={fromMe ? "row-reverse" : "row"}
                      sx={{
                        height: "max-content",
                        my: ".2rem",
                      }}
                    >
                      <Tooltip
                        title={target.from?.username}
                        placement={fromMe ? "top" : "left"}
                        arrow
                      >
                        <Avatar
                          sx={{
                            width: "40px",
                            height: "40px",
                            mr: fromMe ? undefined : ".5rem",
                            ml: !fromMe ? undefined : ".5rem",
                            bgcolor: fromMe
                              ? theme?.palette?.primary?.main
                              : `${theme?.palette?.primary?.main}10`,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                      <Typography
                        sx={{
                          bgcolor,
                          color,
                          maxWidth: "48%",
                          borderRadius: fromMe
                            ? "10px 0px 10px 10px"
                            : "0px 10px 10px 10px",
                          px: ".6rem",
                          py: ".4rem",
                          fontSize: "12px",
                          position: "relative",
                          zIndex: 200,
                          opacity: 1,
                          height: "max-content",
                        }}
                      >
                        <svg
                          height="10"
                          width="10"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            position: "absolute",
                            left: fromMe ? undefined : "-10px",
                            right: fromMe ? "-10px" : undefined,
                            top: 0,
                            backgroundColor: "transparent",
                            zIndex: 100,
                          }}
                        >
                          <polygon
                            points={fromMe ? "0,0 10,0 0,10" : "0,0 10,0 10,10"}
                            style={{
                              fill: bgcolor,
                              zIndex: 100,
                            }}
                          />
                        </svg>
                        {target?.text}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack
                direction={"row"}
                component={"form"}
                sx={{
                  py: ".5rem",
                  border: "none!important",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onSubmit={handleChatSubmit}
              >
                <TextField
                  size={"small"}
                  type="text"
                  value={currentMsg?.text}
                  onChange={handleChat}
                  placeholder={"Votre message"}
                  label={"Votre message"}
                  style={{
                    flexGrow: 1,
                  }}
                />
                <IconButton
                  type={"submit"}
                  sx={{
                    ml: ".5rem",
                    bgcolor: theme?.palette?.primary?.main,
                    "&:hover": {
                      bgcolor: theme?.palette?.primary?.main,
                    },
                  }}
                >
                  <Send
                    sx={{
                      color: theme?.palette?.common?.white,
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Layout>
  );
};

export default Conversations;
