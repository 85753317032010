// application routing logic definition

import { ReactComponent as FileIcon } from "../assets/svg/file.svg";
import { ReactComponent as SettingIcon } from "../assets/svg/setting.svg";
import { ReactComponent as WritingIcon } from "../assets/svg/writing.svg";
import Dashboard from "../pages/dashboard";

import MailDashboard from "../components/e-courrier/Dashboard";
import File from "../pages/file";
import Setting from "../pages/setting";
import Sign from "../pages/sign";
import MyDocuments from "../pages/workspace";
import AllDocuments from "../pages/all-documents";
import PendingDocuments from "../pages/pending-requests";
import ValidatedDocs from "../pages/validate-documents";
import LoginForm from "../pages/login";
import DocumentView from "../components/DocumentView";
import EnvelopeDocs from "../components/EncelopeDocs";
import AccessManagement from "../pages/settings";
import InvitedUser from "../components/InvitedUser";
import ParaphView from "../pages/paraph";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import Apps from "../pages/apps";

import CourrierFile from "../components/e-courrier/File";
import ArchiveFile from "../components/archiving/File";
import Contacts from "../components/e-courrier/Contacts";
import ContactView from "../components/e-courrier/ContactView";
import MailsTab from "../components/e-courrier/MailsTab";
import Library from "../components/archiving/Library";
import ArchiveView from "../components/archiving/ArchiveView";
import EntityView from "../components/e-courrier/EntityView";
import CourrierView from "../components/e-courrier/CourrierView";
import Conversations from "../pages/conversations";
import Notifications from "../components/e-courrier/Notifications.";
import Documents from "../pages/settings/Documents";
import Data from "../pages/archiving/Dashboard";
import SmartCheck from "../pages/archiving/SmartCheck";
import Search from "../pages/archiving/Search";
import Loading from "../pages/loading";
import ArchivesSettings from "../pages/settings/Archives";
import ProjectEditor from "../pages/projects/unique";
import ProjectsList from "../pages/projects";
import SettingsAll from "../pages/settings/SettingsAll";
import EntitiesTab from "../pages/archiving/Entities";

const routes = {
  auth: [
    {
      path: "/auth/reset-password",
      element: <ResetPassword />,
      classTag: "svg-fill",
    },
    {
      path: "/login",
      element: <LoginForm />,
      classTag: "svg-fill",
    },
    {
      path: "/auth/forgot-password",
      element: <ForgotPassword />,
      classTag: "svg-fill",
    },
  ],
  settings: [
    {
      path: "/settings/all",
      element: <SettingsAll />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/users",
      element: <AccessManagement />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/documents",
      element: <Documents />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/archives",
      element: <ArchivesSettings />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/entities/new",
      element: <EntityView />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/entities",
      element: <EntitiesTab />,
      classTag: "svg-fill",
    },
  ],
  "e-signature": [
    {
      path: "/apps/e-signature/dashboard",
      element: <Dashboard />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments/:ref",
      element: <DocumentView />,
      classTag: "svg-fill",
    },

    {
      path: "/apps/e-signature/envelope/:ref/documents",
      element: <EnvelopeDocs />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments/new-document",
      element: <File />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments/sign",
      element: <Sign />,
      classTag: "svg-stroke",
    },
    {
      path: "/apps/e-signature/requests/all",
      element: <AllDocuments />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments",
      element: <MyDocuments />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments/all",
      element: <MyDocuments />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/requests/pending",
      element: <PendingDocuments />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/requests/validated",
      element: <ValidatedDocs />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/attached/:id",
      element: <ParaphView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-signature/mydocuments/notifications",
      element: <Notifications />,
      classTag: "svg-fill",
    },
  ],
  "e-courrier": [
    {
      path: "/apps/e-courrier/dashboard",
      element: <MailDashboard />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/new",
      element: <CourrierFile />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/processed",
      element: <MailsTab title={"Traités"} />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/view/:ref",
      element: <MailsTab title={"Traités"} />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/pending",
      element: <MailsTab title={"En cours"} />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/archived",
      element: <MailsTab title={"Archivés"} />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/contacts",
      element: <Contacts />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/contacts/:ref",
      element: <ContactView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/contacts/new",
      element: <ContactView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/:ref",
      element: <CourrierView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/conversations/:ref",
      element: <Conversations />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/notifications",
      element: <Notifications />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/projects",
      element: <ProjectsList />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/e-courrier/mails/projects/:ref",
      element: <ProjectEditor />,
      classTag: "svg-fill",
    },
  ],
  archiving: [
    {
      path: "/apps/archiving/dashboard",
      element: <Data />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/archiving/library/new",
      element: <ArchiveFile />,
      classTag: "svg-fill",
    },
    {
      path: "/settings/entities/:ref",
      element: <EntityView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/archiving/library",
      element: <Library />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/archiving/library/view/:ref",
      element: <ArchiveView />,
      classTag: "svg-fill",
    },
    {
      path: "/apps/archiving/library/notifications",
      element: <Notifications />,
      classTag: "svg-fill",
    },
  ],
  unclassified: [
    {
      path: "/apps",
      element: <Apps />,
      classTag: "svg-fill",
    },
    {
      path: "/",
      element: <Loading />,
      classTag: "svg-fill",
    },
  ],
};

const routesList = Object.keys(routes)?.reduce((prev, nextKey) => {
  return [...prev, ...routes[nextKey]];
}, []);

export default routesList;
