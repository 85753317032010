// context defintion

import * as React from "react";
import CourriersApi from "../helpers/e-courrier/requests/courriers";
import { guardCtx } from "./Guard";
import { socketCtx } from "./io";
import { notificationCtx } from "./notification";
import { sessionCtx } from "./session";

const courriersCtx = React.createContext({});

const CourrierContext = ({ children }) => {
  const notifsContext = React?.useContext(notificationCtx);

  const showError = notifsContext?.showError;
  const showInfo = notifsContext?.showInfo;
  const showSuccess = notifsContext?.showSuccess;

  const refreshNotifcations = notifsContext?.refreshNotifcations;

  const isUserAuthenticanted = React.useContext(guardCtx)?.isUserAuthenticanted;
  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const [pendingCourriers, setPendingCourriers] = React.useState([]);
  const [processedCourriers, setProcessedCourriers] = React.useState([]);
  const [archivedCourriers, setArchivedCourriers] = React.useState([]);

  const refreshCourriers = () => {
    (async () => {
      await CourriersApi.getAll({
        userId: sessionStorage.getItem("userId"),
      })
        .then((data) => {
          setProcessedCourriers(data?.processed);
          setArchivedCourriers(data?.archived);
          setPendingCourriers(data?.pending);
        })
        .catch((error) => {
          console.log(
            "an error has occured when fetching user's courriers",
            error
          );

          showError("Une erreur est survenue");
        });
    })();
  };

  React.useEffect(() => {
    if (isUserAuthenticanted) {
      // after connecting the two apps, we need to have courriers list available as we will be switching the two apps
      if (currentApp?.toLowerCase() === "vizioo gec") {
        refreshCourriers();
      }
    }
  }, [isUserAuthenticanted, currentApp]);

  React?.useEffect(() => {
    const notifAudio = new Audio("/sounds/notification.mp3");

    const courrierHandler = (payload) => {
      console.log("new courrier event here", payload);

      if (payload?.from?.id?.toString() !== sessionStorage?.getItem("userId")) {
        notifAudio.play();

        refreshCourriers();
        refreshNotifcations();

        showInfo("Nouveau courrier");
      }
    };

    socketCtx.on("NEW_COURRIER", courrierHandler);

    return () => {
      socketCtx.off("NEW_COURRIER");
    };
  }, [pendingCourriers, processedCourriers, archivedCourriers]);

  return (
    <courriersCtx.Provider
      value={{
        pendingCourriers,
        processedCourriers,
        archivedCourriers,

        refreshCourriers,
      }}
    >
      {children}
    </courriersCtx.Provider>
  );
};

export default CourrierContext;
export { courriersCtx };
