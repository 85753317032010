// scaning api central

import lookup from "../../../utils/lookup"

class ScanApi {
    static async getDocument({endpoint}) {

        let documentUrl = "";

        await lookup(`${endpoint}/v1/scan`).then(res => res.json().then(result => {
            if (result?.data) {
                documentUrl = endpoint+ result?.data?.scanned_doc_link
            }
        })).catch(error => {
            console.log("an error has occured when scaning the document", error);
            
        })

        return {
            url: documentUrl
        }
    }
}

export default ScanApi
