// courriers data fetching central

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";
import strapiSanitize from "../../../utils/strapiSanitize";

class CourriersApi {
  static async getAll({ userId = sessionStorage.getItem("userId") }) {
    const pending = [];
    const processed = [];
    const archived = [];

    let courriers = []

    // data fetching here
    await lookup(
      `${BASE_URL}/api/documents?filters[source][$eq]=${"e-courrier"}&populate[receptionist][populate][0]=*&populate[receiptMail][populate][1]=underlying_file&populate[responseMail][populate][2]=underlying_file&populate[senderFrom][populate][3]=*&populate[underlying_file][populate][4]=*&populate[contributors][populate][5]=*&populate[handler][populate][6]=*&filters[$or][0][contributors][id][$eq]=${userId}&filters[$or][1][handler][id][$eq]=${userId}&filters[$or][2][receptionist][id][$eq]=${userId}&filters[$and][0][receiptOf][id][$null]=true&filters[$and][1][responseOf][id][$null]=true&sort=updatedAt:desc&`,
      {
        headers: getHeaders({}),
      }
    ).then(res => res.json().then(res => {

      console.log("received courriers data here", res);

      courriers = res?.data?.map(target => {
        const parsedCourrier = {
          id: target?.id,
          ...target?.attributes,
          contributors: target?.attributes?.contributors?.data?.map(target => {
            return {
              id: target?.id,
              ...target?.attributes
            }
          }),
          receptionist: {
            id: target?.attributes?.receptionist?.data?.id,
            ...target?.attributes?.receptionist?.data?.attributes
          },
          receptionisto: target?.attributes?.receptionist?.data?.attributes?.username,
          handler: {
            id: target?.attributes?.handler?.data?.id,
            ...target?.attributes?.handler?.data?.attributes
          },
          handlero: target?.attributes?.handler?.data?.attributes?.username,
          receiptMail: {
            id: target?.attributes?.receiptMail?.data?.id,
            ...target?.attributes?.receiptMail?.data?.attributes
          },
          responseMail: {
            id: target?.attributes?.responseMail?.data?.id,
            ...target?.attributes?.responseMail?.data?.attributes
          }
        }


        try {
          if (parsedCourrier["receptionist"]?.username) {
            parsedCourrier["contributors"]?.push(parsedCourrier["handler"]);
          }

          if (parsedCourrier["receptionist"]?.username) {
            parsedCourrier["contributors"]?.push(parsedCourrier["receptionist"]);
          }
        } catch (error) {
          console.log("an error has occured when adding handler and receptionist to contributors", error);

        }

        return parsedCourrier
      })
    })).catch(error => {
      console.log("an error has occured when getting user's courriers", error);

    })

    console.log("received courriers data here", courriers);
    // data processing

    pending.push(...courriers?.filter(target => !target?.closed && !target?.archived));
    processed.push(...courriers?.filter(target => target?.closed));
    archived.push(...courriers?.filter(target => target?.archived));

    return {
      pending, processed, archived
    }
  }

  static async update({ id, data }) {
    let status = {};

    await lookup(`${BASE_URL}/api/documents/${id}`, {
      method: "PUT",
      body: JSON.stringify({ data }),
      headers: getHeaders({})
    }).then(stat => stat.json().then(stat => {
      status = stat;
    })).catch(error => {
      console.log("an error has occured when updating courrier", error);

    });

    return status
  }

  static async getOne({ id, }) {

    let courrierObj = {};

    await lookup(`${BASE_URL}/api/documents/${id}?populate[annotations][populate][0]=tos&populate[underlying_file][populate][1]=*&populate[receptionist][populate][2]=*&populate[contributors][populate][3]=*&populate[annexes][populate][4]=*&populate[annexes][populate][underlying_file][populate][5]=*&populate[annotations][populate][6]=from&populate[handler][populate][7]=*&populate[receiptMail][populate][8]=underlying_file&populate[responseMail][populate][9]=underlying_file`, {
      headers: getHeaders({}),
      method: "GET",
    }).then(result => result.json().then(result => {
      
      courrierObj = {
        ...result?.data?.attributes,
        id: result?.data?.id,
        contributors: result?.data?.attributes?.contributors?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes
          }
        }),
        annexes: result?.data?.attributes?.annexes?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes,
            underlying_file: {
              name: target?.attributes?.underlying_file?.data?.attributes?.name,
              url: target?.attributes?.underlying_file?.data?.attributes?.url,
            }
          }
        }),
        receptionist: {
          id: result?.data?.attributes?.receptionist?.data?.id,
          ...result?.data?.attributes?.receptionist?.data?.attributes
        },
        handler: {
          id: result?.data?.attributes?.handler?.data?.id,
          ...result?.data?.attributes?.handler?.data?.attributes
        },
        underlying_file: {
          name: result?.data?.attributes?.underlying_file?.data?.attributes?.name,
          path: result?.data?.attributes?.underlying_file?.data?.attributes?.url,
        },
        receiptMail: {
          id: result?.data?.attributes?.receiptMail?.data?.id,
          ...result?.data?.attributes?.receiptMail?.data?.attributes
        },
        responseMail: {
          id: result?.data?.attributes?.responseMail?.data?.id,
          ...result?.data?.attributes?.responseMail?.data?.attributes
        }
      }

      console.log("current courrier found here", courrierObj);


      try {
        if (courrierObj["handler"]?.username) {
          courrierObj["contributors"]?.push(courrierObj["handler"]);
        }

        if (courrierObj["receptionist"]?.username) {
          courrierObj["contributors"]?.push(courrierObj["receptionist"]);
        }
      } catch (error) {
        console.log("an error has occured when adding handler and receptionist to contributors", error);

      }

      try {
        if (courrierObj["responseMail"]?.title) {
          courrierObj["annexes"]?.push({
            ...courrierObj["responseMail"],
            underlying_file: {
              name: "Réponse · " + courrierObj["responseMail"]?.underlying_file?.data?.attributes?.name,
              url: courrierObj["responseMail"]?.underlying_file?.data?.attributes?.url,
            }
          });
        }

        if (courrierObj["receiptMail"]?.title) {
          courrierObj["annexes"]?.push({
            ...courrierObj["receiptMail"],
            underlying_file: {
              name: "Accusé · " + courrierObj["receiptMail"]?.underlying_file?.data?.attributes?.name,
              url: courrierObj["receiptMail"]?.underlying_file?.data?.attributes?.url,
            }
          });
        }
      } catch (error) {
        console.log("an error has occured when adding receipt and response as annexes", error);

      }

    })).catch(error => {
      console.log("an error has occured when getting single courrier", error);

    });

    console.log("received single courrier here", courrierObj);

    return courrierObj
  }
}

export default CourriersApi
