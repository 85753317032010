// component definition

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, useTheme } from "@mui/material";
import ConfigureStep from "./ConfigureStep";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { notificationCtx } from "../../context/notification";
import lookup from "../../utils/lookup";
import { BASE_URL } from "../../constants/api";
import CourriersApi from "../../helpers/e-courrier/requests/courriers";
import { courriersCtx } from "../../context/courriers";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    title: "Contact",
    short: "Information de l'expéditeur",
  },
  {
    title: "Informations",
    short: "Informations sur le courrier",
  },
  {
    title: "Chargement",
    short: "Chargement du courrier",
  },
];

const MailConfigure = ({}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const courriersContext = React?.useContext(courriersCtx);
  const navigate = useNavigate();

  const refreshCourriers = courriersContext?.refreshCourriers;

  const showError = React?.useContext(notificationCtx)?.showError;
  const showSuccess = React?.useContext(notificationCtx)?.showSuccess;
  const showWarning = React?.useContext(notificationCtx)?.showWarning;

  const assessDataCompleted = (step, mailIdProvided) => {
    try {
      const fields = JSON.parse(sessionStorage.getItem("__fields__"));
      const agents = JSON.parse(sessionStorage.getItem("__agents__"));
      const dates = JSON.parse(sessionStorage?.getItem("__dates__"));

      const underlyingDoc = JSON.parse(
        sessionStorage?.getItem("__underlying_doc__")
      );

      if (step === 0) {
        if (fields.submitter?.length > 0 || mailIdProvided) {
          if (fields.comments?.length > 0) {
            if (dates?.entryDate?.length > 0) {
              if (dates?.entryTime?.length > 0) {
                if (agents?.contact?.name?.length > 0 || mailIdProvided) {
                  return true;
                } else {
                  showWarning("Veuillez selectionner le contact");
                }
              } else {
                showWarning("Veuillez entrer l'heure du courrier");
              }
            } else {
              showWarning("Veuillez entrer la date du courrier");
            }
          } else {
            showWarning("Ecrivez aucun commentaire s'il y en a pas");
          }
        } else {
          showWarning("Veuillez renseigner le porteur du courrier");
        }

        return false;
      } else if (step === 1) {
        if (fields.name?.length > 0) {
          if (fields.type?.length > 0 || mailIdProvided) {
            if (fields.emergency?.length > 0) {
              if (
                fields?.isAcknowledgementOfReceipt || fields?.isMailResponse
                  ? underlyingDoc?.title?.length > 0
                  : true
              ) {
                if (fields.freighted?.toString()?.length > 0) {
                  if (
                    agents?.secretary?.username?.length > 0 ||
                    mailIdProvided
                  ) {
                    if (fields.freighted) {
                      if (fields.weight > 0) {
                        if (fields.cost > 0) {
                          if (fields.destination?.length > 2) {
                            return true;
                          } else {
                            showWarning(
                              "Veuillez entrer la destination du courrier"
                            );
                          }
                        } else {
                          showWarning("Veuillez entrer le coût du courrier");
                        }
                      } else {
                        showWarning("Veuillez entrer le poids du courrier");
                      }
                    } else {
                      return true;
                    }
                  } else {
                    showWarning("Veuillez selectionner le secretaire");
                  }
                } else {
                  showWarning("Veuiller indiquer si le courrier sera frêté");
                }
              } else {
                showWarning("Veuillez selectionner le courrier adjacent");
              }
            } else {
              showWarning("Veuillez selectioner l'urgence du courrier");
            }
          } else {
            showWarning("Veuillez entrer le type du courrier");
          }
        } else {
          showWarning("Veuillez entrer le nom du courrier");
        }

        return false;
      }

      return true;
    } catch (error) {
      showWarning("Veuillez remplir les champs");
      return false;
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    const editedDocId = new URLSearchParams(window?.location?.search).get(
      "mail_id"
    );

    if (assessDataCompleted(activeStep, editedDocId)) {
      if (editedDocId && activeStep === 1) {
        let agents = JSON.parse(sessionStorage.getItem("__agents__"));
        let fields = JSON.parse(sessionStorage.getItem("__fields__"));

        let dates = {};

        dates = JSON.parse(sessionStorage.getItem("__dates__"));
        dates.entryDate = new Date(dates?.entryDate);
        dates.entryTime = new Date(dates?.entryTime);
        dates.final = new Date(
          `${
            dates.entryDate.getMonth() + 1
          }/${dates.entryDate.getDate()}/${dates.entryDate.getFullYear()} ${dates.entryTime.getHours()}:${dates.entryTime.getMinutes()}`
        ).toISOString();

        const payload = {
          entryDate: dates.final,
          receptionist: sessionStorage.getItem("userId")?.toString() || null,
          source: "e-courrier",
          ...fields,
          handler: agents?.secretary?.id?.toString() || null,
          title: fields["name"],
          type: "outgoing",
        };

        console.log(
          "current payload to be sent as payload to update a outgoing courrier",
          payload
        );

        await CourriersApi.update({ id: editedDocId, data: payload })
          .then((status) => {
            if (status) {
              showSuccess("Informations ajoutées");

              refreshCourriers();

              navigate(`/apps/e-courrier/mails/pending`);
            } else {
              showError("Une erreur est survenue. Réessayez");
            }
          })
          .catch((error) => {
            console.log(
              "an error has occured when updating the document",
              error
            );
          });
      } else {
        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else {
      console.log("cannot continue befor filling the required fields");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((target, index) => {
          const stepProps = {};

          const labelProps = {
            optional: (
              <Typography variant="caption">{target?.short}</Typography>
            ),
          };

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={target?.title} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography
                  sx={{
                    fontWeight: stepProps.completed
                      ? theme?.typography?.fontWeightBold
                      : theme?.typography?.fontWeightRegular,
                  }}
                >
                  {target?.title}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              maxHeight: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            <ConfigureStep step={activeStep + 1} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              onClick={activeStep === 0 ? () => {} : handleBack}
              className="btn-secodary flex-auto"
              sx={{
                bgcolor: `${theme?.palette?.common.white}`,
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: `${theme?.palette?.common.white}`,
                },
                // py: ".3rem",
                color: theme?.palette?.primary?.main,
                borderRadius: "1rem",
                mt: "1rem",
                px: "1rem",
                width: "45%",
                cursor: activeStep === 0 ? "not-allowed" : "pointer",
              }}
            >
              Précédent
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={() => {}}
                className="btn-secodary flex-auto"
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  // py: ".3rem",
                  color: theme?.palette?.common?.white,
                  borderRadius: "1rem",
                  mt: "1rem",
                  px: "1rem",
                  width: "45%",
                  cursor: "not-allowed",
                }}
              >
                Suivant
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                className="btn-secodary flex-auto"
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  // py: ".3rem",
                  color: theme?.palette?.common?.white,
                  borderRadius: "1rem",
                  mt: "1rem",
                  px: "1rem",
                  width: "45%",
                }}
              >
                Suivant
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default MailConfigure;
