// api requests central

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";
import strapiSanitize from "../../../utils/strapiSanitize";

class ContactApi {
    static async getAll({ limit, page }) {
        let contacts = [];

        await lookup(`${BASE_URL}/api/contacts`, {
            headers: getHeaders({}),
            method: "GET"
        }).then(res => res.json().then(res => {
            if (res.data?.length > 0) {
                contacts = strapiSanitize({ object: res.data })
            }
        })).catch(error => {
            console.log("an error has occured when fetching all contacts", error);
        });

        return contacts
    }

    static async getOne({ id }) {
        let contact = [];

        await lookup(`${BASE_URL}/api/contacts/${id}`, {
            headers: getHeaders({}),
            method: "GET"
        }).then(res => res.json().then(res => {
            if (res.data) {
                contact = strapiSanitize({ object: res })
            }
        })).catch(error => {
            console.log("an error has occured when fetching one contact", error);
        });

        return contact
    }

    static async create(data) {
        let status = null;

        await lookup(`${BASE_URL}/api/contacts`, {
            headers: getHeaders({}),
            body: JSON.stringify({ data: data }),
            method: "POST"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when creating a contact", error);
        });

        return status
    }

    static async update({data, id}) {
        let status = null;

        await lookup(`${BASE_URL}/api/contacts/${id}`, {
            headers: getHeaders({}),
            body: JSON.stringify({ data: data }),
            method: "PUT"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when updating a contact", error);
        });

        return status
    }

    static async delete({ id }) {
        let status = null;

        await lookup(`${BASE_URL}/api/contacts/${id}`, {
            headers: getHeaders({}),
            method: "DELETE"
        }).then(res => res.json().then(res => {

            status = res.data
        })).catch(error => {
            console.log("an error has occured when deleting a contact", error);
        });

        return status
    }
}

export default ContactApi
