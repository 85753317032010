// component defintion

import * as React from "react";
import {
  useTheme,
  Stack,
  Grid,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { scanCtx } from "../context/scan";
import { settingsCtx } from "../context/settings";
import { notificationCtx } from "../context/notification";
import ScanApi from "../helpers/general/requests/scan";
import imageToPdf from "../utils/imageToPdf";
import { filesCtx } from "../context/files";

const Scanning = ({}) => {
  const theme = useTheme();

  const scanContext = React.useContext(scanCtx);
  const settingsContext = React?.useContext(settingsCtx);
  const notifContext = React.useContext(notificationCtx);
  const filesContext = React?.useContext(filesCtx);

  const setScanOpen = scanContext?.setScanOpen;
  const setScannedDocs = scanContext?.setScannedDocs;
  const scannedDocs = scanContext?.scannedDocs;
  const pendingDocs = scanContext?.pendingDocs;
  const setPendingDocs = scanContext?.setPendingDocs;

  const showInfo = notifContext?.showInfo;
  const showSuccess = notifContext?.showSuccess;
  const showError = notifContext?.showError;
  const showWarning = notifContext?.showWarning;

  const scanEndPoint = settingsContext?.scanEndpoint;

  const fileChangedHandler = filesContext?.fileChangedHandler;

  const handleScan = async (event) => {
    event?.preventDefault();

    // scan api instruction

    if (window.confirm("Le document à scanner, est-il prêt ?")) {
      showInfo("Scan du document en cours ...");

      setPendingDocs([{}]);

      await ScanApi.getDocument({ endpoint: scanEndPoint })
        .then((documentObj) => {
          console.log("received scanned doc here", documentObj);

          if (
            !documentObj?.url?.includes("/error") &&
            documentObj?.url?.length > 0
          ) {
            setScannedDocs([...scannedDocs, documentObj?.url]);
            setPendingDocs([]);

            showSuccess("Page scannée");
          } else {
            showError("Une erreur est survenue, Réessayez");

            setPendingDocs([]);
          }
        })
        .catch((error) => {
          console.log("an error has occured when scanning documents", error);

          setPendingDocs([]);

          showError("Une erreur est survenue, Réessayez");
        });
    }
  };

  const handleCompile = async (event) => {
    event?.preventDefault();

    console.log("current scanned docs", scannedDocs);

    // scan images compilation
    if (scannedDocs?.length > 0) {
      if (window.confirm("Avez-vous scanné l'intégralité des pages ?")) {
        await imageToPdf({ imgUrls: scannedDocs })
          .then(async (result) => {
            await fileChangedHandler(null, true, result?.file)
              .then((status) => {
                showInfo("Traitement finalisé");

                console.log("received compiled file", result?.file);

                setScannedDocs([]);
                setScanOpen(false);
              })
              .catch((error) => {
                console.log(
                  "an error has occured when merging scanned docs",
                  error
                );

                showError("Une erreur est survenue. Réessayer");
              });
          })
          .catch((error) => {
            console.log("an error has occured when mergin scanned docs", error);

            showError("Une erreur est survenue. Réessayer");
          });
      }
    } else {
      showWarning("Veuillez scanner au moins une page");
    }
  };

  return (
    <Stack
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          maxWidth: "700px",
          minWidth: "250px",
          width: "30%",
          bgcolor: theme?.palette?.common?.white,
          borderRadius: "15px",
          boxShadow: "0px 0px 20px -4px #cddde1",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            px: "1rem",
            py: ".5rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.primary?.main,
              fontWeight: theme?.typography?.fontWeightBold,
            }}
          >
            Scan
          </Typography>
          <IconButton
            onClick={(event) => {
              event?.preventDefault();

              setScanOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Stack
          direction={"column"}
          sx={{
            alignItems: "stretch",
            p: "1rem",
          }}
          onSubmit={(event) => {}}
        >
          <Grid
            container
            sx={{
              width: "100%",
              px: ".5rem",
            }}
            gap={1}
          >
            <Grid xs={12}>
              <Typography
                sx={{
                  color: theme?.palette?.grey[700],
                  fontSize: "14px",
                }}
              >
                [{pendingDocs?.length}] document(s) en cours de scan, veuillez
                patienter
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography
                sx={{
                  color: theme?.palette?.grey[700],
                  fontSize: "14px",
                }}
              >
                [{scannedDocs?.length}] document(s) scannés
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              sm={6}
              xs={12}
              sx={{
                pr: ".3rem",
              }}
            >
              <Button
                onClick={handleScan}
                sx={{
                  bgcolor: `${theme?.palette?.secondary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.secondary?.main}`,
                  },
                  width: "100%",
                  // py: ".3rem",
                  borderRadius: "1rem",
                  // mt: "1rem",
                  color: theme?.palette?.primary?.main,
                  mt: "1rem",
                }}
              >
                Scanner
              </Button>
            </Grid>
            <Grid sm={6} xs={12}>
              <Button
                onClick={handleCompile}
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  width: "100%",
                  // py: ".3rem",
                  borderRadius: "1rem",
                  // mt: "1rem",
                  color: theme?.palette?.common?.white,
                  mt: "1rem",
                }}
              >
                Soumettre
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Scanning;
