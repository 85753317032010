// page component definition
import * as React from "react";

import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";

import {
  useTheme,
  Stack,
  Typography,
  Box,
  Grid,
  Chip,
  useMediaQuery,
  Avatar,
  IconButton,
  Slider,
  TextField,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import NavigationLine from "../../components/NavigationLine";
import SettingsApi from "../../helpers/general/requests/settings";
import { notificationCtx } from "../../context/notification";
import { settingsCtx } from "../../context/settings";

const Documents = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));
  const notifsContext = React.useContext(notificationCtx);
  const settingsContext = React?.useContext(settingsCtx);

  const showSuccess = notifsContext?.showSuccess;
  const showError = notifsContext?.showError;

  const alertStates = settingsContext?.alertStates;
  const setAlertStates = settingsContext?.setAlertStates;
  const alertDays = settingsContext?.alertDays;
  const setAlertDays = settingsContext?.setAlertDays;
  const alertSettings = settingsContext?.alertSettings;
  const refreshSettings = settingsContext?.refreshSettings;
  const scanEndpoint = settingsContext?.scanEndpoint;
  const setScanEndpoint = settingsContext?.setScanEndpoint;
  const referencePrefix = settingsContext?.referencePrefix;
  const setReferencePrefix = settingsContext?.setReferencePrefix;
  const companyObj = settingsContext?.companyObj;
  const setCompanyObj = settingsContext?.setCompanyObj;

  const handleLevel0 = async (event) => {
    event?.preventDefault();

    await SettingsApi.update({
      data: {
        alerts: alertStates,
        ttls: alertDays,
      },
    })
      .then((status) => {
        showSuccess("Paramètres modifiés avec succès");
        refreshSettings();
      })
      .catch((error) => {
        console.log("an error has occured when upda/creating settings", error);

        showError("Une erreur est survenue. Réessayez");
      });
  };

  const handleLevel1 = async (event) => {
    event?.preventDefault();

    await SettingsApi.update({
      data: {
        scanEndpoint,
        referencePrefix,
        company: companyObj,
      },
    })
      .then((status) => {
        showSuccess("Paramètres modifiés avec succès");
        refreshSettings();
      })
      .catch((error) => {
        console.log(
          "an error has occured when upda/creating level1 settings",
          error
        );

        showError("Une erreur est survenue. Réessayez");
      });
  };

  const companyFields = [
    {
      name: "name",
      title: "Nom",
    },
    {
      name: "sigle",
      title: "Sigle",
    },
    {
      name: "email",
      title: "Adresse mail",
    },
  ];

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigationLine
            firstTitle={"Paramètres"}
            secondTitle={"Général"}
            backLink={"/settings/all"}
          />
        </Stack>
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            alignItems: "flex-start",
            bgcolor: theme?.palette?.common?.white,
            p: "1rem",
            borderRadius: "15px",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
            }}
          >
            Recurrence d'alertes
          </Typography>
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              width: "100%",
              my: "1rem",
            }}
          >
            {alertSettings?.map((target) => {
              return (
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: "20%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                    }}
                  >
                    {target?.title}
                  </Typography>
                  <Slider
                    aria-label={target?.title}
                    valueLabelDisplay="auto"
                    onChangeCommitted={(_, value) => {
                      setAlertStates({
                        ...alertStates,
                        [target?.field]: value,
                      });
                    }}
                    value={alertStates[target?.field]}
                    shiftStep={1}
                    step={1}
                    marks
                    min={1}
                    max={12}
                    sx={{
                      width: "65%",
                    }}
                    color={target?.color}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
            }}
          >
            Jours d'alertes
          </Typography>
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              width: "100%",
              my: "1rem",
            }}
          >
            {alertSettings?.map((target) => {
              return (
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: "20%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "14px",
                    }}
                  >
                    {target?.title}
                  </Typography>
                  <Slider
                    aria-label={target?.title}
                    valueLabelDisplay="auto"
                    onChangeCommitted={(_, value) => {
                      setAlertDays({
                        ...alertDays,
                        [target?.field]: value,
                      });
                    }}
                    value={alertDays[target?.field]}
                    shiftStep={1}
                    step={1}
                    marks
                    min={1}
                    max={12}
                    sx={{
                      width: "65%",
                    }}
                    color={target?.color}
                  />
                </Stack>
              );
            })}
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={handleLevel0}
              type="submit"
              className="btn-primary items-center justify-center"
              style={{
                width: "max-content",
                fontSize: "14px",
                padding: ".5rem 1rem",
                marginLeft: "auto",
                boxShadow: "none",
              }}
            >
              Confirmer
            </button>
          </Stack>
        </Stack>

        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            alignItems: "flex-start",
            bgcolor: theme?.palette?.common?.white,
            p: "1rem",
            borderRadius: "15px",
            mt: "1rem",
            mb: "5rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
            }}
          >
            Serveur de scan
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            name={"scannServerEndpoint"}
            fullWidth
            sx={{
              mt: ".5rem",
            }}
            onChange={(event) => {
              event?.preventDefault();

              setScanEndpoint(event?.target?.value);
            }}
            value={scanEndpoint}
          />
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
              mt: "1rem",
            }}
          >
            Préfixe reférence courriers
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            name={"scannServerEndpoint"}
            fullWidth
            sx={{
              mt: ".5rem",
            }}
            value={referencePrefix}
            onChange={(event) => {
              event?.preventDefault();

              setReferencePrefix(event?.target?.value);
            }}
          />
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontSize: "14px",
              mt: "1rem",
            }}
          >
            Société
          </Typography>
          <Grid
            container
            sx={{
              width: "100%",
              mt: ".5rem",
            }}
          >
            {companyFields?.map((target, index, targets) => {
              return (
                <Grid
                  sm={4}
                  xs={12}
                  sx={{
                    pr: index !== targets?.length - 1 ? ".5rem" : "",
                    pb: ".3rem",
                  }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    name={target?.name}
                    fullWidth
                    sx={{
                      fontSize: "14px",
                    }}
                    placeholder={target?.title}
                    value={companyObj?.[target?.name] || null}
                    onChange={(event) => {
                      event?.preventDefault();

                      setCompanyObj({
                        ...companyObj,
                        [target?.name]: event?.target?.value,
                      });
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={handleLevel1}
              type="submit"
              className="btn-primary items-center justify-center"
              style={{
                width: "max-content",
                fontSize: "14px",
                padding: ".5rem 1rem",
                marginLeft: "auto",
                boxShadow: "none",
                marginTop: "1rem",
              }}
            >
              Confirmer
            </button>
          </Stack>
        </Stack>
      </Box>
    </Layout>
  );
};

export default Documents;
