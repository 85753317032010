// archives requests central

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";
import strapiSanitize from "../../../utils/strapiSanitize";

class ArchivesApi {
  static async getAll({ id, entities=[], role="admin" }) {
    let archives = [];

    if (entities?.length > 0 || role?.toLowerCase() === "admin") {
      await lookup(`${BASE_URL}/api/documents?populate=*&sort=createdAt:desc${ role?.toLowerCase() === "admin" ? "" : `${entities?.map((entityId, index) => `&filters[entities][id][$in][${index}]=${entityId}`)}`}`, {
        method: "GET",
        headers: getHeaders({}),
      })
        .then((res) =>
          res.json().then((results) => {
  
            if (Array.isArray(results?.data)) {
  
              archives = strapiSanitize({object: results?.data})?.map(target => {
                return  {
                  ...target,
                  contributors: target?.contributors?.data?.map(target => {
                    return {
                      id: target?.id,
                      ...target?.attributes
                    }
                  }),
                  receptionist: {
                    id: target?.receptionist?.data?.id,
                    ...target?.receptionist?.data?.attributes
                  },
                  underlying_file: {
                    name: target?.underlying_file?.data?.attributes?.name,
                    path: target?.underlying_file?.data?.attributes?.url,
                  },
                  entities: target?.entities?.data?.map(target => {
                    return {
                      id: target?.id,
                      ...target?.attributes,
                    }
                  }),
                  categories: target?.categories?.data?.map(target => {
                    return {
                      id: target?.id,
                      ...target?.attributes,
                    }
                  }),
                  tags: target?.tags?.data?.map(target => {
                    return {
                      id: target?.id,
                      ...target?.attributes,
                    }
                  }),
          
                }
          
              })
            }
  
            console.log("received archives data here", archives);
          })
        )
        .catch((error) => {
          console.log("an error has occured when fetching archives data", error);
        });
    }

    return archives;
  }


  static async getOne({ id, }) {

    let archiveObj = {};

    await lookup(`${BASE_URL}/api/documents/${id}?populate=*`, {
      headers: getHeaders({}),
      method: "GET",
    }).then(result => result.json().then(result => {
      
      archiveObj = {
        ...result?.data?.attributes,
        id: result?.data?.id,
        contributors: result?.data?.attributes?.contributors?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes
          }
        }),
        receptionist: {
          id: result?.data?.attributes?.receptionist?.data?.id,
          ...result?.data?.attributes?.receptionist?.data?.attributes
        },
        underlying_file: {
          name: result?.data?.attributes?.underlying_file?.data?.attributes?.name,
          path: result?.data?.attributes?.underlying_file?.data?.attributes?.url,
        },
        entities: result?.data?.attributes?.entities?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes,
          }
        }),
        categories: result?.data?.attributes?.categories?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes,
          }
        }),
        tags: result?.data?.attributes?.tags?.data?.map(target => {
          return {
            id: target?.id,
            ...target?.attributes,
          }
        }),

      }

      console.log("current archive found here", archiveObj);

    })).catch(error => {
      console.log("an error has occured when getting single archive", error);

    });

    console.log("received single archive here", archiveObj);

    return archiveObj
  }

  static async update({id, data }) {
    let status = null;

    await lookup(`${BASE_URL}/api/documents/${id}`, {
      method: "PUT",
      headers : getHeaders({}),
      body: JSON.stringify({data})
    }).then(res => res.json().then(result => {
      console.log("received data after archive update", result);

      if (result) {
        status = result
      }
    })).catch(error => {
      console.log("an error has occured when calling document update", error);
      
    });


  return status
  }

}

export default ArchivesApi;
