// context definition

import * as React from "react";

import { notificationCtx } from "./notification";
import { usersCtx } from "./users";
import { guardCtx } from "./Guard";
import { sessionCtx } from "./session";
import EntityApi from "../helpers/archiving/requests/entity";
import { appDataCtx } from "./appData";

const entitiesCtx = React.createContext({});

const EntitiesContext = ({ children }) => {
  const users = React.useContext(usersCtx)?.users;

  const appDataContext = React?.useContext(appDataCtx);

  const [entities, setEntities] = React.useState([]);

  const isUserAuthenticanted = React.useContext(guardCtx)?.isUserAuthenticanted;
  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const showError = React?.useContext(notificationCtx)?.showError;
  const showInfo = React?.useContext(notificationCtx)?.showInfo;
  const showSuccess = React?.useContext(notificationCtx)?.showSuccess;

  const refreshEntities = async () => {
    await EntityApi.getAll()
      .then((results) => {
        console.log("all entities datat here", results);

        if (Array.isArray(results)) {
          setEntities(results);
        }
      })
      .catch((error) => {
        console.log("an error has occured when fetching all teams", error);

        showError("Oups! Une erreur est survenue", error);
      });
  };

  React?.useEffect(() => {
    appDataContext?.setEntitiesIn(
      entities?.filter((target) => {
        return target?.employees?.some((employee) => {
          return (
            employee?.id?.toString() ===
            sessionStorage?.getItem("userId")?.toString()
          );
        });
      })
    );
  }, [entities]);

  React.useEffect(() => {
    if (users?.length > 0) {
      if (isUserAuthenticanted) {
        if (currentApp?.toLowerCase() === "vizioo archivage") {
          (async () => {
            await Promise.all([refreshEntities()])
              .then((status) => {
                console.log("fetched entities successfully");
              })
              .catch((error) => {
                showError("Une erreur est survenue", error);

                console.log("an error has occured when feting entities", error);
              });
          })();
        }
      }
    }
  }, [users, isUserAuthenticanted, currentApp]);

  return (
    <entitiesCtx.Provider
      value={{
        entities,
        refreshEntities,
      }}
    >
      {children}
    </entitiesCtx.Provider>
  );
};

export { entitiesCtx };
export default EntitiesContext;
