// component defintion

import * as React from "react";
import {
  useTheme,
  Box,
  Typography,
  Stack,
  Badge,
  useMediaQuery,
  Checkbox,
  Chip,
} from "@mui/material";
import Layout from "../Layout";
import NotificationsApi from "../../helpers/e-courrier/requests/notifications";
import { notificationCtx } from "../../context/notification";
import { useNavigate } from "react-router-dom";

const emergencyTexts = {
  low: "Normal",
  medium: "Moyen",
  high: "Haute",
};

const emergencyColors = {
  low: "info",
  medium: "warning",
  high: "error",
};

const Notifications = ({}) => {
  const theme = useTheme();

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));
  const notificationContext = React.useContext(notificationCtx);
  const navigate = useNavigate();

  const notifications = notificationContext.notifications;

  return (
    <Layout>
      <Stack
        sx={{
          pt: "1rem",
          // px: !screen900 ? "2rem" : ".5rem",
          overflowX: "hidden",
          maxWidth: !screen900 ? "100%" : undefined,
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            my: "1rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.grey[700],
              fontWeight: theme?.typography?.fontWeightBold,
              fontSize: "16px",
            }}
          >
            {"Notifications"}
          </Typography>
        </Stack>
        <Stack
          direction={"column"}
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            maxHeight: screen900 ? undefined : "calc(100vh - 160px)",
            overflowY: "auto",
            bgcolor: theme?.palette?.common?.white,
            borderRadius: "15px",
            overflowX: "hidden",
            maxWidth: "100%",
            p: "1rem",
          }}
        >
          {!(notifications?.length > 0) ? (
            <Stack
              sx={{
                width: "100%",
                bgcolor: theme?.palette?.common?.white,
                // borderRadius: screen900 ? "10px" : "15px",
                maxHeight: screen900 ? "max-content" : "50vh",
                overflowY: "auto",
                p: "1rem",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  color: theme?.palette?.grey[700],
                }}
              >
                Aucune notification
              </Typography>
            </Stack>
          ) : (
            ""
          )}
          {notifications?.map((target, index, tab) => {
            return (
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  // my: ".3rem",
                  // bgcolor: theme?.palette?.common?.white,
                  px: "1rem",
                  py: ".5rem",
                  // borderRadius: "10px",
                  width: "100%",
                  borderBottom:
                    index !== tab?.length - 1
                      ? `1px solid ${theme?.palette?.grey[300]}`
                      : undefined,
                  transition: "all .3s",
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: theme?.palette?.grey[50],
                  },
                  borderRadius: "10px",
                  bgcolor: !target?.seen ? theme?.palette?.grey[50] : undefined,
                }}
                onClick={(event) => {
                  event?.preventDefault();

                  navigate(target?.link);
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexGrow: 1,
                  }}
                >
                  <Stack
                    direction={"row"}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      width: "max-content",
                    }}
                  >
                    <Checkbox
                      inputProps={{
                        "aria-label": "notif-select",
                      }}
                      sx={{
                        mr: "1rem",
                      }}
                    />
                  </Stack>

                  <Stack
                    direction={"column"}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Chip
                      color={emergencyColors[target["emergency"]]}
                      size={"small"}
                      variant={"outlined"}
                      label={emergencyTexts[target["emergency"]]}
                      sx={{
                        width: "max-content",
                        py: ".1rem",
                      }}
                    />
                    <Typography
                      sx={{
                        color: theme?.palette?.common.black,
                        fontSize: "14px",
                        fontWeight: theme?.typography?.fontWeightRegular,
                        maxWidth: "70%",
                      }}
                    >
                      {target?.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme?.palette?.grey[700],
                        fontSize: "14px",
                        fontWeight: theme?.typography?.fontWeightRegular,
                        maxWidth: "100%",
                      }}
                      dangerouslySetInnerHTML={{ __html: target?.text }}
                    ></Typography>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    alignItems: "flex-start",
                    ml: "1rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme?.palette?.grey[600],
                      fontSize: "14px",
                    }}
                  >
                    {new Date(target?.createdAt)?.toLocaleString("fr-FR")}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Notifications;
