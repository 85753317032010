// component defintion

import * as React from "react";
import {
  useTheme,
  Stack,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  Button,
  useMediaQuery,
  Typography,
  Avatar,
  Divider,
  Tooltip,
  ButtonBase,
  Chip,
} from "@mui/material";
import { notificationCtx } from "../../../context/notification";
import { currDocumentCtx } from "../../../context/currDocument";
import MessagesApi from "../../../helpers/e-courrier/requests/messages";
import { usersCtx } from "../../../context/users";

import { useNavigate } from "react-router-dom";

const Contributors = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const currDocContext = React?.useContext(currDocumentCtx);
  let contributors = currDocContext?.currDocContributors;

  console.log("received contributors here", {contributors});
  

  if (!Array.isArray(contributors)) {
    contributors=[]
  }

  return (
    <Box
      sx={{
        width: "100%",
        mt: "1rem",
      }}
    >
      <Box
        className="tag-element"
        sx={{
          width: "100%",
        }}
      >
        <p>Contributeurs ({contributors?.length})</p>
        <Stack
          direction={"row"}
          // className="tag-list"
          sx={{
            py: "0!important",
            width: "100%",
            flexWrap: "no-wrap",
            overflowX: "auto",
            maxWidth: "100%",
            // px: "1rem",
            pb: ".3rem",
          }}
        >
          {contributors?.map((target) => {
            return (
              <Chip
                size={"small"}
                avatar={
                  <Avatar
                    sx={
                      {
                        // bgcolor: theme?.palette?.primary?.main,
                      }
                    }
                  >
                    {target?.username[0]?.toUpperCase()}
                  </Avatar>
                }
                label={target?.username}
                sx={{
                  width: "max-content",
                  minWidth: "100px",
                  ml: ".2rem",
                }}
              />
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default Contributors;
