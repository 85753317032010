// component definition
import * as React from "react";

import Layout from "../Layout";
import {
  useTheme,
  Stack,
  Box,
  Typography,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import NavigationLine from "../NavigationLine";
import { contactsCtx } from "../../context/contacts";
import getHeaders from "../../utils/getHeaders";
import { useNavigate } from "react-router-dom";

import { BASE_URL } from "../../constants/api";
import lookup from "../../utils/lookup";

import ContactApi from "../../helpers/e-courrier/requests/contacts";
import { filesCtx } from "../../context/files";

const ContactView = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const fileContext = React.useContext(filesCtx);

  const editMode = !window?.location?.pathname?.includes("/contacts/new");

  const contactsContext = React?.useContext(contactsCtx);

  const currcontactId = new URLSearchParams(window?.location?.search)?.get(
    "id"
  );

  const contacts = contactsContext?.contacts;
  const setContacts = contactsContext?.setCcontacts;

  const [defaultcontact, setDefaultcontact] = React?.useState(
    contacts?.find(
      (target) => target?.id?.toString() === currcontactId?.toString()
    ) || {}
  );

  const refreshContacts = contactsContext?.refreshContacts;

  React?.useEffect(() => {
    setDefaultcontact(
      contacts?.find(
        (target) => target?.id?.toString() === currcontactId?.toString()
      ) || {}
    );

    console.log(
      "current contacts here",
      contacts,
      contacts?.find(
        (target) => target?.id?.toString() === currcontactId?.toString()
      ),
      currcontactId,
      editMode
    );
  }, [contacts]);

  const identityFields = [
    {
      field: "name",
      title: "Nom",
      required: true,
    },
    {
      field: "email",
      title: "Addresse mail",
      required: true,
    },
    {
      field: "phoneNumber",
      title: "Téléphone",
      required: true,
    },
    {
      field: "address",
      title: "Adresse",
      required: true,
    },
    {
      field: "poBox",
      title: "Boîte postale",
      required: true,
    },
    {
      field: "type",
      title: "Type",
      required: true,
    },
    {
      field: "briefing",
      title: "Brief",
      required: true,
    },
  ];

  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (
      window.confirm(
        `Voulez-vous procéder à la ${editMode ? "mise à jour" : "création"} ?`
      )
    ) {
      const submitObj = {};

      const formData = new FormData(event.currentTarget);

      for (let [key, value] of formData.entries()) {
        submitObj[key] = value;

        // alert(value);
      }

      console.log("received data for contact action", submitObj, editMode);

      if (editMode) {
        await ContactApi.update({ data: submitObj, id: currcontactId })
          .then((res) => {})
          .then(async (res) => {
            await refreshContacts()
              .then(() => {
                console.log("successfully updated contacts list");

                navigate("/apps/e-courrier/contacts");
              })
              .catch((error) => {
                console.log(
                  "an error has occured when updating contacts",
                  error
                );
              });
            navigate("/apps/e-courrier/contacts");
          })
          .catch((error) => {
            console.log("an error has occured on contact update", error);
          });
      } else {
        await ContactApi.create(submitObj)
          .then((res) => {})
          .then(async (res) => {
            await refreshContacts()
              .then(() => {
                console.log("successfully created contacts list");

                navigate("/apps/e-courrier/contacts");
              })
              .catch((error) => {
                console.log(
                  "an error has occured when creating contacts",
                  error
                );
              });
          })
          .catch((error) => {
            console.log("an error has occured on contact update", error);
          });
      }
    }
  };

  const handleDelete = async (event) => {
    event?.preventDefault();

    if (window.confirm("Voulez-vous supprimer contact ?")) {
      await ContactApi.delete({ id: currcontactId }).then(async (res) => {
        await refreshContacts()
          .then(() => {
            console.log("successfully created contacts list");

            navigate("/apps/e-courrier/contacts");
          })
          .catch((error) => {
            console.log("an error has occured when creating contacts", error);
          });
      });
    }
  };

  const resetCanvasData = fileContext?.resetCanvasData;

  React.useEffect(() => {
    return () => {
      resetCanvasData();
    };
  }, []);

  const Form = () => {
    return (
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: theme?.palette?.common?.white,
          width: "100%",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            alignItems: "flex-start",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: theme?.palette?.primary?.main,
                fontWeight: theme?.typography?.fontWeightBold,
                mb: ".5rem",
              }}
            >
              Informations importantes
            </Typography>
            <Grid
              container
              sx={{
                width: "100%",
              }}
            >
              {identityFields?.map((target, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    sm={6}
                    sx={{
                      p: ".5rem",
                    }}
                  >
                    {target?.field === "type" ? (
                      <FormControl
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size={"small"}
                        autoComplete="off"
                      >
                        <InputLabel>{target?.title}</InputLabel>
                        <Select
                          size={"small"}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label={target?.title}
                          placeholder={target?.title}
                          name={target?.field}
                          defaultValue={
                            (editMode ? defaultcontact[target?.field] : null) ||
                            null
                          }
                          required={target?.required}
                          // autoComplete="off"
                        >
                          <MenuItem value={"individual"}>
                            {"Personne physique"}
                          </MenuItem>
                          <MenuItem value={"corporate"}>
                            {"Personne morale"}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        name={target?.field}
                        placeholder={target?.title}
                        label={target?.title}
                        type={
                          target?.field === "password"
                            ? "password"
                            : target?.field === "email"
                            ? "email"
                            : "text"
                        }
                        size={"small"}
                        // autoComplete="new-password"
                        sx={{
                          width: "100%",
                        }}
                        defaultValue={
                          target?.field === "password"
                            ? null
                            : (editMode
                                ? defaultcontact[
                                    target?.field === "contactname"
                                      ? "fullName"
                                      : target.field
                                  ]
                                : null) || null
                        }
                        required={target?.required}
                        multiline={target?.field === "briefing"}
                        rows={target?.field === "briefing" ? 4 : undefined}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <button
            type="submit"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: ".4rem",
              paddingBottom: ".4rem",
              marginTop: "1rem",
              marginLeft: ".5rem",
              maxWidth: "max-content",
            }}
            className="btn-primary flex-auto"
          >
            {editMode ? "Mettre à jour" : "Créer"}
          </button>
          {editMode ? (
            <button
              onClick={handleDelete}
              type="submit"
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: ".4rem",
                paddingBottom: ".4rem",
                marginTop: "1rem",
                marginLeft: ".5rem",
                maxWidth: "max-content",
                backgroundColor: theme?.palette?.error?.main,
              }}
              className="btn-primary flex-auto"
            >
              Suprimer
            </button>
          ) : null}
        </Stack>
      </form>
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <NavigationLine
          firstTitle={"Contacts"}
          secondTitle={editMode ? defaultcontact?.name : "Ajout"}
          backLink={"/apps/e-courrier/contacts"}
        />

        {editMode ? (
          Object.keys(defaultcontact)?.length > 0 && <Form />
        ) : (
          <Form />
        )}
      </Box>
    </Layout>
  );
};

export default ContactView;
