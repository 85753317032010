// context defintion

import * as React from "react";

const saeConfCtx = React?.createContext({});

const SaeConfContext = ({ children }) => {
  return <saeConfCtx.Provider value={{}}>{children}</saeConfCtx.Provider>;
};

export default SaeConfContext;
