// component definititon

import * as React from "react";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
  Avatar,
  Chip,
  useMediaQuery,
  SpeedDial,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Add,
  Archive,
  AttachFile,
  Check,
  ChevronRight,
  Download,
  DownloadDoneTwoTone,
  DownloadForOffline,
  Edit,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RejectionModal from "../RejectionModal";
import { documentsCtx } from "../../context/documents";
import { Search } from "react-feather";
import { appDataCtx } from "../../context/appData";
import NavigationLine from "../NavigationLine";
import { filesCtx } from "../../context/files";
import { BASE_URL } from "../../constants/api";
import getHeaders from "../../utils/getHeaders";
import Layout from "../Layout";
import lookup from "../../utils/lookup";
import { courriersCtx } from "../../context/courriers";
import CourriersApi from "../../helpers/e-courrier/requests/courriers";
import parseRef from "../../utils/parseRef";
import mergePdf from "../../utils/pdfMerger";

const ITEM_HEIGHT = 48;

const MailsTab = ({ firstTitle = "E-Courrier", title }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const courrierContext = React.useContext(courriersCtx);

  const pendingCourriers = courrierContext.pendingCourriers;
  const processedCourriers = courrierContext.processedCourriers;
  const archivedCourriers = courrierContext.archivedCourriers;

  const refreshCourriers = courrierContext.refreshCourriers;

  const emergency = new URLSearchParams(window?.location?.search)?.get(
    "emergency"
  );
  const importance = new URLSearchParams(window?.location?.search)?.get(
    "importance"
  );

  const _title = new URLSearchParams(window?.location?.search).get("title");

  if (_title) {
    title = _title;
  }

  const [rejectionModal, setRejectionModal] = React?.useState({
    data: {},
    visible: false,
    rejected: true,
  });

  const [searchedRows, setSearchedRows] = React?.useState([]);
  const [courriers, setCourriers] = React.useState([]);

  const [courriersType, setCourriersType] = React.useState("incoming");

  React?.useEffect(() => {
    switch (title?.toLowerCase()) {
      case "en cours":
        const _courriers = parseCourriers(
          pendingCourriers?.filter((target) => {
            if (emergency) {
              return target?.emergency === emergency;
            } else if (importance) {
              return target?.importance === importance;
            } else {
              return true;
            }
          })
        );

        setSearchedRows(_courriers);
        setCourriers(_courriers);

        break;

      case "archivés":
        setSearchedRows(parseCourriers(archivedCourriers));

        setCourriers(parseCourriers(archivedCourriers));
        break;

      case "traités":
        setSearchedRows(parseCourriers(processedCourriers));

        setCourriers(parseCourriers(processedCourriers));
        break;

      default:
        break;
    }
  }, [pendingCourriers, processedCourriers, archivedCourriers, title]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const signedDocuments =
    React?.useContext(documentsCtx)?.documents?.signed || [];

  const downloadLink = React?.useRef(null);
  const fileContext = React?.useContext(filesCtx);

  const setDocumentAnnexes = fileContext?.setDocumentAnnexes;

  const [rowActions, setRowActions] = React?.useState([
    {
      title: "Télécharger",
      icon: (options) => <DownloadForOffline {...options} />,
    },
  ]);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("role")?.toLowerCase() === "admin" &&
      rowActions?.length === 1
    ) {
      setRowActions([
        ...rowActions,
        { title: "Terminer", icon: (options) => <Check {...options} /> },
        {
          title: "Archiver",
          icon: (options) => <Archive {...options} />,
        },
        { title: "Editer", icon: (options) => <Edit {...options} /> },
      ]);
    }
  }, []);

  const columns = [
    {
      field: "reference",
      headerName: "Réf",
    },
    {
      field: "senderFrom",
      headerName: "Expéditeur",
      renderCell: ({ row }) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme?.typography?.fontWeightBold,
              color: theme?.palette?.primary?.main,
              maxWidth: "95%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row?.senderFrom}
          </Typography>
        );
      },
    },
    {
      field: "title",
      headerName: "Nom du courrier",
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: ({ row }) => (
        <Chip
          label={row?.type}
          color="primary"
          sx={{
            color:
              row?.type == "incoming"
                ? theme?.palette?.info?.main
                : theme?.palette?.success?.main,
            bgcolor:
              row?.type == "incoming"
                ? `${theme?.palette?.info?.main}10`
                : `${theme?.palette?.success.main}10`,
            height: "25px!important",
            width: "80px!important",
            fontSize: "12px",
            fontWeight: theme?.typography?.fontWeightMedium,
          }}
        />
      ),
    },
    {
      field: "contributors",
      headerName: "Contributeurs",
      renderCell: ({ row }) => (
        <AvatarGroup max={screen900 ? 1 : 2}>
          {row?.contributors?.map((target) => {
            return (
              <Avatar
                src={target?.profile}
                sx={{
                  bgcolor: "#227378",
                  width: "40px",
                  height: "40px",
                  fontSize: "14px",
                }}
              >
                {(target?.username || target?.name)
                  ?.split(" ")
                  ?.slice(0, 2)
                  ?.map((target) => target[0])
                  ?.join("")}
              </Avatar>
            );
          })}
        </AvatarGroup>
      ),
    },

    {
      field: "date",
      headerName: "Date",
    },
    {
      field: "receptionisto",
      headerName: "Reception",
    },
    {
      field: "handlero",
      headerName: "Traitement",
    },
    {
      field: "open",
      headerName: "Ouvrir",
      renderCell: ({ row }) => (
        <IconButton
          onClick={(event) => {
            event?.preventDefault();

            if (!(row?.archived || row?.closed)) {
              navigate(`/apps/e-courrier/mails/${row?.id}`);
            }
          }}
          sx={{
            cursor: row?.archived || row?.closed ? "not-allowed" : "pointer",
          }}
        >
          <Visibility
            sx={{
              color: "#227378",
            }}
          />
        </IconButton>
      ),
    },
    {
      field: "status",
      headerName: "Statut",
      renderCell: ({ row }) => (
        <Chip
          label={row?.status}
          color="primary"
          sx={{
            color: row?.closed
              ? theme?.palette?.success?.main
              : row?.archived
              ? theme?.palette?.error?.main
              : theme?.palette?.warning?.main,
            bgcolor: row?.closed
              ? `${theme?.palette?.success?.main}10`
              : row?.archived
              ? `${theme?.palette?.error?.main}10`
              : `${theme?.palette?.warning?.main}10`,
            height: "25px!important",
            width: "80px!important",
            fontSize: "12px",
            fontWeight: theme?.typography?.fontWeightMedium,
          }}
        />
      ),
    },
    {
      field: "more",
      headerName: "Options",
      renderCell: ({ row }) => {
        const rowObj = { ...row };

        return <MoreActions rowObj={row} rowActions={rowActions} />;
      },
    },
  ]?.map((col) => {
    if (col.field === "date") {
      if (courriersType === "incoming") {
        return {
          field: "entryDate",
          headerName: "Date de reception",
          renderCell: ({ row }) => {
            return (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme?.typography?.fontWeightRegular,
                  maxWidth: "95%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {new Date(row?.entryDate)?.toLocaleString()}
              </Typography>
            );
          },
          flex: 1,
          headerClassName: "grid--header",
        };
      } else if (courriersType === "outgoing") {
        return {
          field: "leavingDate",
          headerName: "Date d'envoi",
          renderCell: ({ row }) => {
            return (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme?.typography?.fontWeightRegular,
                  maxWidth: "95%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {new Date(row?.leavingDate)?.toLocaleString()}
              </Typography>
            );
          },
          flex: 1,
          headerClassName: "grid--header",
        };
      }
    }

    return {
      ...col,
      flex: ["open", "more", "closed", ""]?.includes(col.field) ? undefined : 1,
      headerClassName: "grid--header",
    };
  });

  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    const searchedCourriers = [...courriers]?.filter((target) => {
      return Object.keys(target)?.some((key) => {
        if (["author"]?.includes(key)) {
          return target[key]?.fullName
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else if (["file"]?.includes(key)) {
          return target[key]?.path
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else if (["terminated"]?.includes(key)) {
          console.log("current search config ", {
            key,
            value: target[key],
          });

          return (
            target?.rejectedBy?.username?.toString()?.length > 0
              ? "rejeté"
              : target?.terminated === true
              ? "complété"
              : `reste ${target?.terminated}`
          )
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else {
          return target[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        }
      });
    });

    setSearchedRows(searchedCourriers);
  };

  const handleZipDownload = async (event) => {
    event?.preventDefault();

    const files = [
      rejectionModal?.data?.filePath,
      ...(rejectionModal?.data?.attachedFiles || [])?.map(
        (target) => target?.path
      ),
    ]?.map((file) => {
      return "/uploads/" + file?.split("/")?.slice(-1)[0];
    });

    const _fileName = rejectionModal?.data?.title?.split(".")?.slice(0, 1)[0];

    await lookup(`${BASE_URL}/api/compress`, {
      headers: getHeaders({}),
      body: JSON.stringify({
        data: {
          preferredName: _fileName,
          filesToCompress: files,
        },
      }),
      method: "POST",
    })
      .then((res) =>
        res.blob().then((zipBuffer) => {
          const compressedFile = new File([zipBuffer], _fileName + ".zip", {
            type: "application/zip",
          });
          const dowloadUrl = URL.createObjectURL(compressedFile);

          // window.open(dowloadUrl);

          downloadRef?.current?.setAttribute("download", `${_fileName}.zip`);
          downloadRef?.current?.setAttribute("href", dowloadUrl);
          downloadRef?.current?.click();
        })
      )
      .catch((error) => {
        console.log("an error has occured when creating ", error);
      });
  };

  const downloadRef = React?.useRef({});

  return (
    <Layout>
      <div>
        <RejectionModal
          open={rejectionModal?.visible}
          setOpen={() => {
            setRejectionModal({
              ...rejectionModal,
              visible: false,
            });
          }}
          childrenClassName={`w-[${screen900 ? 95 : 70}%]`}
        >
          <Stack className="card-box w-full p-6" sx={{}}>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme?.typography?.fontWeightBold,
                color: theme?.palette?.primary?.main,
              }}
            >
              {rejectionModal?.rejected ? "DOCUMENT REJETE" : "DOCUMENT"}
            </Typography>
            <a
              ref={downloadRef}
              href={"/"}
              style={{
                display: "none",
              }}
            ></a>

            <Stack
              direction={"column"}
              sx={{
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {rejectionModal?.rejected ? (
                    <Stack
                      direction={"column"}
                      sx={{
                        width: screen900 ? "90%" : "45%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme?.palette?.grey?.[500],
                          fontWeight: theme?.typography?.fontWeightBold,
                          mt: "1rem",
                        }}
                      >
                        Par
                      </Typography>
                      <Typography>{rejectionModal?.data?.username}</Typography>
                      <Typography
                        sx={{
                          color: theme?.palette?.grey?.[500],
                          fontWeight: theme?.typography?.fontWeightBold,
                          mt: "1rem",
                        }}
                      >
                        Date de rejet
                      </Typography>
                      <Typography>
                        {new Date(
                          rejectionModal?.data?.rejectionDate
                        )?.toLocaleString("fr-FR")}
                      </Typography>
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Stack
                    direction={"column"}
                    sx={{
                      width: screen900 ? "90%" : "45%",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[500],
                        fontWeight: theme?.typography?.fontWeightBold,
                        mt: "1rem",
                      }}
                    >
                      Pièces jointes
                    </Typography>
                    {rejectionModal?.data?.attachedFiles?.length > 0 ? (
                      ""
                    ) : (
                      <Typography>{"Aucune pièce jointe"}</Typography>
                    )}
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {[
                        {
                          path: rejectionModal?.data?.filePath,
                          name: rejectionModal?.data?.title,
                        },
                        ...(rejectionModal?.data?.attachedFiles || []),
                      ]?.map((target, index) => {
                        return (
                          <Chip
                            icon={
                              <AttachFile
                                sx={{
                                  color: theme?.palette?.primary?.main,
                                  fontSize: "15px",
                                  transform: "rotate(45deg)",
                                }}
                              />
                            }
                            key={index}
                            href={target?.path}
                            component={"a"}
                            size={"small"}
                            label={target?.name}
                            target={"_blank"}
                            sx={{
                              maxWidth: "100%!important",
                              mr: ".2rem",
                              mt: ".2rem",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
                {rejectionModal?.rejected ? (
                  <div>
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[500],
                        fontWeight: theme?.typography?.fontWeightBold,
                        mt: "1rem",
                      }}
                    >
                      Motif
                    </Typography>
                    <Typography>{rejectionModal?.data?.reason}</Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Stack>
            <Stack
              onClick={handleZipDownload}
              direction={"row"}
              sx={{
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn-primary flex-auto"
                style={{
                  width: "max-content",
                  marginTop: "1rem",
                }}
              >
                Compresser {<Download />}
              </button>
            </Stack>
          </Stack>
        </RejectionModal>
        <Stack
          sx={{
            pt: "1rem",
            // px: !screen900 ? "2rem" : ".5rem",
            overflowX: "hidden",
            maxWidth: !screen900 ? "100%" : undefined,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <NavigationLine firstTitle={"Courriers"} secondTitle={title} />
            <Box
              className="text-field"
              sx={{
                py: ".3rem",
              }}
            >
              <input
                type="text"
                value={searchValue}
                onChange={handleSearch}
                placeholder={"Recherche"}
              />
              <span className="cursor-pointer">
                <Search className="stroke-black" />
              </span>
            </Box>
          </Stack>
          <Box
            sx={{
              "& .grid--header": {
                "& *": { fontWeight: theme?.typography?.fontWeightBold },
              },
              width: "100%",
              overflowX: "auto",
              // maxHeight: "75vh",
              // overflowY: "auto",
              borderRadius: "15px",
              pt: "1rem",
              pb: "3rem",
            }}
          >
            <DataGrid
              rows={searchedRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "15px",
                overflow: "hidden",
              }}
              stickyHeader
            />
          </Box>
        </Stack>
      </div>
    </Layout>
  );
};

const parseCourriers = (courriersList = []) => {
  let parsedCourriers = [];

  parsedCourriers = courriersList?.map((target) => {
    // console.log("current receptionist here", target?.receptionist);

    return {
      ...target,
      reference: target?.reference || "Aucune",
      senderFrom:
        target?.type === "outgoing"
          ? "Propriétaire"
          : target?.senderFrom?.data?.attributes?.name,
      type: target?.type === "incoming" ? "entrant" : "sortant",
      receptionist: target?.receptionist?.username,
      handler: target?.handler?.username,
      status: target?.closed
        ? "Traité"
        : target?.archived
        ? "Archivé"
        : "En cours",
    };
  });

  return parsedCourriers;
};

const MoreActions = ({ rowObj, rowActions }) => {
  const courrierContext = React.useContext(courriersCtx);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const refreshCourriers = courrierContext.refreshCourriers;

  async function handleMore({ event, rowObj, option }) {
    event?.preventDefault();

    if (option.title === "Terminer") {
      console.log("current links for mergin processed in download", rowObj);

      await CourriersApi.update({
        id: rowObj?.id,
        data: { closed: true, archived: false },
      }).catch((error) => {
        console.log("an error has occured when closing courriers", error);
      });
    } else if (option.title === "Archiver") {
      await CourriersApi.update({
        id: rowObj?.id,
        data: { archived: true, closed: false },
      }).catch((error) => {
        console.log("an error has occured when archiving courriers", error);
      });
    } else if (option?.title === "Editer") {
      await CourriersApi.update({
        id: rowObj?.id,
        data: { archived: false, closed: false },
      }).catch((error) => {
        console.log("an error has occured when activating courriers", error);
      });
    } else {
      let mergeLink = "";

      let files = [
        {
          url: rowObj?.underlying_file?.data?.attributes?.url,
          title: rowObj?.title,
        },
        {
          url: rowObj?.receiptMail?.underlying_file?.data?.attributes?.url,
          title: rowObj?.receiptMail?.title,
        },
        {
          url: rowObj?.responseMail?.underlying_file?.data?.attributes?.url,
          title: rowObj?.responseMail?.title,
        },
      ];

      console.log(
        "current links for mergin processed in download",
        files,
        rowObj
      );

      files = files?.filter((target) => target?.url?.length > 3);

      files = await Promise.all(
        files?.map((file) => {
          if (file.url) {
            return lookup(`${BASE_URL}${file?.url}`, {
              method: "GET",
              headers: getHeaders({}),
            }).then((res) =>
              res?.blob().then((data) => {
                const finalFile = new File([data], file.title + ".pdf", {
                  type: "application/pdf",
                });

                return finalFile;
              })
            );
          } else {
            return null;
          }
        })
      );

      files = files?.filter((target) => target);

      await mergePdf({
        files: files,
        parentDocument: rowObj?.title,
      }).then((result) => {
        mergeLink = result.url;
      });

      console.log("received merged courriers link", mergeLink);

      if (mergeLink) {
        window.open(mergeLink);
      }
    }

    refreshCourriers();

    handleClose();
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {rowActions.map((option) => (
          <MenuItem
            key={option?.title}
            onClick={(event) => handleMore({ event, rowObj, option })}
          >
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
              }}
            >
              {option.icon({
                sx: {
                  color: theme?.palette?.primary?.main,
                  fontSize: "24px",
                  mr: ".5rem",
                },
              })}
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {option?.title}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MailsTab;
