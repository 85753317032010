// setting api central 

import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize";

class SettingApi {

    static async getAll() {

        let _setting = null;

        await lookup(`${BASE_URL}/api/setting?populate=*`, {
            method: "GET",
            headers : getHeaders({})
        }).then(results => results.json().then(results => {
            _setting = strapiSanitize({object: results});

        })).catch(error => {
            console.log("an error has occured when fetching setting", error);
            
        })


        return _setting
    }

    static async update({data}) {
        let _status = null;

        await lookup(`${BASE_URL}/api/setting`, {
            method: "PUT",
            headers: getHeaders({}),
            body: JSON.stringify({data})
        }).then(result => {
            _status = result
        }).catch(error => {
            console.log("an error has occured when creatin setting", error);
            
        })

        return _status
    }
}

export default SettingApi
