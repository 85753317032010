// component definition

import * as React from "react";

import Layout from "../Layout";
import {
  useTheme,
  Stack,
  Box,
  Typography,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import NavigationLine from "../NavigationLine";
import { contactsCtx } from "../../context/contacts";
import getHeaders from "../../utils/getHeaders";
import { useNavigate } from "react-router-dom";

import { BASE_URL } from "../../constants/api";
import lookup from "../../utils/lookup";

import { notificationCtx } from "../../context/notification";

import ContactApi from "../../helpers/e-courrier/requests/contacts";
import { entitiesCtx } from "../../context/entities";
import EntityApi from "../../helpers/archiving/requests/entity";

const EntityView = ({}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const editMode = !window?.location?.pathname?.includes("/entities/new");

  const currentEntityId = new URLSearchParams(window?.location?.search)?.get(
    "entityId"
  );
  const slug = new URLSearchParams(window?.location.search)?.get("slug");

  const showError = React?.useContext(notificationCtx)?.showError;
  const showInfo = React?.useContext(notificationCtx)?.showInfo;
  const showSuccess = React?.useContext(notificationCtx)?.showSuccess;
  const showWarning = React?.useContext(notificationCtx)?.showWarning;

  const entitiesContext = React.useContext(entitiesCtx);

  React.useEffect(() => {
    if (currentEntityId) {
      const _defaultEntity =
        entitiesContext?.entities?.find(
          (target) => target?.id?.toString() === currentEntityId?.toString()
        ) || {};
      // console.log("current default entity", _defaultEntity);

      setDefaultEntity(_defaultEntity);
    }
  }, [entitiesContext?.entities]);

  const [defaultEntity, setDefaultEntity] = React?.useState({});

  const entityFields = [
    {
      field: "name",
      headerName: "Nom",
      required: true,
    },
    {
      field: "description",
      headerName: "Description",
      required: true,
    },
  ];

  console.log("current entities fields here ", entityFields);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    if (window?.confirm("Voulez-vous créer cette entité ?")) {
      const submitObj = {};
      const formData = new FormData(event.currentTarget);

      for (let [key, value] of formData.entries()) {
        submitObj[key] = value;
      }

      console.log("current data for action on entity", submitObj);

      if (submitObj["name"]?.length > 1) {
        if (submitObj["description"]?.length > 5) {
          if (editMode) {
            await EntityApi.update({
              id: defaultEntity?.id,
              data: { data: submitObj },
            })
              .then(async (status) => {
                event?.target?.reset();

                showSuccess("Entité modifiée avec succès");

                await entitiesContext?.refreshEntities();
                navigate("/settings/entities");
              })
              .catch((error) => {
                console.log(
                  "an error has occured when updating an entity",
                  error
                );

                showError("Oups! Une erreur est survenue. Réessayez");
              });
          } else {
            await EntityApi.create({ data: { data: submitObj } })
              .then(async (status) => {
                event?.target?.reset();

                showSuccess("Entité créée avec succès");

                await entitiesContext?.refreshEntities();

                navigate("/settings/entities");
              })
              .catch((error) => {
                console.log(
                  "an error has occured when creating an entity",
                  error
                );

                showError("Oups! Une erreur est survenue. Réessayez");
              });
          }
        } else {
          showWarning("Veuillez entrer une description de l'entité");
        }
      } else {
        showWarning("Veuillez entrer un nom valide");
      }
    }
  };

  const handleDelete = async (event) => {
    event?.preventDefault();

    if (window.confirm("Voulez-vous supprimer cette entité ?")) {
      await EntityApi.remove({ id: defaultEntity?.id })
        ?.then(async (status) => {
          if (status) {
            showSuccess("Entité supprimée avec succès");

            await entitiesContext?.refreshEntities();
            navigate("/settings/entities");
          }
        })
        ?.catch((error) => {
          console.log("an error just occured when removing an entity", error);
        });
    }
  };

  const Form = () => {
    return (
      <form
        onSubmit={handleSubmit}
        style={{
          backgroundColor: theme?.palette?.common?.white,
          width: "100%",
          padding: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Stack
          sx={{
            width: "100%",
            alignItems: "flex-start",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <Stack
            direction={"column"}
            sx={{
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: theme?.palette?.primary?.main,
                fontWeight: theme?.typography?.fontWeightBold,
                mb: ".5rem",
              }}
            >
              Informations
            </Typography>
            <Grid
              container
              sx={{
                width: "100%",
              }}
            >
              {entityFields
                ?.filter((target) =>
                  !editMode ? target?.field?.toLowerCase() !== "id" : true
                )
                ?.map((target) => {
                  return ["parent", "children"]?.includes(
                    target.field?.toLowerCase()
                  ) ? (
                    <Grid
                      item
                      md={6}
                      sm={12}
                      sx={{
                        p: ".5rem",
                        width: "100%",
                      }}
                    >
                      <FormControl
                        variant="outlined"
                        sx={{ width: "100%" }}
                        size={"small"}
                        autoComplete="off"
                      >
                        <InputLabel>{target?.headerName}</InputLabel>
                        <Select
                          size={"small"}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label={target?.headerName}
                          placeholder={target?.headerName}
                          name={target?.field}
                          required={target?.required}
                          multiple={!["parent"]?.includes(target?.field)}
                          value={
                            target?.field === "parent"
                              ? currentParent
                              : currentChildren
                          }
                          onChange={
                            target?.field === "parent"
                              ? handleParent
                              : target?.field === "children"
                              ? handleChildren
                              : null
                          }
                        >
                          {target?.options?.map((opt) => {
                            return (
                              <MenuItem value={opt?.id?.toString()}>
                                {opt?.name || opt?.username}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      md={6}
                      sm={12}
                      sx={{
                        p: ".5rem",
                        width: "100%",
                      }}
                    >
                      <TextField
                        name={target?.field}
                        multiline={
                          target?.field === "description" ? true : false
                        }
                        rows={target?.field === "description" ? 3 : undefined}
                        placeholder={target?.headerName}
                        label={target?.headerName}
                        size={"small"}
                        defaultValue={defaultEntity[target?.field] || null}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            mb: "1rem",
          }}
        >
          <button
            type="submit"
            style={{
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingTop: ".4rem",
              paddingBottom: ".4rem",
              marginTop: "1rem",
              marginLeft: ".5rem",
              maxWidth: "max-content",
            }}
            className="btn-primary flex-auto"
          >
            {editMode ? "Mettre à jour" : "Créer"}
          </button>
          {editMode ? (
            <button
              onClick={handleDelete}
              type="submit"
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: ".4rem",
                paddingBottom: ".4rem",
                marginTop: "1rem",
                marginLeft: ".5rem",
                maxWidth: "max-content",
                backgroundColor: theme?.palette?.error?.main,
              }}
              className="btn-primary flex-auto"
            >
              Suprimer
            </button>
          ) : null}
        </Stack>
      </form>
    );
  };

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <NavigationLine
          firstTitle={"Entités"}
          secondTitle={defaultEntity?.name ? defaultEntity?.name : "Nouvelle"}
          backLink={"/settings/entities"}
        />

        {editMode ? (
          Object.keys(defaultEntity)?.length > 0 && <Form />
        ) : (
          <Form />
        )}
      </Box>
    </Layout>
  );
};

export default EntityView;
