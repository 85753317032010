// scan data context definition

import * as React from "react";

const scanCtx = React?.createContext({});

const ScanContext = ({ children }) => {
  const [scanOpen, setScanOpen] = React?.useState(false);
  const [pendingDocs, setPendingDocs] = React?.useState([]);
  const [scannedDocs, setScannedDocs] = React?.useState([]);

  return (
    <scanCtx.Provider
      value={{
        scanOpen,
        setScanOpen,
        scannedDocs,
        setScannedDocs,
        pendingDocs,
        setPendingDocs,
      }}
    >
      {children}
    </scanCtx.Provider>
  );
};

export { scanCtx };
export default ScanContext;
