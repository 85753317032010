// component definition

import * as React from "react";
import {
  useTheme,
  Typography,
  Stack,
  Box,
  Grid,
  useMediaQuery,
  Tabs,
  Tab,
  Badge,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Layout from "../Layout";
import {
  Archive,
  CheckRounded,
  CrisisAlert,
  DateRange,
  Forum,
  NotificationImportant,
  Timelapse,
} from "@mui/icons-material";
import { documentsCtx } from "../../context/documents";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";
import { courriersCtx } from "../../context/courriers";
import { notificationCtx } from "../../context/notification";

import Chart from "react-apexcharts";
import { DataGrid } from "@mui/x-data-grid";
import { messagesCtx } from "../../context/messages";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { sessionCtx } from "../../context/session";

const columns = [
  {
    field: "title",
    headerName: "Courrier",
  },
  {
    field: "weight",
    headerName: "Poids(Kg)",
  },
  {
    field: "destination",
    headerName: "Destination",
  },
  {
    field: "cost",
    headerName: "Coût($)",
  },
]?.map((col) => {
  return {
    ...col,
    flex: 1,

    headerClassName: "grid--header",
  };
});

const Dashboard = ({}) => {
  const theme = useTheme();

  const courriersContext = React?.useContext(courriersCtx);
  const notifContext = React.useContext(notificationCtx);
  const msgContext = React.useContext(messagesCtx);

  const sessionContext = React.useContext(sessionCtx);

  const startDate = sessionContext?.startDate;
  const setStartDate = sessionContext?.setStartDate;
  const endDate = sessionContext?.endDate;
  const setEndDate = sessionContext?.setEndDate;

  const [metrics, setMetrics] = React.useState([
    {
      title: "Courriers traités",
      count: courriersContext?.processedCourriers?.length,
      icon: (props) => <Timelapse {...props} />,
      link: "/apps/e-courrier/mails/processed",
      color: "success",
    },
    {
      title: "Courriers en attentes",
      count: courriersContext?.pendingCourriers?.length,
      icon: (props) => <DateRange {...props} />,
      link: "/apps/e-courrier/mails/pending",
      color: "warning",
    },
    {
      title: "Courriers importants",
      count: courriersContext?.pendingCourriers?.filter(
        (courrier) => courrier?.importance === "high"
      )?.length,
      icon: (props) => <NotificationImportant {...props} />,
      link: "/apps/e-courrier/mails/pending?importance=high&title=Importants",
      color: "#000000",
    },
    {
      title: "Courriers urgents",
      count: courriersContext?.pendingCourriers?.filter(
        (courrier) => courrier?.emergency === "high"
      )?.length,
      icon: (props) => <CrisisAlert {...props} />,
      link: "/apps/e-courrier/mails/pending?emergency=high&title=Urgents",
      color: "error",
    },
    {
      title: "Courriers archivés",
      count: courriersContext?.archivedCourriers?.length,
      icon: (props) => <Archive {...props} />,
      link: "/apps/e-courrier/mails/archived",
      color: "#000000",
    },
    {
      title: "Conversations",
      count: msgContext?.totalMessages,
      icon: (props) => <Forum {...props} />,
      link: "/apps/e-courrier/mails/conversations/all",
      color: "info",
    },
  ]);

  React.useEffect(() => {
    console.log(
      "current data for the courriers",
      courriersContext?.pendingCourriers
    );

    const startLimit = new Date(startDate?.$d)?.setHours(0, 0, 0);
    const endLimit = new Date(endDate?.$d)?.setHours(23, 59, 59);

    console.log("current dates processed values", {
      startLimit,
      endLimit,
    });

    let pendingCourriers = courriersContext?.pendingCourriers;
    let archivedCourriers = courriersContext?.archivedCourriers;
    let processedCourriers = courriersContext?.processedCourriers;

    if (startLimit && endLimit) {
      pendingCourriers = pendingCourriers?.filter((target) => {
        return (
          endLimit >= new Date(target?.createdAt).getTime() &&
          new Date(target?.createdAt).getTime() >= startLimit
        );
      });

      archivedCourriers = pendingCourriers?.filter((target) => {
        return (
          endLimit >= new Date(target?.createdAt).getTime() &&
          new Date(target?.createdAt).getTime() >= startLimit
        );
      });

      processedCourriers = pendingCourriers?.filter((target) => {
        return (
          endLimit >= new Date(target?.createdAt).getTime() &&
          new Date(target?.createdAt).getTime() >= startLimit
        );
      });
    }

    setMetrics([
      {
        title: "Courriers traités",
        count: processedCourriers?.length,
        icon: (props) => <Timelapse {...props} />,
        link: "/apps/e-courrier/mails/processed",
        color: "success",
      },
      {
        title: "Courriers en attentes",
        count: pendingCourriers?.length,
        icon: (props) => <DateRange {...props} />,
        link: "/apps/e-courrier/mails/pending",
        color: "warning",
      },
      {
        title: "Courriers importants",
        count: pendingCourriers?.filter(
          (courrier) => courrier?.importance === "high"
        )?.length,
        icon: (props) => <NotificationImportant {...props} />,
        link: "/apps/e-courrier/mails/pending?importance=high&title=Importants",
        color: "#000000",
      },
      {
        title: "Courriers urgents",
        count: pendingCourriers?.filter(
          (courrier) => courrier?.emergency === "high"
        )?.length,
        icon: (props) => <CrisisAlert {...props} />,
        link: "/apps/e-courrier/mails/pending?emergency=high&title=Urgents",
        color: "error",
      },
      {
        title: "Courriers archivés",
        count: archivedCourriers?.length,
        icon: (props) => <Archive {...props} />,
        link: "/apps/e-courrier/mails/archived",
        color: "#000000",
      },
      {
        title: "Conversations",
        count: msgContext?.totalMessages,
        icon: (props) => <Forum {...props} />,
        link: "/apps/e-courrier/mails/conversations/all",
        color: "info",
      },
    ]);
  }, [
    courriersContext?.processedCourriers,
    courriersContext?.archivedCourriers,
    courriersContext?.pendingCourriers,
    msgContext?.totalMessages,
    startDate,
    endDate,
  ]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const folders = React?.useContext(documentsCtx)?.folders;

  const [filteredFolders, setFilteredFolders] = React?.useState([]);

  React?.useEffect(() => {
    setFilteredFolders(folders);
  }, [folders]);

  const [searchValue, setSearchValue] = React?.useState("");

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    const _searchedObjects = folders?.filter((target) => {
      return Object.keys(target)?.some((key) => {
        if (key !== "documents") {
          return target[key]
            ?.toString()
            .toLowerCase()
            ?.includes(_search?.toLowerCase());
        } else {
          return false;
        }
      });
    });

    setFilteredFolders(_searchedObjects);
  };

  // console.log("current folders", { folders, filteredFolders });

  const navigate = useNavigate();

  const [courrierStreams, setCourrierStreams] = React.useState({
    Entrants: [],
    Sortants: [],
    Frêtés: [],
  });

  React.useEffect(() => {
    (() => {
      const startLimit = new Date(startDate?.$d)?.setHours(0, 0, 0);
      const endLimit = new Date(endDate?.$d)?.setHours(23, 59, 59);

      console.log("current dates processed values", {
        startLimit,
        endLimit,
      });

      let courriers = [
        ...courriersContext?.processedCourriers,
        ...courriersContext?.archivedCourriers,
        ...courriersContext?.pendingCourriers,
      ];

      if (endLimit && startLimit) {
        courriers = courriers?.filter((target) => {
          return (
            endLimit >= new Date(target?.createdAt).getTime() &&
            new Date(target?.createdAt).getTime() >= startLimit
          );
        });
      }

      const stats = {
        outgoing: [],
        incoming: [],
        freighted: [],
      };

      Object.keys(stats)?.forEach((key) => {
        const _courriers = courriers?.filter((target) => {
          if (key === "outgoing") {
            return target?.type === "outgoing";
          } else if (key === "incoming") {
            return target?.type === "incoming";
          } else if (key === "freighted") {
            return target?.freighted === true;
          }

          return false;
        });

        _courriers?.forEach((target) => {
          const metricObj = {};
          const _title = target?.senderFrom?.data?.attributes?.name;

          if (_title) {
            const metricIndex = stats[key].findIndex((_target) => {
              return _target?.title === _title;
            });

            if (metricIndex !== -1) {
              stats[key][metricIndex].value += 1;
            } else {
              metricObj["title"] = _title;
              metricObj["value"] = 1;

              stats[key].push(metricObj);
            }
          }
        });

        if (key === "freighted") {
          setFreightedMails(_courriers);

          const totals = _courriers?.reduce(
            (prev, next) => {
              prev["costs"] += next?.cost || 0;
              prev["weights"] += next?.weight || 0;

              return prev;
            },
            { costs: 0, weights: 0 }
          );

          setTotalCost(totals?.costs);
          setTotalWeight(totals?.weights);
        }
      });

      console.log("computed stats values here", stats);

      // limiting graph parts to 3

      const maxCount = 2;

      Object.keys(stats).forEach((key) => {
        if (stats[key]?.length > maxCount) {
          const ordered = stats[key]?.sort((prev, next) => {
            return next?.value - prev?.value;
          });

          const filter = ordered?.splice(0, maxCount);

          filter.push({
            value: ordered.reduce((prev, next) => {
              return prev + next?.value;
            }, 0),
            title: "Autres",
          });

          console.log("current ordered stats here ", { filter, ordered });

          stats[key] = filter;
        }
      });

      setCourrierStreams({
        Entrants: stats["incoming"],
        Sortants: stats["outgoing"],
        Frêtés: stats["freighted"],
      });
    })();
  }, [
    courriersContext?.processedCourriers,
    courriersContext?.archivedCourriers,
    courriersContext?.pendingCourriers,
    startDate,
    endDate,
  ]);

  const [topicValue, setTopicValue] = React.useState(0);

  const handleTopic = (event, newValue) => {
    setTopicValue(newValue);
  };
  const [mailsDirection, setMailsDirections] = React.useState("incoming");

  const handleMailsDirection = (event) => {
    event?.preventDefault();

    setMailsDirections(event?.target?.value);
  };

  const [currentYear, setCurrentYear] = React.useState("dayly");

  const handleCostsPeriod = (event) => {
    event?.preventDefault();
    setCurrentYear(event?.target?.value);
  };

  const [freightedRows, setFreightedMails] = React.useState([]);
  const [totalWeight, setTotalWeight] = React.useState("0.00");
  const [totalCost, setTotalCost] = React.useState("0.00");

  return (
    <Layout>
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          maxWidth: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          mt: "2rem",
          "& button": {
            textTransform: "none",
          },
          overflowX: "hidden",
          bgcolor: theme?.palette?.secondary?.main,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            ml: "1rem",
          }}
        >
          <Grid
            spacing={2}
            container
            sx={{
              maxWidth: "100%",
              width: "100%",
              mb: "1rem",
              // bgcolor: theme?.palette?.common?.white,
              borderRadius: "10px",
              //  p: "1rem",
            }}
          >
            {metrics?.map((target) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={2}
                  sx={{
                    mt: ".5rem",
                  }}
                >
                  <Stack
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(target?.link);
                    }}
                    sx={{
                      bgcolor: `${
                        theme?.palette[target.color]?.main || target?.color
                      }10`,
                      borderRadius: "10px",
                      p: "10px",
                      cursor: "pointer",
                      boxShadow: `0px 0px 3px ${theme?.palette?.grey[300]}`,
                    }}
                  >
                    {target?.icon({
                      sx: {
                        color:
                          theme?.palette[target.color]?.main || target?.color,
                        fontSize: "30px",
                      },
                    })}
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[700],
                        fontWeight: theme?.typography?.fontWeightBold,
                        fontSize: screen900 ? "14px" : "20px",
                        ml: ".1rem",
                      }}
                    >
                      {target?.count}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[700],
                        fontSize: screen900 ? "12px" : "14px",
                        fontWeight: theme?.typography?.fontWeightMedium,
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        ml: ".1rem",
                      }}
                    >
                      {target?.title}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            px: "1rem",
            mt: "1rem",
          }}
        >
          {/* <FormControl variant="outlined" sx={{ width: "12rem" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Type des courriers
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={mailsDirection}
              onChange={handleMailsDirection}
              label="Type des courriers"
              size="small"
            >
              <MenuItem value={"incoming"}>{"Entrants"}</MenuItem>
              <MenuItem value={"outgoing"}>{"Sortants"}</MenuItem>
            </Select>
          </FormControl> */}
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              mb: "0rem",
              width: "max-content",
              justifyContent: "flex-start",
            }}
          >
            <Badge
              color={mailsDirection === "outgoing" ? "info" : "info"}
              badgeContent={" "}
              variant={"dot"}
              sx={{
                mr: ".7rem",
              }}
            />
            <Typography
              sx={{
                color: theme?.palette?.common?.black,
                fontSize: "13px",
                fontWeiht: theme?.typography?.fontWeightRegular,
              }}
            >
              {`Flux de courriers ${
                "par contacts"
                // mailsDirection === "outgoing" ? "sortants" : "entrants"
              }`}
            </Typography>
          </Stack>
        </Stack>
        <Grid
          container
          sx={{
            maxWidth: "100%",
            width: "100%",
            mb: "1rem",
          }}
        >
          {Object.keys(courrierStreams)
            ?.filter(
              (key) =>
                !courrierStreams[key]?.every((target) => target?.value === 0)
            )
            ?.map((key) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  sx={{
                    p: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: theme?.palette?.common?.white,
                      borderRadius: "10px",
                      width: "100%",
                      py: "1rem",
                    }}
                  >
                    <Chart
                      options={{
                        dataLabels: {
                          enabled: false,
                        },
                        labels: courrierStreams[key]?.map(
                          (target) => target?.title
                        ),
                        title: {
                          text: key,
                          align: "center",
                          style: {
                            color: theme?.palette?.common?.black,
                          },
                        },
                        legend: {
                          position: "bottom",
                        },
                      }}
                      series={courrierStreams[key]?.map(
                        (target) => target?.value
                      )}
                      type="donut"
                    />
                  </Box>
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          sx={{
            maxWidth: "100%",
            width: "100%",
            mb: "1rem",
          }}
        >
          <Box
            sx={{
              bgcolor: theme?.palette?.common?.white,
              borderRadius: "10px",
              width: "100%",
              py: "1rem",
              px: "1rem",
              mx: "1rem",
            }}
          >
            <Chart
              options={{
                dataLabels: {
                  enabled: false,
                },
                labels: courrierStreams["Entrants"]?.map(
                  (target) => target?.title
                ),
                title: {
                  text: "Statistiques courrriers",
                  align: "center",
                  style: {
                    color: theme?.palette?.common?.black,
                  },
                },
                legend: {
                  position: "top",
                },
                grid: {
                  show: false,
                },
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
              }}
              series={[
                {
                  data: courrierStreams["Entrants"]?.map(
                    (target) => target?.value
                  ),
                  name: "Courriers entrants",
                },
                {
                  data: courrierStreams["Sortants"]?.map(
                    (target) => target?.value
                  ),
                  name: "Courriers sortants",
                },
                {
                  data: courrierStreams["Frêtés"]?.map(
                    (target) => target?.value
                  ),
                  name: "Courriers frêtés",
                },
              ]}
              type="bar"
              height={300}
            />
          </Box>
        </Grid>
        <Box
          sx={{
            width: "100%",
            px: "1rem",
            mb: "2rem",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              // px: "1rem",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
                mb: "1rem",
                width: "max-content",
                justifyContent: "flex-start",
              }}
            >
              <Badge
                color={"success"}
                badgeContent={" "}
                variant={"dot"}
                sx={{
                  mr: ".7rem",
                }}
              />
              <Typography
                sx={{
                  color: theme?.palette?.common?.black,
                  fontSize: "13px",
                  fontWeiht: theme?.typography?.fontWeightRegular,
                }}
              >
                {"Courriers frêtés"}
              </Typography>
            </Stack>
            {/* <FormControl variant="outlined" sx={{ width: "12rem" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Année
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={currentYear}
                onChange={handleCostsPeriod}
                label="Période"
                size="small"
              >
                <MenuItem value={2022}>{"2022"}</MenuItem>
                <MenuItem value={2023}>{"2023"}</MenuItem>
                <MenuItem value={2024}>{"2024"}</MenuItem>
              </Select>
            </FormControl> */}
          </Stack>

          <Stack
            spacing={5}
            direction={"row"}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              mt: "1rem",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "10px",
                flexGrow: 1,
                alignItems: "center",
                px: "1rem",
                py: "2rem",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{}}>Total Poids (Kg)</Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: theme?.typography?.fontWeightBold,
                  color: theme?.palette?.info?.main,
                }}
              >
                {parseFloat(totalWeight).toFixed(2)}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "10px",
                flexGrow: 1,
                alignItems: "center",
                px: "1rem",
                py: "2rem",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{}}>Total Coûts ($)</Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: theme?.typography?.fontWeightBold,
                  color: theme?.palette?.success?.main,
                }}
              >
                {parseFloat(totalCost).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              "& .grid--header": {
                "& *": { fontWeight: theme?.typography?.fontWeightBold },
              },
              width: "100%",
              overflowX: "auto",
              // overflowY: "auto",
              borderRadius: "15px",
              my: "1rem",
            }}
          >
            <DataGrid
              rows={freightedRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[5, 25, 50]}
              disableRowSelectionOnClick
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "15px",
                overflow: "hidden",
                width: "100%",
              }}
            />
          </Box>
          <Stack
            direction={"row"}
            sx={{
              bgcolor: theme?.palette?.primary?.main,
              borderRadius: "15px",
              overflow: "hidden",
              width: "100%",
              p: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              border: `1px solid ${theme?.palette?.grey?.[300]}`,
              "& *": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
              "& *:hover": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
              "&:hover *": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                my: "1rem",
              }}
            >
              Périodes
            </Typography>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <DatePicker
                label={"Début"}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                sx={{
                  width: "15rem",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
              <DatePicker
                label={"Fin"}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                sx={{
                  width: "15rem",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
};

export default Dashboard;
