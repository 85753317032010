// component defintion

import * as React from "react";
import {
  useTheme,
  Stack,
  Grid,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import { AttachFile, Close } from "@mui/icons-material";
import { settingsCtx } from "../../context/settings";
import { notificationCtx } from "../../context/notification";
import MailApi from "../../helpers/archiving/requests/mail";
import imageToPdf from "../../utils/imageToPdf";
import SessionContext, { sessionCtx } from "../../context/session";

const Mailing = ({ setMailOpen }) => {
  const theme = useTheme();

  const settingsContext = React?.useContext(settingsCtx);
  const notifContext = React.useContext(notificationCtx);
  const sessionContext = React.useContext(sessionCtx);

  const showInfo = notifContext?.showInfo;
  const showSuccess = notifContext?.showSuccess;
  const showError = notifContext?.showError;
  const showWarning = notifContext?.showWarning;

  const emailFields = [
    {
      field: "to",
      title: "À",
      required: true,
      type: "email",
    },
    {
      field: "cc",
      title: "CC",
      required: false,
      type: "email",
    },
    {
      field: "subject",
      title: "Sujet",
      required: true,
      type: "text",
    },
    {
      field: "body",
      title: "Message",
      required: true,
      type: "text",
    },
  ];

  const handleSend = async (event) => {
    event?.preventDefault();

    const submitObj = {};

    const formData = new FormData(event.currentTarget);

    for (let [key, value] of formData.entries()) {
      submitObj[key] = value;
    }

    submitObj["path"] =
      sessionContext?.session?.sae?.currentArchive?.underlying_file?.path;

    await MailApi.send({ payload: submitObj })
      .then((status) => {
        if (status) {
          showSuccess("Email envoyé avec succès");

          event?.target?.reset();

          setMailOpen(false);
        } else {
          showError("Une erreur est survenue");
        }
      })
      .catch((error) => {
        console.log("&n error has occured when sending mail", error);

        showError("Une erreur est survenue");
      });
  };

  return (
    <Stack
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          maxWidth: "700px",
          minWidth: "250px",
          width: "60%",
          bgcolor: theme?.palette?.common?.white,
          borderRadius: "15px",
          boxShadow: "0px 0px 20px -4px #cddde1",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            px: "1rem",
            py: ".5rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.primary?.main,
              fontWeight: theme?.typography?.fontWeightBold,
            }}
          >
            Partage via e-mail
          </Typography>
          <IconButton
            onClick={(event) => {
              event?.preventDefault();

              setMailOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Stack
          direction={"column"}
          component={"form"}
          sx={{
            alignItems: "stretch",
            p: "1rem",
          }}
          onSubmit={handleSend}
        >
          <Grid
            container
            sx={{
              width: "100%",
              px: ".5rem",
            }}
            // gap={1}
          >
            {emailFields?.map((target, index) => {
              return (
                <Grid
                  key={index}
                  sm={target?.field === "body" ? 12 : 6}
                  xs={12}
                  sx={{
                    p: ".3rem",
                  }}
                >
                  <TextField
                    size={"small"}
                    label={target?.title}
                    placeholder={target?.title}
                    multiline={target?.field === "body"}
                    rows={target?.field === "body" ? 5 : null}
                    name={target?.field}
                    sx={{
                      width: "100%",
                    }}
                    required={target?.required}
                    type={target?.type}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid container>
            <Grid
              sm={6}
              xs={12}
              sx={{
                pr: ".3rem",
              }}
            >
              <Button
                startIcon={
                  <AttachFile
                    sx={{
                      transform: "rotate(45deg)",
                    }}
                  />
                }
                sx={{
                  bgcolor: `${theme?.palette?.secondary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.secondary?.main}`,
                  },
                  width: "100%",
                  // py: ".3rem",
                  borderRadius: "1rem",
                  // mt: "1rem",
                  color: theme?.palette?.primary?.main,
                  mt: "1rem",
                  cursor: "none",
                }}
              >
                Document joint
              </Button>
            </Grid>
            <Grid sm={6} xs={12}>
              <Button
                type={"submit"}
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  width: "100%",
                  // py: ".3rem",
                  borderRadius: "1rem",
                  // mt: "1rem",
                  color: theme?.palette?.common?.white,
                  mt: "1rem",
                }}
              >
                Envoyer
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Mailing;
