// session context defintion

import * as React from "react";

const sessionCtx = React?.createContext({});

const SessionContext = ({ children }) => {
  const [session, setSession] = React?.useState({
    data: {},
    currentAppName: sessionStorage?.getItem("currentAppName"),
    sae: {},
  });

  const [startDate, setStartDate] = React?.useState(null);
  const [endDate, setEndDate] = React?.useState(null);

  const setCurrentApp = (appName) => {
    setSession({ ...session, currentAppName: appName });
  };

  React.useEffect(() => {
    switch (session?.currentAppName) {
      case "vizioo e-signature":
        // e-courrier app's data clearing
        sessionStorage.removeItem("__fields__");
        sessionStorage.removeItem("__contact__");
        sessionStorage.removeItem("__dates__");
        sessionStorage.removeItem("__employees__");
        sessionStorage.removeItem("__entities__");

        // archiving data clearing
        sessionStorage.removeItem("__sae_fields__");

        break;

      case "vizioo gec":
        // e-signature app's data clearing
        sessionStorage.removeItem("paraph-ctx");
        sessionStorage.removeItem("document-ctx");

        // archiving data clearing
        sessionStorage.removeItem("__sae_fields__");

        break;

      case "vizioo archivage":
        // e-signature app's data clearing
        sessionStorage.removeItem("paraph-ctx");
        sessionStorage.removeItem("document-ctx");

        // e-courrier app's data clearing
        sessionStorage.removeItem("__fields__");
        sessionStorage.removeItem("__contact__");
        sessionStorage.removeItem("__dates__");
        sessionStorage.removeItem("__employees__");
        sessionStorage.removeItem("__entities__");

        break;

      default:
        break;
    }
  }, [session]);

  return (
    <sessionCtx.Provider
      value={{
        session,
        setSession,
        startDate,
        setStartDate,
        setCurrentApp,
        endDate,
        setEndDate,
      }}
    >
      {children}
    </sessionCtx.Provider>
  );
};

export default SessionContext;
export { sessionCtx };
