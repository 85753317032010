// operations api helper 

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";
import parseRef from "../../../utils/parseRef";

class OperationApi {
    static async getReference({orgName, refPrefix}) {


        const date = new Date();

        let lastReference = `${refPrefix || "REF_PREFIX"} : ${orgName || "ORG_NAME"
            } /YYY/00000`

        const startYear = `${date.getFullYear()}-01-01 00:00:00`;
        const endYear = `${date.getFullYear()}-12-31 23:59:59`;

        await lookup(
            `${BASE_URL}/api/documents?filters[source][$eq][0]=e-courrier&filters[type][$eq][1]=outgoing&sort=createdAt:desc&pagination[limit]=1&fields[0]=id&filters[$and][0][createdAt][$lte]=${endYear}&filters[$and][1][createdAt][$gte]=${startYear}`,
            {
                method: "GET",
                headers: getHeaders({}),
            }
        )
            .then((res) =>
                res.json().then((documents) => {
                    console.log("received last document object here", documents);

                    lastReference = lastReference?.replace(
                        "/YYY/00000",
                        `/${date.getFullYear()}/${parseRef(
                            documents?.meta?.pagination?.total + 1,
                            4
                        )}`
                    );

                })
            )
            .catch((error) => {
                console.log(
                    "an error has occured when fetching last mail document",
                    error
                );
            });

        return { text: lastReference }
    }
}

export default OperationApi