// component definition

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconButton, useTheme } from "@mui/material";
import ConfigureStep from "./ConfigureStep";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { notificationCtx } from "../../context/notification";
import lookup from "../../utils/lookup";
import { BASE_URL } from "../../constants/api";
import CourriersApi from "../../helpers/e-courrier/requests/courriers";
import { courriersCtx } from "../../context/courriers";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    title: "Configuration",
    short: "Centre Archivage",
  },
  {
    title: "Chargement",
    short: "Chargement du document",
  },
];

const ArchiveConfigure = ({}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const courriersContext = React?.useContext(courriersCtx);
  const navigate = useNavigate();

  const refreshCourriers = courriersContext?.refreshCourriers;

  const showError = React?.useContext(notificationCtx)?.showError;
  const showSuccess = React?.useContext(notificationCtx)?.showSuccess;
  const showWarning = React?.useContext(notificationCtx)?.showWarning;

  const assessDataCompleted = () => {
    const saeFields = JSON.parse(
      sessionStorage?.getItem("__sae_fields__") || "{}"
    );

    try {
      if (saeFields["title"]) {
        if (saeFields["comments"]) {
          if (saeFields["entities"]?.length > 0) {
            return true;
          } else {
            showWarning("Veuillez entrer au moins une entité");
            return false;
          }
        } else {
          showWarning("Veuillez entrer un commentaire");
          return false;
        }
      } else {
        showWarning("Veuillez entrer le titre");
        return false;
      }
    } catch (error) {
      showWarning("Veuillez remplir les champs");

      return false;
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;

    if (activeStep === 0) {
      if (assessDataCompleted()) {
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else {
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((target, index) => {
          const stepProps = {};

          const labelProps = {
            optional: (
              <Typography variant="caption">{target?.short}</Typography>
            ),
          };

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }

          return (
            <Step key={target?.title} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography
                  sx={{
                    fontWeight: stepProps.completed
                      ? theme?.typography?.fontWeightBold
                      : theme?.typography?.fontWeightRegular,
                  }}
                >
                  {target?.title}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box
            sx={{
              maxHeight: "calc(100vh - 300px)",
              overflowY: "auto",
            }}
          >
            <ConfigureStep step={activeStep + 1} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              onClick={activeStep === 0 ? () => {} : handleBack}
              className="btn-secodary flex-auto"
              sx={{
                bgcolor: `${theme?.palette?.common.white}`,
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: `${theme?.palette?.common.white}`,
                },
                // py: ".3rem",
                color: theme?.palette?.primary?.main,
                borderRadius: "1rem",
                mt: "1rem",
                px: "1rem",
                width: "45%",
                cursor: activeStep === 0 ? "not-allowed" : "pointer",
              }}
            >
              Précédent
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={() => {}}
                className="btn-secodary flex-auto"
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  // py: ".3rem",
                  color: theme?.palette?.common?.white,
                  borderRadius: "1rem",
                  mt: "1rem",
                  px: "1rem",
                  width: "45%",
                  cursor: "not-allowed",
                }}
              >
                Suivant
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                className="btn-secodary flex-auto"
                sx={{
                  bgcolor: `${theme?.palette?.primary?.main}`,
                  fontSize: "14px",
                  textTransform: "capitalize",
                  "&:hover": {
                    bgcolor: `${theme?.palette?.primary?.main}`,
                  },
                  // py: ".3rem",
                  color: theme?.palette?.common?.white,
                  borderRadius: "1rem",
                  mt: "1rem",
                  px: "1rem",
                  width: "45%",
                }}
              >
                Suivant
              </Button>
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ArchiveConfigure;
