// component defintion

import * as React from "react";

import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Cosigners from "../../../components/Cosigners";
import TagField from "./TabPanel/TagField";
import TagSign from "./TabPanel/TagSign";
import SelectSignee from "../../../components/SelectSignee";
import { currDocumentCtx } from "../../../context/currDocument";
import NotifsFields from "../NotifsFields";
import AttachedFiles from "../../../components/AttachedFiles";
import { paraphCtx } from "../../paraph";
import { sessionCtx } from "../../../context/session";
import Annotations from "./Annotations";
import ReferenceField from "../ReferenceField";
import Contributors from "./Contributors";
import Seal from "./TabPanel/Seal";
import ArchivingCommands from "../../../components/ArchvingCommands";

const TabPanel = ({ uploadFinalFile, setNotifications, notifications }) => {
  const [isDocumentNew, setIsDocumentNew] = React.useState(false);

  const isParaph = React?.useContext(paraphCtx)?.isParaph;

  React?.useEffect(() => {
    console.log("current pathname string", window?.location?.pathname);

    if (
      ["/new-document", "/mails/new"]?.some((tag) =>
        window?.location?.pathname?.includes(tag)
      )
    ) {
      setIsDocumentNew(true);
    }
  }, []);

  // console.log("received document status", { isDocumentNew });

  const annexContext = sessionStorage?.getItem("annexes-data")?.length > 1;
  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const theme = useTheme();

  return (
    <Box
      className="flat-list flex flex-col flat:flex-row flat:px-6"
      sx={{
        maxHeight: "calc(100vh - 80px)",
        overflowY: "auto",
        overflowX: "hidden",
        pb: "1rem",
      }}
    >
      {sessionStorage.getItem("document-type") !== "outgoing_mail" &&
      currentApp?.toLowerCase() !== "vizioo gec" &&
      isDocumentNew &&
      !annexContext ? (
        <NotifsFields
          setNotifications={setNotifications}
          notifications={notifications}
        />
      ) : (
        ""
      )}
      {currentApp?.toLowerCase() === "vizioo gec" && !isDocumentNew && (
        <ReferenceField />
      )}
      {isDocumentNew && !annexContext ? <TagField /> : ""}
      <TagSign uploadFinalFile={uploadFinalFile} />
      {sessionStorage.getItem("document-type") !== "outgoing_mail" &&
        currentApp?.toLowerCase() === "vizioo e-signature" && <Cosigners />}

      {currentApp?.toLowerCase() !== "vizioo gec" &&
        sessionStorage.getItem("document-type") === "outgoing_mail" && <Seal />}
      {currentApp?.toLowerCase() !== "vizioo archivage" && <AttachedFiles />}
      {currentApp?.toLowerCase() === "vizioo archivage" && (
        <ArchivingCommands />
      )}
      {currentApp?.toLowerCase() === "vizioo gec" &&
        !window.location.pathname?.includes("/new") && (
          <Box
            sx={{
              p: 0,
              m: 0,
            }}
          >
            <Annotations />
            <Contributors />
          </Box>
        )}
    </Box>
  );
};

export default TabPanel;
