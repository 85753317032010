// component definition

import * as React from "react";
import {
  Box,
  useTheme,
  Grid,
  Autocomplete,
  TextField,
  Stack,
  IconButton,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { Add, Close, Undo } from "@mui/icons-material";
import { entitiesCtx } from "../../context/entities";
import { settingsCtx } from "../../context/settings";
import { archivesCtx } from "../../context/archives";
import ArchivesApi from "../../helpers/archiving/requests/archive";
import { appDataCtx } from "../../context/appData";

const ParallelSearch = ({
  setOpen,
  setData,
  searchRows,
  setSearchRows,
  fieldsMap,
  setFieldsMap,
}) => {
  const theme = useTheme();

  const entitiesContext = React?.useContext(entitiesCtx);
  const settingsContext = React?.useContext(settingsCtx);
  const archivesContext = React?.useContext(archivesCtx);
  const appDataContext = React?.useContext(appDataCtx);

  const userRole = sessionStorage?.getItem("role")?.toLowerCase();

  const searchFields = [
    {
      field: "text",
      title: "Recherche",
      required: false,
    },
    {
      field: "entities",
      title: "Enités",
      required: true,
      options:
        userRole === "admin"
          ? entitiesContext?.entities
          : appDataContext?.entitiesIn,
    },
    {
      field: "categories",
      title: "Catégories",
      required: false,
      options: settingsContext?.categories,
    },
    {
      field: "tags",
      title: "Mots clé",
      required: false,
      options: settingsContext?.tags,
    },
  ];

  const [hiddenRows, setHiddenRows] = React?.useState([]);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    // process the search
    const searches = Object.keys(fieldsMap)?.reduce((prev, next) => {
      prev.push(fieldsMap[next]);
      return prev;
    }, []);

    console.log("processed rich search conditions", searches);

    const searchResults = archivesContext?.archives?.filter((target) => {
      /**
       * id there's any search obj/row that fullfil the current archive target
       */
      return searches?.some((searchObj, index) => {
        let optionsTruthiness = true;

        return Object.keys(target)?.every((key) => {
          let startKey = key;

          if (["title", "comments"]?.includes(key)) {
            startKey = "text";
          }

          if (searchObj[startKey] && optionsTruthiness) {
            console.log("will process rich search");

            if (["title", "comments"]?.includes(key)) {
              optionsTruthiness = [target["title"], target["comments"]]?.some(
                (opt) =>
                  opt?.toLowerCase()?.includes(searchObj?.text?.toLowerCase())
              );
            }

            console.log("will process rich search", optionsTruthiness);

            if (optionsTruthiness) {
              if (["categories", "entities", "categories"]?.includes(key)) {
                optionsTruthiness =
                  target[key]?.length === searchObj[key]?.length;

                if (optionsTruthiness) {
                  if (searchObj[key]?.length > 0) {
                    optionsTruthiness = searchObj[key]?.every(
                      (searchVal, index) => {
                        return (
                          searchVal?.id?.toString() ===
                          target[key][index]?.id?.toString()
                        );
                      }
                    );
                  }
                }
              }
            }
          }

          return optionsTruthiness;
        });
      });
    });

    // update the data
    console.log("processed rich search data here", searchResults);

    setData(searchResults);
    setOpen(false);
  };

  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 999,
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        backdropFilter: "blur(5px)",
      }}
    >
      <Box
        sx={{
          maxWidth: "700px",
          minWidth: "250px",
          width: "60%",
          bgcolor: theme?.palette?.common?.white,
          borderRadius: "15px",
          boxShadow: "0px 0px 20px -4px #cddde1",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            px: "1rem",
            py: ".5rem",
          }}
        >
          <Typography
            sx={{
              color: theme?.palette?.primary?.main,
              fontWeight: theme?.typography?.fontWeightBold,
            }}
          >
            Recherche enrichie
          </Typography>
          <IconButton
            onClick={(event) => {
              event?.preventDefault();

              setOpen(false);
            }}
          >
            <Close />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Stack
          direction={"column"}
          sx={{
            alignItems: "stretch",
            p: "1rem",
            maxHeight: "60vh",
            height: "60vh",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            {Array.from({ length: searchRows }).map((target, primaryIndex) => {
              return (
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "center",
                    width: "100%",
                    display: hiddenRows?.includes(primaryIndex)
                      ? "none"
                      : undefined,
                  }}
                >
                  <IconButton
                    disabled={searchRows === 1}
                    sx={{}}
                    onClick={(event) => {
                      event?.preventDefault();
                      setHiddenRows([...hiddenRows, primaryIndex]);

                      const _fields = { ...fieldsMap };
                      delete _fields[primaryIndex];

                      setFieldsMap(_fields);
                    }}
                  >
                    <Undo
                      sx={
                        {
                          //   fontSize: "14px",
                        }
                      }
                    />
                  </IconButton>
                  {fieldsMap[primaryIndex] && (
                    <Grid
                      container
                      sx={{
                        flexGrow: 1,
                        mx: ".5rem",
                        p: ".5rem",
                        borderRadius: "1rem",
                        bgcolor: theme?.palette?.common?.white,
                        border: `1px solid ${theme?.palette?.grey[300]}`,
                        mb: ".5rem",
                      }}
                    >
                      {searchFields?.map((target, index) => {
                        return ["entities", "categories", "tags"]?.includes(
                          target?.field
                        ) ? (
                          <Grid
                            item
                            key={index}
                            xs={6}
                            sm={4}
                            md={3}
                            sx={{
                              p: ".5rem",
                            }}
                          >
                            <Autocomplete
                              onChange={(event, object) => {
                                event?.preventDefault();

                                setFieldsMap({
                                  ...fieldsMap,
                                  [primaryIndex]: {
                                    ...fieldsMap[primaryIndex],
                                    [target?.field]: object,
                                  },
                                });
                              }}
                              size={"small"}
                              id="contacts--el"
                              options={target?.options}
                              value={
                                fieldsMap[primaryIndex][target?.field] || []
                              }
                              multiple
                              disableCloseOnSelect
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>{option?.name}</li>
                              )}
                              style={{ width: "100%" }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={target?.title}
                                  placeholder={target?.title}
                                  size={"small"}
                                />
                              )}
                            />
                          </Grid>
                        ) : (
                          <Grid
                            item
                            key={index}
                            xs={6}
                            sm={4}
                            md={3}
                            sx={{
                              p: ".5rem",
                            }}
                          >
                            <TextField
                              name={target?.field}
                              placeholder={target?.title}
                              label={target?.title}
                              type={
                                target?.field === "password"
                                  ? "password"
                                  : target?.field === "email"
                                  ? "email"
                                  : "text"
                              }
                              size={"small"}
                              // autoComplete="new-password"
                              sx={{
                                width: "100%",
                              }}
                              required={target?.required}
                              rows={target?.field === "comments" ? 4 : 1}
                              multiline={
                                target?.field === "comments" ? true : false
                              }
                              onChange={(event) => {
                                event?.preventDefault();

                                setFieldsMap({
                                  ...fieldsMap,
                                  [primaryIndex]: {
                                    ...fieldsMap[primaryIndex],
                                    [target?.field]: event?.target?.value,
                                  },
                                });
                              }}
                              value={fieldsMap[primaryIndex][target?.field]}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Stack>
              );
            })}
          </Box>
        </Stack>

        <Divider
          sx={{
            mt: "0rem",
          }}
        />

        <Stack
          direction={"row"}
          sx={{
            width: "100%",
            flexGrow: 1,
            justifyContent: "flex-end",
            px: "1.4rem",
            pb: "1rem",
          }}
        >
          <Button
            onClick={(event) => {
              event?.preventDefault();
              setSearchRows(searchRows + 1);

              setFieldsMap({
                ...fieldsMap,
                [searchRows]: {},
              });
            }}
            startIcon={<Add sx={{}} />}
            disabled={searchRows >= 10}
            sx={{
              bgcolor: `${theme?.palette?.secondary?.main}`,
              fontSize: "14px",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: `${theme?.palette?.secondary?.main}`,
              },
              width: "10rem",
              // py: ".3rem",
              borderRadius: "1rem",
              // mt: "1rem",
              color: theme?.palette?.primary?.main,
              mt: "1rem",
              mr: ".5rem",
            }}
          >
            Ajouter
          </Button>
          <Button
            type={"submit"}
            sx={{
              bgcolor: `${theme?.palette?.primary?.main}`,
              fontSize: "14px",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: `${theme?.palette?.primary?.main}`,
              },
              width: "10rem",
              // py: ".3rem",
              borderRadius: "1rem",
              // mt: "1rem",
              color: theme?.palette?.common?.white,
              mt: "1rem",
            }}
          >
            Envoyer
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ParallelSearch;
