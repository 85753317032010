// component defintion

import * as React from "react";
import {
  useTheme,
  Typography,
  Stack,
  Box,
  Avatar,
  TextField,
  Checkbox,
  Autocomplete,
  useMediaQuery,
  Chip,
  Button,
} from "@mui/material";
import {
  Add,
  AttachFile,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckRounded,
  Verified,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { sessionCtx } from "../context/session";
import SingImgContext from "../context/SingImgContext";
import { settingsCtx } from "../context/settings";
import { notificationCtx } from "../context/notification";
import { entitiesCtx } from "../context/entities";
import Mailing from "./archiving/Mailing";
import ArchivesApi from "../helpers/archiving/requests/archive";
import { archivesCtx } from "../context/archives";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const ArchivingCommands = ({}) => {
  const theme = useTheme();

  const archivesContext = React?.useContext(archivesCtx);

  const sessionContext = React?.useContext(sessionCtx);
  const entitiesContext = React?.useContext(entitiesCtx);
  const settingsContext = React?.useContext(settingsCtx);
  const notifsContext = React?.useContext(notificationCtx);
  const navigate = useNavigate();
  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));
  const isArchiveAnew = window?.location?.pathname?.includes("/new");

  const showSuccess = notifsContext?.showSuccess;
  const showError = notifsContext?.showError;

  const refreshArchives = archivesContext?.refreshArchives;

  const [viewData, setViewData] = React?.useState({
    tags: [],
    categories: [],
    entities: [],
  });

  const [actionBtnOn, setActionBtnOn] = React?.useState(false);

  React?.useEffect(() => {
    if (isArchiveAnew) {
      const saeFields = JSON.parse(
        sessionStorage?.getItem("__sae_fields__") || "{}"
      );

      setDocTitle(saeFields?.title);
      setDocComments(saeFields?.comments);

      setViewData({
        categories: saeFields?.categories?.map((target) => {
          return settingsContext?.categories?.find((category) => {
            return target?.id?.toString() === category?.id?.toString();
          });
        }),
        entities: saeFields?.entities?.map((target) => {
          return entitiesContext?.entities?.find((entity) => {
            return target?.id?.toString() === entity?.id?.toString();
          });
        }),
        tags: saeFields?.tags?.map((target) => {
          return settingsContext?.tags?.find((category) => {
            return target?.id?.toString() === category?.id?.toString();
          });
        }),
      });
    } else {
      const _currentArchive = sessionContext?.session?.sae?.currentArchive;

      console.log("current archive object", _currentArchive);

      setDocTitle(_currentArchive?.title);
      setDocComments(_currentArchive?.comments);

      setViewData({
        categories: _currentArchive?.categories?.map((target) => {
          return settingsContext?.categories?.find((category) => {
            return target?.id?.toString() === category?.id?.toString();
          });
        }),
        entities: _currentArchive?.entities?.map((target) => {
          return entitiesContext?.entities?.find((entity) => {
            return target?.id?.toString() === entity?.id?.toString();
          });
        }),
        tags: _currentArchive?.tags?.map((target) => {
          return settingsContext?.tags?.find((category) => {
            return target?.id?.toString() === category?.id?.toString();
          });
        }),
      });
    }
  }, [sessionContext?.session]);

  const [sendMailVisible, setSendMailVisible] = React?.useState(false);

  const handleUpdate = async (event) => {
    event?.preventDefault();

    const defaultArchive = sessionContext?.session?.sae?.currentArchive;

    const categories = viewData?.categories?.map((target) =>
      target?.id?.toString()
    );

    const tags = viewData?.tags?.map((target) => target?.id?.toString());

    const entities = viewData?.entities?.map((target) =>
      target?.id?.toString()
    );

    await ArchivesApi.update({
      data: {
        entities,
        categories,
        tags,
        title: docTitle,
        comments: docComments,
      },
      id: defaultArchive?.id?.toString(),
    })
      .then(async (status) => {
        if (status) {
          await refreshArchives();

          showSuccess("Mise à jour effectuée");
        } else {
          showError("Une erreur est survenue");
        }
      })
      .catch((error) => {
        console.log("an error has occured when updating an archive ", error);

        showError("Une erreur est survenue");
      });
  };

  const [docTitle, setDocTitle] = React?.useState("");
  const [docComments, setDocComments] = React?.useState("");

  React?.useEffect(() => {
    /**
     * if creating a new archive, we are going to update the sae
     * session storage field
     */

    if (isArchiveAnew) {
      sessionStorage?.setItem(
        "__sae_fields__",
        JSON.stringify({
          ...viewData,
          title: docTitle,
          comments: docComments,
        })
      );
    }
  }, [viewData, docComments, docTitle]);

  return (
    <div
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      {sendMailVisible && <Mailing setMailOpen={setSendMailVisible} />}

      <Button
        onClick={(event) => {
          event?.preventDefault();

          setSendMailVisible(true);
        }}
        sx={{
          bgcolor: `${theme?.palette?.secondary?.main}`,
          fontSize: "14px",
          "&:hover": {
            bgcolor: `${theme?.palette?.secondary?.main}`,
          },
          width: "100%",
          // py: ".3rem",
          borderRadius: "1rem",
          // mt: "1rem",
          color: theme?.palette?.primary?.main,
          mt: "1rem",
          textTransform: "none",
          mb: "1rem",
        }}
      >
        Joindre dans un mail
      </Button>
      <Stack
        direction={"column"}
        className="tag-element"
        spacing={1}
        sx={{
          position: "relative",
          pb: "1rem",
        }}
      >
        {/* <p>Paramètres</p> */}

        <TextField
          size={"small"}
          value={docTitle}
          sx={{
            width: "100%",
          }}
          label={"Titre"}
          placeholder={"Titre"}
          onChange={(event) => {
            event?.preventDefault();

            setDocTitle(event?.target?.value);
          }}
        />
        <TextField
          size="small"
          multiline={true}
          value={docComments}
          sx={{
            width: "100%",
            my: "1rem",
          }}
          label={"Commentaires"}
          placeholder={"Commentaires"}
          onChange={(event) => {
            event?.preventDefault();

            setDocComments(event?.target?.value);
          }}
        />

        <Box
          sx={{
            "& .MuiInput-root": {
              flexWrap: "nowrap",
              minWidth: "100%!important",
              maxWidth: "100%!important",
              overflowX: "auto!important",
            },
            "& .MuiFormControl-root": {
              // width: "max-content",
            },
            "& .MuiAutocomplete-endAdornment": {
              display: "none!important",
            },
            scrollbarWidth: "thin",
            "& *": {
              border: "none!important",
              outline: "none!important",
            },
            width: "100%",
          }}
        >
          <Autocomplete
            onChange={(event, object) => {
              event?.preventDefault();

              setViewData({ ...viewData, ["tags"]: object });
            }}
            size={"small"}
            name={"tags"}
            id="contacts--el"
            options={settingsContext?.tags}
            value={viewData["tags"] || []}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.name}</li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={"Mots clés"} variant={"standard"} />
            )}
          />

          <Autocomplete
            onChange={(event, object) => {
              event?.preventDefault();

              setViewData({ ...viewData, ["entities"]: object });
            }}
            size={"small"}
            name={"entities"}
            id="contacts--el"
            options={entitiesContext?.entities}
            value={viewData["entities"] || []}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.name}</li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={"Entités"} variant={"standard"} />
            )}
          />

          <Autocomplete
            onChange={(event, object) => {
              event?.preventDefault();

              setViewData({ ...viewData, ["categories"]: object });
            }}
            size={"small"}
            id="contacts--el"
            options={settingsContext["categories"]}
            value={viewData["categories"] || []}
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>{option?.name}</li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={"Mots clés"} variant={"standard"} />
            )}
          />
        </Box>

        {!isArchiveAnew && (
          <Button
            onClick={handleUpdate}
            sx={{
              bgcolor: `${theme?.palette?.primary?.main}`,
              fontSize: "14px",
              "&:hover": {
                bgcolor: `${theme?.palette?.primary?.main}`,
              },
              width: "100%",
              // py: ".3rem",
              borderRadius: "1rem",
              // mt: "1rem",
              color: theme?.palette?.common?.white,
              mt: "1.5rem!important",
              textTransform: "none",
              mb: "1rem",
            }}
          >
            Enregistrer
          </Button>
        )}
      </Stack>
    </div>
  );
};

export default ArchivingCommands;
