// constext defintion

import { CircularProgress, LinearProgress, Stack } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import App from "../App";
import LoginForm from "../pages/login";
import { socketCtx } from "./io";

import Cookies from "universal-cookie";

import { NODE_ENV } from "../constants/env";

const guardCtx = React?.createContext({});

const GuardContext = ({ children }) => {
  const [isUserAuthenticanted, setIsUserAuthenticated] = React?.useState(
    window?.sessionStorage?.getItem("token")?.length > 15
  );

  const [loadingMap, _setLoadingMap] = React?.useState(false);

  const setLoadingMap = (loadingValue, key) => {
    if (![loadingValue, key]?.includes(undefined)) {
      const newVal = {
        ...(JSON.parse(sessionStorage.getItem("loadingMap")) || {}),
        [key]: loadingValue,
      };

      if (Object.keys(newVal)?.some((key) => newVal[key] === true)) {
        _setLoadingMap(true);
      } else {
        _setLoadingMap(false);
      }

      sessionStorage?.setItem("loadingMap", JSON.stringify(newVal));
    }
  };

  window.setLoading = setLoadingMap;

  const navigate = useNavigate();

  const [isFetchCustomized, setIsFetchCustomized] = React?.useState(false);

  React.useEffect(() => {
    sessionStorage?.setItem("loadingMap", JSON.stringify({}));

    setIsFetchCustomized(true);

    if (window?.sessionStorage?.getItem("token")?.length > 15) {
      setIsUserAuthenticated(true);

      socketCtx.auth = {
        user: {
          fullName: sessionStorage?.getItem("username"),
          userId: sessionStorage.getItem("userId"),
          department: sessionStorage.getItem("department"),
        },
      };

      socketCtx.connect();

      console.log("should have been connected to sockets server");
    } else {
      if (
        !window?.location?.pathname?.includes("-password") &&
        !(window?.location?.pathname === "/")
      ) {
        navigate("/login", { replace: true });
      } else {
        console.log(
          "prevented moving to login as we are processing passwords or loading"
        );
      }

      try {
        console.log(
          "no token registered, should authenticate",
          window?.sessionStorage?.getItem("token").length
        );
      } catch (error) {
        console.log("no registered token", error);
      }
    }

    console.log("authentication value here", { isUserAuthenticanted });
  }, [isUserAuthenticanted]);

  /**
   *
   * Handle logout events of the app
   */
  const handleLogout = (event) => {
    event?.preventDefault();

    setLoadingMap(true, "disconnecting");

    const cookies = new Cookies();
    cookies.remove("user-credentials", {
      path: "/",
    });

    socketCtx.disconnect();
    sessionStorage?.clear();

    cookies.remove("user-credentials");
    setIsUserAuthenticated(false);

    setTimeout(() => {
      setLoadingMap(false, "disconnecting");
      navigate("/login", { replace: true });
    }, 1000);
  };

  const checkSession = (redirect = false, pushInside = false) => {
    const cookies = new Cookies();

    const userCreds = cookies.get("user-credentials") || {};

    console.log("current user credentials", userCreds);

    const role = sessionStorage?.getItem("role");

    if (
      window?.location?.pathname?.includes("/settings") &&
      role?.toLowerCase() !== "admin"
    ) {
      handleLogout();
    }

    if (userCreds["token"]) {
      console.log("should navigate to dashb");

      sessionStorage.setItem("token", userCreds.token);
      sessionStorage.setItem("username", userCreds.username);
      sessionStorage.setItem("email", userCreds.email);
      sessionStorage.setItem("userId", userCreds.userId);
      sessionStorage.setItem("role", userCreds.role);

      if (pushInside) {
        window.location.assign("/apps");
      }

      setIsUserAuthenticated(true);
    } else {
      handleLogout();
    }
  };

  return (
    <div>
      {loadingMap ? (
        <Stack
          sx={{
            width: "100vw",
            height: "100vh",
            p: 0,
            m: 0,
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "transparent",
            // filter: "blur(.05rem)",
            zIndex: 999,
          }}
        >
          <LinearProgress
            sx={{
              width: "100%",
            }}
          />
        </Stack>
      ) : (
        ""
      )}
      {isFetchCustomized && (
        <guardCtx.Provider
          value={{
            isUserAuthenticanted,
            setIsUserAuthenticated,
            setLoadingMap,
            checkSession,
            handleLogout,
          }}
        >
          {children}
        </guardCtx.Provider>
      )}
    </div>
  );
};

export { guardCtx };

export default GuardContext;
