import { ReactComponent as CheckboxIcon } from "../assets/svg/checkbox.svg";
import { ReactComponent as DateIcon } from "../assets/svg/date.svg";
import { ReactComponent as RadioIcon } from "../assets/svg/radio.svg";
import { ReactComponent as TextIcon } from "../assets/svg/text.svg";

export enum FileMenuName {
  Upload = "上傳檔案",
  FinishUpload = "上傳檔案完成",
  EditFile = "編輯文件",
  FinishFile = "完成文件",
}

export enum FabricObjectEnum {
  FONTFAMILY = "Helvetica",
  TEXT_COLOR = "#000",
  WHITE = "#FFF",
}

export enum AddGroupEnum {
  CHECKBOX,
  RADIO,
}

export const FileStepMenu = {
  0: FileMenuName.Upload,
  1: FileMenuName.FinishUpload,
  2: FileMenuName.EditFile,
  3: FileMenuName.FinishFile,
};

export const FileNameDefault = "File";

export enum FieldTagName {
  DATE = "Date",
  TEXT = "Texte",
  CHECKBOX = "Case",
  RADIO = "Radio",
  REF = "Référence",
}

export const FieldTagItem: TagItemType[] = [
  {
    name: FieldTagName.DATE,
    icon: <DateIcon />,
  },
  {
    name: FieldTagName.TEXT,
    icon: <TextIcon />,
  },
  {
    name: FieldTagName.REF,
    icon: <CheckboxIcon />,
  },
  // {
  //   name: FieldTagName.RADIO,
  //   icon: (
  //     <span>
  //       <RadioIcon />
  //     </span>
  //   ),
  // },
];
