// layout definition

import * as React from "react";
import {
  useTheme,
  Typography,
  Stack,
  Box,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  IconButton,
  useMediaQuery,
  Drawer,
  SpeedDial,
  Checkbox,
  Badge,
} from "@mui/material";
import {
  Dashboard,
  DesktopMac,
  Folder,
  Menu,
  RequestQuote,
  Add,
  Close,
  AddAlarm,
  NotificationAdd,
  People,
  Apps,
  ChevronLeft,
  Api,
  Contacts,
  NotificationAddSharp,
  Notifications,
  Settings,
  AllInclusive,
  AccountBalance,
} from "@mui/icons-material";

import { Link, useNavigate } from "react-router-dom";
import User from "./User";
import RejectionModal from "./RejectionModal";
import { documentsCtx } from "../context/documents";
import { sessionCtx } from "../context/session";
import { notificationCtx } from "../context/notification";
import { guardCtx } from "../context/Guard";

interface Props {
  children?: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [isMenuCollapsed, setIsMenuCollaped] = React?.useState(true);

  const handleCollapse = (event) => {
    event?.preventDefault();

    setIsMenuCollaped(!isMenuCollapsed);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const eSignatureMenuItems = [
    {
      title: "Reporting",
      link: "/apps/e-signature/dashboard",
      key: "/dashboard",
      icon: (props) => <Dashboard {...props} />,
      children: [
        { title: "Statistiques", link: "/apps/e-signature/dashboard" },
      ],
    },
    {
      title: "Espace personnel",
      link: "/apps/e-signature/mydocuments",
      key: "/mydocuments",
      icon: (props) => <DesktopMac {...props} />,
      children: [
        {
          title: "Nouveau document",
          link: "/apps/e-signature/mydocuments/new-document",
        },
        { title: "Mes documents", link: "/apps/e-signature/mydocuments/all" },
        { title: "Signatures", link: "/apps/e-signature/mydocuments/sign" },
        {
          title: "Notifications",
          link: "/apps/e-signature/mydocuments/notifications",
        },
      ],
    },
    {
      title: "Documents",
      key: "/requests",
      link: "/apps/e-signature/requests/all",
      icon: (props) => <Folder {...props} />,
      children: [
        { title: "Tous", link: "/apps/e-signature/requests/all" },
        { title: "En attentes", link: "/apps/e-signature/requests/pending" },
        { title: "Signés", link: "/apps/e-signature/requests/validated" },
      ],
    },
  ];

  const eCourrierMenuItems = [
    {
      title: "Reporting",
      link: "/apps/e-courrier/dashboard",
      key: "/dashboard",
      icon: (props) => <Dashboard {...props} />,
      children: [{ title: "Statistiques", link: "/apps/e-courrier/dashboard" }],
    },
    {
      title: "Courriers",
      key: "/mails",
      link: "/apps/e-courrier/mails/pending",
      icon: (props) => <Folder {...props} />,
      children: [
        { title: "Nouveau", link: "/apps/e-courrier/mails/new" },
        { title: "Traités", link: "/apps/e-courrier/mails/processed" },
        { title: "En cours", link: "/apps/e-courrier/mails/pending" },
        { title: "Archives", link: "/apps/e-courrier/mails/archived" },
        { title: "Projets", link: "/apps/e-courrier/mails/projects" },
        {
          title: "Notifications",
          link: "/apps/e-courrier/mails/notifications",
        },
      ],
    },
    {
      title: "Contacts",
      link: "/apps/e-courrier/contacts",
      key: "/contacts",
      icon: (props) => <Contacts {...props} />,
      children: [{ title: "Liste", link: "/apps/e-courrier/contacts" }],
    },
  ];

  const archivingMenuItems = [
    {
      title: "Dashboard",
      link: "/apps/archiving/dashboard",
      key: "/dashboard",
      icon: (props) => <Dashboard {...props} />,
      children: [{ title: "Reporting", link: "/apps/archiving/dashboard" }],
    },
    {
      title: "Archives",
      link: "/apps/archiving/library",
      key: "/library",
      icon: (props) => <AccountBalance {...props} />,
      children: [
        { title: "Médiathèque", link: "/apps/archiving/library" },
        { title: "Numérisation", link: "/apps/archiving/library/new" },
        {
          title: "Notifications",
          link: "/apps/archiving/library/notifications",
        },
      ],
    },
  ];

  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;
  const setCurrentApp = React?.useContext(sessionCtx)?.setCurrentApp;

  React?.useEffect(() => {
    if (
      window?.location?.pathname?.includes("/e-signature") &&
      currentApp !== "vizioo e-signature"
    ) {
      setCurrentApp("Vizioo E-signature");
    } else if (
      window?.location?.pathname?.includes("/e-courrier") &&
      currentApp !== "vizioo gec"
    ) {
      setCurrentApp("Vizioo GEC");
    } else if (
      window?.location?.pathname?.includes("/archiving") &&
      currentApp !== "vizioo archivage"
    ) {
      setCurrentApp("Vizioo Archivage");
    }
  }, [window?.location?.pathname]);

  const [menuMaterials, setMenuMaterials] = React?.useState([]);

  React?.useEffect(() => {
    let _menuMaterials = [];

    switch (currentApp?.toLowerCase()) {
      case "vizioo e-signature":
        _menuMaterials = eSignatureMenuItems;
        break;
      case "vizioo gec":
        _menuMaterials = eCourrierMenuItems;
        break;
      case "vizioo archivage":
        _menuMaterials = archivingMenuItems;
        break;

      default:
        break;
    }

    if (
      sessionStorage?.getItem("role")?.toLowerCase() === "admin" &&
      _menuMaterials?.length > 0
    ) {
      _menuMaterials?.push({
        title: "Administration",
        key: "settings",
        link: "/settings/all",
        icon: (props) => <Settings {...props} />,
        children: [{ title: "Paramètres", link: "/settings/all" }],
      });

      setMenuMaterials(_menuMaterials);
    } else {
      setMenuMaterials(_menuMaterials);
    }
  }, [currentApp]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const [drawerOpened, setDrawerOpened] = React?.useState(false);
  const [userDrawerOpened, setUserDrawerOpened] = React?.useState(false);
  const [isRingtoneOpen, setIsRingtoneOpen] = React?.useState(false);

  const lateDocuments = React?.useContext(
    documentsCtx
  )?.documents?.pending?.filter((target) => {
    return target?.author?.id === sessionStorage.getItem("userId");
  });

  const handleRingtone = async (event) => {
    event?.preventDefault();
  };

  const handleDial = (event) => {
    event?.preventDefault();
    if (window?.location?.pathname?.includes("/apps/e-courrier/contacts")) {
      navigate("/apps/e-courrier/contacts/new");
    } else if (window?.location?.pathname?.includes("/projects")) {
      navigate("/apps/e-courrier/mails/projects/new");
    } else if (window?.location?.pathname?.includes("/settings/users")) {
      navigate("/users-settings/user/new");
    } else if (window?.location?.pathname?.includes("/apps/e-courrier/")) {
      navigate("/apps/e-courrier/mails/new");
    } else if (window?.location?.pathname?.includes("/apps/e-signature/")) {
      navigate("/apps/e-signature/mydocuments/new-document");
    } else if (window?.location?.pathname?.includes("/entities")) {
      navigate("/settings/entities/new");
    } else if (
      window?.location?.pathname?.includes("/apps/archiving/library")
    ) {
      navigate("/apps/archiving/library/new");
    } else if (
      window?.location?.pathname?.includes("/apps/archiving/dashboard")
    ) {
      navigate("/apps/archiving/library/new");
    }
  };

  const notifsContext = React.useContext(notificationCtx);

  const totalNotifications = notifsContext?.notifications?.filter((target) => {
    return target?.seen === false;
  })?.length;

  const guardContext = React.useContext(guardCtx);

  const checkSession = guardContext?.checkSession;

  React.useEffect(() => {
    checkSession(true);
  }, []);

  return (
    <Box
      sx={{
        bgcolor: theme?.palette?.secondary?.main,
      }}
    >
      {![
        "/new-document",
        "/mails/new",
        "/e-courrier/contacts/",
        "/conversations/",
        "entityId",
      ]?.some((tag) => window?.location?.href?.includes(tag)) && (
        <SpeedDial
          ariaLabel="create document"
          onClick={handleDial}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
          icon={<Add sx={{}} />}
        />
      )}
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          alignItems: "flex-start",
          bgcolor: theme?.palette?.secondary?.main,
          minHeight: "100vh",
        }}
      >
        <Drawer
          anchor={"right"}
          open={userDrawerOpened}
          onClose={() => {
            setUserDrawerOpened(false);
          }}
        >
          <User />
        </Drawer>
        <Drawer
          anchor={"top"}
          open={drawerOpened}
          onClose={() => {
            setDrawerOpened(false);
          }}
        >
          {" "}
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              height: "100%",
              pt: "2rem",
              pl: "2rem",
              pb: "1rem",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={(event) => {
                event?.preventDefault();

                setDrawerOpened(!drawerOpened);
              }}
            >
              <Close
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Stack>
          <Stack
            direction={"column"}
            sx={{
              pb: "2rem",
              alignItems: !isMenuCollapsed ? "center" : undefined,
              px: "1rem",
            }}
          >
            {menuMaterials?.map((target, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={
                    expanded === index ||
                    window?.location?.pathname?.includes(target?.key)
                  }
                  onChange={handleChange(index)}
                  sx={{
                    boxShadow: "none",
                    borderRadius: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ChevronLeft
                        sx={{
                          color: theme?.palette?.common?.white,
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: theme?.palette?.grey[700],
                        fontSize: "14px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        fontWeight: theme?.typography?.fontWeightBold,
                      }}
                    >
                      {target?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {target?.children?.map((_child) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            navigate(_child?.link);
                          }}
                          sx={{
                            bgcolor: window?.location?.pathname?.includes(
                              _child?.link
                            )
                              ? "#227378"
                              : "transparent",
                            color: window?.location?.pathname?.includes(
                              _child?.link
                            )
                              ? theme?.palette?.common?.white
                              : undefined,
                            borderRadius: "10px",
                            "&:hover": {
                              bgcolor: window?.location?.pathname?.includes(
                                _child?.link
                              )
                                ? "#227378"
                                : undefined,
                            },
                            fontSize: "14px",
                          }}
                        >
                          {_child?.title}
                        </MenuItem>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Stack>
        </Drawer>
        <Stack
          direction={"column"}
          sx={{
            flexGrow: 1,
            position: "relative",
            width: "100%",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              borderBottom: `1px solid ${theme?.palette?.grey[300]}`,
              height: "65px",
              bgcolor: theme?.palette?.common?.white,
              position: "fixed",
              right: 0,
              left: isMenuCollapsed
                ? !screen900
                  ? "250px"
                  : "0px"
                : !screen900
                ? "65px"
                : "0px",
              top: 0,
              alignItems: "center",
              justifyContent: "space-between",
              px: "1rem",
              zIndex: 900,
            }}
          >
            {!screen900 && (
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <IconButton
                  onClick={(event) => {
                    event?.preventDefault();

                    setIsMenuCollaped(!isMenuCollapsed);
                  }}
                >
                  <Menu
                    sx={{
                      color: theme?.palette?.primary?.main,
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    color: theme?.palette?.primary?.main,
                    fontSize: "16px",
                    textTransform: "capitalize",
                    fontWeight: theme?.typography.fontWeightBold,
                    ml: ".5rem",
                  }}
                >
                  {currentApp?.split(" ")[1]}
                </Typography>
              </Stack>
            )}
            {screen900 ? (
              <Stack
                direction={"row"}
                sx={{
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <IconButton
                  onClick={(event) => {
                    event?.preventDefault();

                    setDrawerOpened(!drawerOpened);
                  }}
                >
                  <Menu
                    sx={{
                      color: theme?.palette?.primary?.main,
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: theme?.typography?.fontWeightBold,
                    color: theme?.palette?.primary?.main,
                  }}
                >
                  {menuMaterials?.find((menu) =>
                    window?.location?.pathname?.includes(menu?.link)
                  )?.title || "Vizioo"}
                </Typography>
              </Stack>
            ) : null}
            {!screen900 ? (
              <Stack
                direction={"row"}
                sx={{
                  height: "100%",
                  alignItems: "flex-end",
                  mx: "3rem",
                }}
              >
                {menuMaterials?.map((target) => {
                  return (
                    <Stack
                      onClick={() => {
                        navigate(target?.link);
                      }}
                      direction={"row"}
                      sx={{
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-start",
                        px: "1rem",
                        pb: "1rem",
                        cursor: "pointer",
                        borderBottom: window?.location?.pathname?.includes(
                          target?.key
                        )
                          ? `2px solid #227378`
                          : undefined,
                      }}
                    >
                      {target?.icon({
                        sx: {
                          color: "#227378",
                          fontSize: "25px",
                        },
                      })}
                      <Typography
                        sx={{
                          ml: ".5rem",
                          color: "#227378",
                          fontSize: "14px",
                          fontWeight: window?.location?.pathname?.includes(
                            target?.link
                          )
                            ? "bold"
                            : "normal",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {target?.title}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            ) : (
              ""
            )}
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
              }}
            >
              <Link
                to={
                  window?.location?.pathname?.includes("/e-courrier")
                    ? "/apps/e-courrier/mails/notifications"
                    : window?.location?.pathname?.includes("/archiving")
                    ? "/apps/archiving/library/notifications"
                    : "/apps/e-signature/mydocuments/notifications"
                }
              >
                <IconButton
                  sx={{
                    mr: "1rem",
                  }}
                >
                  <Badge badgeContent={totalNotifications} color="secondary">
                    <Notifications
                      sx={{
                        color: theme?.palette?.primary?.main,
                        fontSize: "28px",
                      }}
                    />
                  </Badge>
                </IconButton>
              </Link>
              <Link to={"/apps"}>
                <IconButton
                  sx={{
                    mr: "1rem",
                  }}
                >
                  <Apps
                    sx={{
                      color: theme?.palette?.primary?.main,
                      fontSize: "28px",
                    }}
                  />
                </IconButton>
              </Link>
              <Avatar
                onClick={(event) => {
                  event?.preventDefault();
                  setUserDrawerOpened(true);
                }}
                src={sessionStorage?.getItem("profile")}
                sx={{
                  width: 40,
                  height: 40,
                  cursor: "pointer",
                }}
              >
                {sessionStorage
                  ?.getItem("username")
                  ?.split(" ")
                  ?.map((chars) => chars[0])
                  ?.splice(0, 2)}
              </Avatar>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            {!screen900 ? (
              <Stack
                // className={"card-box"}
                sx={{
                  width: isMenuCollapsed ? "250px" : "65px",
                  minWidth: !isMenuCollapsed ? "65px" : undefined,
                  bgcolor: theme?.palette?.primary?.main,
                  maxHeight: "100vh",
                  minHeight: "100vh",
                  mt: "0px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  ml: "0rem",
                  py: "0!important",
                  transition: "all .2s",
                  transitionDelay: theme?.transitions?.easing?.easeInOut,
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 900,
                }}
              >
                {isMenuCollapsed && (
                  <Stack
                    direction={"column"}
                    sx={{
                      px: isMenuCollapsed ? "1rem" : undefined,
                      pt: "2rem",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      src={"/images/logo-white.png"}
                      style={{
                        width: "100%",
                        maxWidth: "100px",
                      }}
                    />
                  </Stack>
                )}
                <Stack
                  direction={"column"}
                  sx={{
                    py: "1.5rem",
                    alignItems: !isMenuCollapsed ? "center" : undefined,
                    pt: !isMenuCollapsed ? "15vh" : undefined,
                  }}
                >
                  {menuMaterials?.map((target, index) => {
                    return isMenuCollapsed ? (
                      <Accordion
                        key={index}
                        expanded={
                          expanded === index ||
                          window?.location?.pathname?.includes(target?.key)
                        }
                        onChange={handleChange(index)}
                        sx={{
                          boxShadow: "none",
                          borderRadius: 0,
                          bgcolor: "transparent",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ChevronLeft
                              sx={{
                                color: theme?.palette?.common?.white,
                              }}
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Stack
                            direction={"row"}
                            sx={{
                              alignItems: "center",
                            }}
                          >
                            {target?.icon({
                              sx: {
                                color: theme?.palette?.common?.white,
                                fontSize: "25px",
                                mr: "1rem",
                              },
                            })}
                            <Typography
                              sx={{
                                color: theme?.palette?.common?.white,
                                fontSize: "14px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontWeight: theme?.typography?.fontWeightBold,
                              }}
                            >
                              {target?.title}
                            </Typography>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          {target?.children?.map((_child, key) => {
                            return (
                              <MenuItem
                                key={key}
                                onClick={() => {
                                  navigate(_child?.link);
                                }}
                                sx={{
                                  bgcolor: window?.location?.pathname?.includes(
                                    _child?.link
                                  )
                                    ? `${theme?.palette?.common?.white}10`
                                    : "transparent",
                                  color: theme?.palette?.common?.white,
                                  borderRadius: "10px",
                                  "&:hover": {
                                    bgcolor:
                                      window?.location?.pathname?.includes(
                                        _child?.link
                                      )
                                        ? "#227378"
                                        : undefined,
                                  },
                                  fontSize: "14px",
                                }}
                              >
                                {_child?.title}
                              </MenuItem>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      <IconButton
                        onClick={(event) => {
                          event?.preventDefault();

                          navigate(target?.link);
                        }}
                        sx={{
                          width: "50px",
                          height: "50px",
                        }}
                      >
                        {target?.icon({
                          sx: {
                            fontSize: "25px",
                            color: theme?.palette?.common?.white,
                          },
                        })}
                      </IconButton>
                    );
                  })}
                </Stack>
              </Stack>
            ) : (
              ""
            )}
            <Box
              sx={{
                mt: "65px",
                width: "100%",
                ml: isMenuCollapsed
                  ? !screen900
                    ? "250px"
                    : "0px"
                  : !screen900
                  ? "65px"
                  : "0px",
                maxWidth: isMenuCollapsed
                  ? !screen900
                    ? "calc(100vw - 250px)"
                    : "100vw"
                  : !screen900
                  ? "calc(100vw - 65px)"
                  : "100vw",
                // maxHeight: "calc(100vh - 65px)",
                // minHeight: "calc(100vh - 65px)",
                px: !screen900 ? "1.5rem" : "1.3rem",
                // overflowY: "auto",
                bgcolor: theme?.palette?.secondary?.main,
                height: "max-content",
              }}
            >
              {children}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Layout;
