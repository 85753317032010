// component definititon

import * as React from "react";
import {
  Box,
  useTheme,
  Stack,
  Typography,
  IconButton,
  AvatarGroup,
  Avatar,
  Chip,
  useMediaQuery,
  SpeedDial,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  Add,
  Archive,
  AttachFile,
  Check,
  ChevronRight,
  Download,
  DownloadDoneTwoTone,
  DownloadForOffline,
  Edit,
  MoreVert,
  SearchRounded,
  Visibility,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import OptionModal from "../RejectionModal";
import { documentsCtx } from "../../context/documents";
import { Search } from "react-feather";
import { appDataCtx } from "../../context/appData";
import NavigationLine from "../NavigationLine";
import { filesCtx } from "../../context/files";
import { BASE_URL } from "../../constants/api";
import getHeaders from "../../utils/getHeaders";
import Layout from "../Layout";
import lookup from "../../utils/lookup";
import { courriersCtx } from "../../context/courriers";
import CourriersApi from "../../helpers/e-courrier/requests/courriers";
import parseRef from "../../utils/parseRef";
import mergePdf from "../../utils/pdfMerger";
import { archivesCtx } from "../../context/archives";
import ParallelSearch from "./ParallelSearch";

const ITEM_HEIGHT = 48;

const Library = ({ firstTitle = "E-Courrier", title }) => {
  const theme = useTheme();

  const navigate = useNavigate();

  const _title = new URLSearchParams(window?.location?.search).get("title");

  if (_title) {
    title = _title;
  }

  const [optionModal, setOptionModal] = React?.useState({
    data: {},
    visible: false,
    rejected: true,
  });

  const archivesContext = React?.useContext(archivesCtx);

  const [searchedRows, setSearchedRows] = React?.useState([]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const downloadLink = React?.useRef(null);
  const fileContext = React?.useContext(filesCtx);

  const [rowActions, setRowActions] = React?.useState([
    {
      title: "Télécharger",
      icon: (options) => <DownloadForOffline {...options} />,
    },
  ]);

  React.useEffect(() => {
    if (
      sessionStorage.getItem("role")?.toLowerCase() === "admin" &&
      rowActions?.length === 1
    ) {
      setRowActions([
        ...rowActions,
        { title: "Terminer", icon: (options) => <Check {...options} /> },
        {
          title: "Archiver",
          icon: (options) => <Archive {...options} />,
        },
        { title: "Editer", icon: (options) => <Edit {...options} /> },
      ]);
    }
  }, []);

  const columns = [
    {
      field: "reference",
      headerName: "Réf",
      renderCell: ({ row }) => {
        return <Typography>{row?.reference || "Aucune"}</Typography>;
      },
    },
    {
      field: "entities",
      headerName: "Entités",
      renderCell: ({ row }) => {
        const _names = (row?.entities || [])?.map((entity) => entity?.name);
        const part1 = _names?.slice(0, 2)?.map((target) =>
          target
            ?.split(" ")
            ?.map((strs) => strs[0]?.toUpperCase())
            ?.join("")
        );
        const part2 = _names?.slice(2);

        return (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme?.palette?.info?.main,
            }}
          >
            {part1?.length > 0 ? part1?.join(", ") : "Aucun(e)"}
            {part2?.length > 0 ? ", etc" : ""}
          </Typography>
        );
      },
    },
    {
      field: "title",
      headerName: "Titre",
    },
    {
      field: "categories",
      headerName: "Catégories",
      renderCell: ({ row }) => {
        const _names = (row?.categories || [])?.map((entity) => entity?.name);
        const part1 = _names
          ?.slice(0, 2)
          ?.map((name) => `${name?.slice(0, 3)}..`);
        const part2 = _names?.slice(2);

        return (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme?.palette?.success?.main,
            }}
          >
            {part1?.length > 0 ? part1?.join(", ") : "Aucun(e)"}
            {part2?.length > 0 ? ", etc" : ""}
          </Typography>
        );
      },
    },
    {
      field: "tags",
      headerName: "Mots clé",
      renderCell: ({ row }) => {
        const _names = (row?.tags || [])?.map((entity) => entity?.name);
        const part1 = _names
          ?.slice(0, 2)
          ?.map((name) => `${name?.slice(0, 3)}..`);
        const part2 = _names?.slice(2);

        return (
          <Typography
            sx={{
              fontSize: "14px",
              color: theme?.palette?.warning?.main,
            }}
          >
            {part1?.length > 0 ? part1?.join(", ") : "Aucun(e)"}
            {part2?.length > 0 ? ", etc" : ""}
          </Typography>
        );
      },
    },
    {
      field: "receptionist",
      headerName: "Initiateur",
      renderCell: ({ row }) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {row?.receptionist?.username || "Aucun(e)"}
          </Typography>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Date",
      renderCell: ({ row }) => {
        return (
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {new Date(row?.createdAt)?.toLocaleDateString("fr")}
          </Typography>
        );
      },
    },
    {
      field: "open",
      headerName: "Ouvrir",
      renderCell: ({ row }) => (
        <IconButton
          onClick={(event) => {
            event?.preventDefault();

            if (!(row?.archived || row?.closed)) {
              navigate(`/apps/archiving/library/view/${row?.id}`);
            }
          }}
          sx={{
            cursor: row?.archived || row?.closed ? "not-allowed" : "pointer",
          }}
        >
          <Visibility
            sx={{
              color: "#227378",
            }}
          />
        </IconButton>
      ),
    },
  ]?.map((col) => {
    return {
      ...col,
      flex: ["open", "more", "closed", ""]?.includes(col.field) ? undefined : 1,
      headerClassName: "grid--header",
    };
  });

  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    const searchedCourriers = [...courriers]?.filter((target) => {
      return Object.keys(target)?.some((key) => {
        if (["author"]?.includes(key)) {
          return target[key]?.fullName
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else if (["file"]?.includes(key)) {
          return target[key]?.path
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else if (["terminated"]?.includes(key)) {
          console.log("current search config ", {
            key,
            value: target[key],
          });

          return (
            target?.rejectedBy?.username?.toString()?.length > 0
              ? "rejeté"
              : target?.terminated === true
              ? "complété"
              : `reste ${target?.terminated}`
          )
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        } else {
          return target[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        }
      });
    });

    setSearchedRows(searchedCourriers);
  };

  const handleZipDownload = async (event) => {
    event?.preventDefault();

    const files = [
      optionModal?.data?.filePath,
      ...(optionModal?.data?.attachedFiles || [])?.map(
        (target) => target?.path
      ),
    ]?.map((file) => {
      return "/uploads/" + file?.split("/")?.slice(-1)[0];
    });

    const _fileName = optionModal?.data?.title?.split(".")?.slice(0, 1)[0];

    await lookup(`${BASE_URL}/api/compress`, {
      headers: getHeaders({}),
      body: JSON.stringify({
        data: {
          preferredName: _fileName,
          filesToCompress: files,
        },
      }),
      method: "POST",
    })
      .then((res) =>
        res.blob().then((zipBuffer) => {
          const compressedFile = new File([zipBuffer], _fileName + ".zip", {
            type: "application/zip",
          });
          const dowloadUrl = URL.createObjectURL(compressedFile);

          // window.open(dowloadUrl);

          downloadRef?.current?.setAttribute("download", `${_fileName}.zip`);
          downloadRef?.current?.setAttribute("href", dowloadUrl);
          downloadRef?.current?.click();
        })
      )
      .catch((error) => {
        console.log("an error has occured when creating ", error);
      });
  };

  const downloadRef = React?.useRef({});

  const [richSearchOpen, setRichSearchOpen] = React?.useState(false);

  React.useEffect(() => {
    setSearchedRows(archivesContext?.archives);
  }, [archivesContext?.archives]);

  const [searchRows, setSearchRows] = React?.useState(1);
  const [fieldsMap, setFieldsMap] = React?.useState({ 0: {} });

  return (
    <Layout>
      <div>
        {richSearchOpen && (
          <ParallelSearch
            fieldsMap={fieldsMap}
            searchRows={searchRows}
            setFieldsMap={setFieldsMap}
            setSearchRows={setSearchRows}
            setOpen={setRichSearchOpen}
            setData={setSearchedRows}
          />
        )}
        <OptionModal
          open={optionModal?.visible}
          setOpen={() => {
            setOptionModal({
              ...optionModal,
              visible: false,
            });
          }}
          childrenClassName={`w-[${screen900 ? 95 : 70}%]`}
        >
          <Stack className="card-box w-full p-6" sx={{}}>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme?.typography?.fontWeightBold,
                color: theme?.palette?.primary?.main,
              }}
            >
              {optionModal?.rejected ? "DOCUMENT REJETE" : "DOCUMENT"}
            </Typography>
            <a
              ref={downloadRef}
              href={"/"}
              style={{
                display: "none",
              }}
            ></a>

            <Stack
              direction={"column"}
              sx={{
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {optionModal?.rejected ? (
                    <Stack
                      direction={"column"}
                      sx={{
                        width: screen900 ? "90%" : "45%",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme?.palette?.grey?.[500],
                          fontWeight: theme?.typography?.fontWeightBold,
                          mt: "1rem",
                        }}
                      >
                        Par
                      </Typography>
                      <Typography>{optionModal?.data?.username}</Typography>
                      <Typography
                        sx={{
                          color: theme?.palette?.grey?.[500],
                          fontWeight: theme?.typography?.fontWeightBold,
                          mt: "1rem",
                        }}
                      >
                        Date de rejet
                      </Typography>
                      <Typography>
                        {new Date(
                          optionModal?.data?.rejectionDate
                        )?.toLocaleString("fr-FR")}
                      </Typography>
                    </Stack>
                  ) : (
                    ""
                  )}
                  <Stack
                    direction={"column"}
                    sx={{
                      width: screen900 ? "90%" : "45%",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[500],
                        fontWeight: theme?.typography?.fontWeightBold,
                        mt: "1rem",
                      }}
                    >
                      Pièces jointes
                    </Typography>
                    {optionModal?.data?.attachedFiles?.length > 0 ? (
                      ""
                    ) : (
                      <Typography>{"Aucune pièce jointe"}</Typography>
                    )}
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                        maxWidth: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {[
                        {
                          path: optionModal?.data?.filePath,
                          name: optionModal?.data?.title,
                        },
                        ...(optionModal?.data?.attachedFiles || []),
                      ]?.map((target, index) => {
                        return (
                          <Chip
                            icon={
                              <AttachFile
                                sx={{
                                  color: theme?.palette?.primary?.main,
                                  fontSize: "15px",
                                  transform: "rotate(45deg)",
                                }}
                              />
                            }
                            key={index}
                            href={target?.path}
                            component={"a"}
                            size={"small"}
                            label={target?.name}
                            target={"_blank"}
                            sx={{
                              maxWidth: "100%!important",
                              mr: ".2rem",
                              mt: ".2rem",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Stack>
                {optionModal?.rejected ? (
                  <div>
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[500],
                        fontWeight: theme?.typography?.fontWeightBold,
                        mt: "1rem",
                      }}
                    >
                      Motif
                    </Typography>
                    <Typography>{optionModal?.data?.reason}</Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Stack>
            <Stack
              onClick={handleZipDownload}
              direction={"row"}
              sx={{
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn-primary flex-auto"
                style={{
                  width: "max-content",
                  marginTop: "1rem",
                }}
              >
                Compresser {<Download />}
              </button>
            </Stack>
          </Stack>
        </OptionModal>
        <Stack
          sx={{
            pt: "1rem",
            // px: !screen900 ? "2rem" : ".5rem",
            overflowX: "hidden",
            maxWidth: !screen900 ? "100%" : undefined,
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <NavigationLine firstTitle={"Archives"} secondTitle={"Toutes"} />
            <Button
              onClick={(event) => {
                event?.preventDefault();

                setRichSearchOpen(true);
              }}
              startIcon={<SearchRounded sx={{}} />}
              sx={{
                bgcolor: `${theme?.palette?.common?.white}`,
                fontSize: "14px",
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: `${theme?.palette?.common?.white}`,
                },
                width: "10rem",
                // py: ".3rem",
                borderRadius: "1rem",
                // mt: "1rem",
                color: theme?.palette?.primary?.main,
                mt: "1rem",
                mr: ".5rem",
              }}
            >
              Rechercher
            </Button>
          </Stack>
          <Box
            sx={{
              "& .grid--header": {
                "& *": { fontWeight: theme?.typography?.fontWeightBold },
              },
              width: "100%",
              overflowX: "auto",
              // maxHeight: "75vh",
              // overflowY: "auto",
              borderRadius: "15px",
              pt: "1rem",
              pb: "3rem",
            }}
          >
            <DataGrid
              rows={searchedRows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 25, 50]}
              disableRowSelectionOnClick
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "15px",
                overflow: "hidden",
              }}
              stickyHeader
            />
          </Box>
        </Stack>
      </div>
    </Layout>
  );
};

const MoreActions = ({ rowObj, rowActions }) => {
  const courrierContext = React.useContext(courriersCtx);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const refreshCourriers = courrierContext.refreshCourriers;

  async function handleMore({ event, rowObj, option }) {
    event?.preventDefault();

    if (option.title === "Terminer") {
      console.log("current links for mergin processed in download", rowObj);

      await CourriersApi.update({
        id: rowObj?.id,
        data: { closed: true, archived: false },
      }).catch((error) => {
        console.log("an error has occured when closing courriers", error);
      });
    } else if (option.title === "Archiver") {
      await CourriersApi.update({
        id: rowObj?.id,
        data: { archived: true, closed: false },
      }).catch((error) => {
        console.log("an error has occured when archiving courriers", error);
      });
    } else if (option?.title === "Editer") {
      await CourriersApi.update({
        id: rowObj?.id,
        data: { archived: false, closed: false },
      }).catch((error) => {
        console.log("an error has occured when activating courriers", error);
      });
    } else {
      let mergeLink = "";

      let files = [
        {
          url: rowObj?.underlying_file?.data?.attributes?.url,
          title: rowObj?.title,
        },
        {
          url: rowObj?.receiptMail?.underlying_file?.data?.attributes?.url,
          title: rowObj?.receiptMail?.title,
        },
        {
          url: rowObj?.responseMail?.underlying_file?.data?.attributes?.url,
          title: rowObj?.responseMail?.title,
        },
      ];

      console.log(
        "current links for mergin processed in download",
        files,
        rowObj
      );

      files = files?.filter((target) => target?.url?.length > 3);

      files = await Promise.all(
        files?.map((file) => {
          if (file.url) {
            return lookup(`${BASE_URL}${file?.url}`, {
              method: "GET",
              headers: getHeaders({}),
            }).then((res) =>
              res?.blob().then((data) => {
                const finalFile = new File([data], file.title + ".pdf", {
                  type: "application/pdf",
                });

                return finalFile;
              })
            );
          } else {
            return null;
          }
        })
      );

      files = files?.filter((target) => target);

      await mergePdf({
        files: files,
        parentDocument: rowObj?.title,
      }).then((result) => {
        mergeLink = result.url;
      });

      console.log("received merged courriers link", mergeLink);

      if (mergeLink) {
        window.open(mergeLink);
      }
    }

    refreshCourriers();

    handleClose();
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {rowActions.map((option) => (
          <MenuItem
            key={option?.title}
            onClick={(event) => handleMore({ event, rowObj, option })}
          >
            <Stack
              direction={"row"}
              sx={{
                alignItems: "center",
              }}
            >
              {option.icon({
                sx: {
                  color: theme?.palette?.primary?.main,
                  fontSize: "24px",
                  mr: ".5rem",
                },
              })}
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {option?.title}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Library;
