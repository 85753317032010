// tags data central

import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize";

class TagApi {
    static async getAll() {
        const tags = [];

        await lookup(`${BASE_URL}/api/tags`, {
            method: "GET",
            headers: getHeaders({})
        }).then(res => res.json().then(result =>  {
            if (Array.isArray(result?.data)) {
                tags.push(...strapiSanitize({object: result?.data}))
            }
        })).catch(error => {
            console.log("an error has occured when fetching tags", error);
            
        })

        return tags
    }

    static async remove({ id }) {

        let status = null;

        await lookup(`${BASE_URL}/api/tags/${id}`, {
            method: "DELETE",
            headers: getHeaders({})
        }).then(res => res.json().then(result => {
            if (result) {
                status = result
            }
        })).catch(error => {
            console.log("an error has occured when remoing a tag", error);
            
        });

        return status
    }

    static async create(data) {
        let status = null;

        await lookup(`${BASE_URL}/api/tags`, {
            method: "POST",
            headers: getHeaders({}),
            body: JSON.stringify(data)
        }).then(res => res.json().then(result => {
            if (result) {
                status = result;

            }
        })).catch(error => {
            console.log("an error has occured when creating a tag", error);
            
        })

        return status
    }
}

export default TagApi
