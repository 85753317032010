// application entry point

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./styles/App.scss";
import App from "./App";
import Message from "./components/Message";
import ThemeContext from "./context/Theme";
import { CssBaseline } from "@mui/material";
import GuardContext from "./context/Guard";
import DocumentContext from "./context/documents";
import SignaturesContext from "./context/signatures";
import UsersContext from "./context/users";
import AppDataContext from "./context/appData";
import CurrDocumentContext from "./context/currDocument";
import FilesContext from "./context/files";
import RolesContext from "./context/rolesCtx";
import NotificationContext from "./context/notification";
import SessionContext from "./context/session";
import ContactsContext from "./context/contacts";
import EntitiesContext from "./context/entities";
import CourrierContext from "./context/courriers";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import TimerWraper from "./context/timer";
import MessagesContext from "./context/messages";
import ScanContext from "./context/scan";
import SettingsContext from "./context/settings";
import ArchivesContext from "./context/archives";
import SaeConfContext from "./context/saeConf";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter basename={"/"}>
    <ThemeContext>
      <CssBaseline />
      <GuardContext>
        <SessionContext>
          <NotificationContext>
            <RolesContext>
              <UsersContext>
                <DocumentContext>
                  <SignaturesContext>
                    <AppDataContext>
                      <CourrierContext>
                        <CurrDocumentContext>
                          <FilesContext>
                            <ContactsContext>
                              <EntitiesContext>
                                <MessagesContext>
                                  <ArchivesContext>
                                    <ScanContext>
                                      <SettingsContext>
                                        <SaeConfContext>
                                        <TimerWraper>
                                          <LocalizationProvider
                                            adapterLocale={"fr"}
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <App />
                                          </LocalizationProvider>
                                        </TimerWraper>
                                        </SaeConfContext>
                                        
                                      </SettingsContext>
                                    </ScanContext>
                                  </ArchivesContext>
                                </MessagesContext>
                              </EntitiesContext>
                            </ContactsContext>
                          </FilesContext>
                        </CurrDocumentContext>
                      </CourrierContext>
                    </AppDataContext>
                  </SignaturesContext>
                </DocumentContext>
              </UsersContext>
            </RolesContext>
          </NotificationContext>
        </SessionContext>
      </GuardContext>
      <Message />
    </ThemeContext>
  </BrowserRouter>
);
