// page component definition
import * as React from "react";

import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";

import {
  useTheme,
  Stack,
  Typography,
  Box,
  Grid,
  Chip,
  useMediaQuery,
  Avatar,
  IconButton,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import NavigationLine from "../../components/NavigationLine";
import { usersCtx } from "../../context/users";
import { rolesCtx } from "../../context/rolesCtx";
import { Search } from "react-feather";

const AccessManagement = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const usersContext = React?.useContext(usersCtx);

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    const _users = usersContext?.users?.filter(
      (user) =>
        user?.id?.toString() !== sessionStorage?.getItem("userId")?.toString()
    );

    setUsers(_users);
  }, [usersContext?.users]);

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const roles = React?.useContext(rolesCtx)?.roles;

  const [searchValue, setSearchValue] = React.useState("");

  const [searchedUsers, setSearchedUsers] = React.useState([]);

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    setSearchedUsers(
      [...users]?.filter((target) => {
        console.log("current keys here", Object.keys(target));

        return Object.keys(target)?.some((key) => {
          return target[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        });
      })
    );
  };

  React.useEffect(() => {
    setSearchedUsers([...users]);
  }, [users]);

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigationLine
            firstTitle={"Paramètres"}
            secondTitle={"Utilisateurs"}
            backLink={"/settings/all"}
          />
          <Box
            className="text-field"
            sx={{
              py: ".3rem",
            }}
          >
            <input
              type="text"
              value={searchValue}
              onChange={handleSearch}
              placeholder={"Recherche"}
            />
            <span className="cursor-pointer">
              <Search className="stroke-black" />
            </span>
          </Box>
        </Stack>

        <Grid
          container
          sx={{
            width: "100%",
            maxHeight: !screen900 ? "calc(100vh - 160px)" : undefined,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {searchedUsers?.map((target) => {
            const role = roles?.find(
              (role) => role?.id?.toString() === target?.role?.toString()
            )?.name;

            return (
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                sx={{
                  alignSelf: "stretch",
                }}
              >
                <Box
                  sx={{
                    p: ".5rem",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction={"column"}
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(`/users-settings/user/edit?id=${target?.id}`);
                    }}
                    sx={{
                      bgcolor: theme?.palette?.common?.white,
                      borderRadius: "1rem",
                      width: "100%",
                      p: "1rem",
                      cursor: "pointer",
                      justifyContent: "center",
                      "&:hover": {
                        boxShadow: "0px 2px 20px -4px #cddde1",
                      },
                      alignItems: "center",
                      position: "relative",
                      py: "2rem",
                    }}
                  >
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: ".5rem",
                        top: ".5rem",
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                    <Avatar
                      src={target.profile}
                      sx={{
                        bgcolor: theme?.palette?.primary?.main,
                        width: "50px",
                        height: "50px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                      }}
                    >
                      {target?.username}
                    </Typography>
                    <Chip
                      variant={"outlined"}
                      size={"small"}
                      color={role === "Admin" ? "success" : "warning"}
                      label={role?.toLowerCase()}
                      sx={{
                        width: "max-content",
                        py: ".0rem",
                        mb: "1rem",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        // fontStyle: "italic",
                        color: theme?.palette?.grey[800],
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                      }}
                    >
                      {target?.email || "Non défini"}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        // fontStyle: "italic",
                        color: theme?.palette?.grey[800],
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                      }}
                    >
                      {target?.department || "Non défini"}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Layout>
  );
};

export default AccessManagement;
