// component definition

import * as React from "react";
import {
  useTheme,
  Typography,
  Stack,
  Box,
  Grid,
  useMediaQuery,
  Tabs,
  Tab,
  Badge,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Layout from "../../components/Layout";
import {
  Archive,
  BarChart,
  CheckRounded,
  CrisisAlert,
  DateRange,
  Forum,
  NotificationImportant,
  Timelapse,
} from "@mui/icons-material";
import { documentsCtx } from "../../context/documents";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";
import { archivesCtx } from "../../context/archives";
import { notificationCtx } from "../../context/notification";

import Chart from "react-apexcharts";
import { DataGrid } from "@mui/x-data-grid";
import { messagesCtx } from "../../context/messages";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { sessionCtx } from "../../context/session";
import { entitiesCtx } from "../../context/entities";
import { guardCtx } from "../../context/Guard";
import { appDataCtx } from "../../context/appData";

const Dashboard = ({}) => {
  const theme = useTheme();

  const archivesContext = React?.useContext(archivesCtx);
  const notifContext = React.useContext(notificationCtx);
  const msgContext = React.useContext(messagesCtx);

  const sessionContext = React.useContext(sessionCtx);
  const entitiesContext = React?.useContext(entitiesCtx);

  const startDate = sessionContext?.startDate;
  const setStartDate = sessionContext?.setStartDate;
  const endDate = sessionContext?.endDate;
  const setEndDate = sessionContext?.setEndDate;

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));
  const setLoadingMap = React?.useContext(guardCtx)?.setLoadingMap;

  const [searchValue, setSearchValue] = React?.useState("");

  // console.log("current folders", { folders, filteredFolders });

  const navigate = useNavigate();

  const [timeseries, setTimeseries] = React?.useState([]);
  const [entitiesArchives, setEntitiesArchives] = React?.useState({});

  const appDataContext = React?.useContext(appDataCtx);

  React?.useEffect(() => {
    setLoadingMap(true, "sae_dashboard_process");
    const weekMiliseconds = 604800000;

    /** 
     * we consider data are ranged by creation time descending order

 */

    const series = [];
    const _entitiesArchives = { "0": 0 };

    let baseLimit = {
      x: null,
      y: 0,
    };

    const startLimit = new Date(startDate?.$d)?.setHours(0, 0, 0);
    const endLimit = new Date(endDate?.$d)?.setHours(23, 59, 59);

    let itemsValues = [...archivesContext?.archives];

    if (startLimit && endLimit) {
      itemsValues = itemsValues?.filter((target) => {
        const timestamp = new Date(target?.createdAt)?.getTime();

        return timestamp >= startLimit && endLimit >= timestamp;
      });
    }

    /**
     * area chart data processing &
     */
    itemsValues?.map((document, index, tab) => {
      const currentDate = new Date(document?.createdAt).getTime();

      // to count entities archives and store them for next processing

      if (document?.entities?.length > 0) {
        document?.entities?.forEach((target) => {
          if (_entitiesArchives[target?.id?.toString()] > 0) {
            _entitiesArchives[target?.id?.toString()] += 1;
          } else {
            _entitiesArchives[target?.id ? target?.id?.toString() : "0"] = 1;
          }
        });
      } else {
        _entitiesArchives["0"] += 1;
      }

      if (baseLimit.x === null) {
        baseLimit.x = currentDate;
        baseLimit.y = 1;

        if (index === tab?.length - 1) {
          series.push(baseLimit);
        }
      } else {
        if (baseLimit.x - currentDate <= weekMiliseconds) {
          baseLimit.y = baseLimit.y + 1;

          if (index === tab?.length - 1) {
            series.push(baseLimit);
          }
        } else {
          series.push({ x: currentDate, y: baseLimit.y + 1 });

          console.log("current base limit object", series);

          baseLimit.x = currentDate;
          baseLimit.y = 1;

          /**
           * if last index doesn't match the previous value
           */

          if (index === tab?.length - 1) {
            series.push(baseLimit);
          }
        }
      }

      console.log("current base limit here", index, series, baseLimit);
    });

    console.log("computed entities archives here ", _entitiesArchives, series);

    setTimeseries(series);
    setEntitiesArchives(_entitiesArchives);

    setLoadingMap(false, "sae_dashboard_process");
  }, [startDate, endDate, archivesContext?.archives]);

  const [metrics, setMetrics] = React.useState([]);

  React?.useEffect(() => {
    setLoadingMap(true, "sae_dashboard_process_entities");

    console.log("user's entities in data here", appDataContext?.entitiesIn);

    const role = sessionStorage?.getItem("role")?.toLowerCase();

    const _entities =
      role === "admin" ? entitiesContext?.entities : appDataContext?.entitiesIn;

    let _metrics = [
      ..._entities?.map((target) => {
        return {
          ...target,
          count: entitiesArchives[target?.id?.toString()] || 0,
          icon: (props) => <BarChart {...props} />,
          link: `/settings/entities/${target?.id}?entityId=${target?.id}`,
          color: theme?.palette?.info?.main,
        };
      }),
    ];

    // add non classed docs here only for admins

    if (role === "admin") {
      _metrics.push({
        name: "Sans entité",
        count: entitiesArchives["0"] || 0,
        icon: (props) => <BarChart {...props} />,
        link: `/settings/entities`,
        color: theme?.palette?.common?.black,
      });
    }

    _metrics = _metrics?.sort((prev, next) => {
      return next?.count - prev?.count;
    });

    setMetrics(_metrics);

    setLoadingMap(false, "sae_dashboard_process_entities");
  }, [entitiesContext?.entities, appDataContext?.entitiesIn, entitiesArchives]);

  return (
    <Layout>
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          maxWidth: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          mt: "2rem",
          "& button": {
            textTransform: "none",
          },
          overflowX: "hidden",
          bgcolor: theme?.palette?.secondary?.main,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            ml: "1rem",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              maxWidth: "max-content",
              width: "100%",
              mb: "1rem",
              // bgcolor: theme?.palette?.common?.white,
              borderRadius: "10px",
              //  p: "1rem",
              overflowX: "auto",
              flexWrap: "nowrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {metrics?.length > 0 &&
              metrics?.map((target) => {
                return (
                  <Stack
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(target?.link);
                    }}
                    sx={{
                      bgcolor: `${
                        theme?.palette[target.color]?.main || target?.color
                      }10`,
                      borderRadius: "10px",
                      p: "10px",
                      cursor: "pointer",
                      boxShadow: `0px 0px 3px ${theme?.palette?.grey[300]}`,
                      minWidth: "200px",
                      width: "33.3vw",
                      mx: ".5rem",
                    }}
                  >
                    {target?.icon({
                      sx: {
                        color:
                          theme?.palette[target.color]?.main || target?.color,
                        fontSize: "30px",
                      },
                    })}
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[700],
                        fontWeight: theme?.typography?.fontWeightBold,
                        fontSize: screen900 ? "14px" : "20px",
                        ml: ".1rem",
                      }}
                    >
                      {target?.count}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme?.palette?.grey?.[700],
                        fontSize: screen900 ? "12px" : "14px",
                        fontWeight: theme?.typography?.fontWeightMedium,
                        maxWidth: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        ml: ".1rem",
                      }}
                    >
                      {target?.name}
                    </Typography>
                  </Stack>
                );
              })}
          </Stack>
        </Box>
        {timeseries?.length > 0 && (
          <Grid
            container
            sx={{
              maxWidth: "100%",
              width: "100%",
              mb: "1rem",
            }}
          >
            <Box
              sx={{
                bgcolor: theme?.palette?.common?.white,
                borderRadius: "10px",
                width: "100%",
                py: "1rem",
                px: "1rem",
                mx: "1rem",
              }}
            >
              <Chart
                type={"area"}
                series={[{ data: timeseries, name: "Volume" }]}
                options={{
                  chart: {
                    type: "area",
                    stacked: false,
                    height: 350,
                    zoom: {
                      type: "x",
                      enabled: true,
                      autoScaleYaxis: true,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  markers: {
                    size: 0,
                  },
                  title: {
                    text: "Volume archivé",
                    align: "left",
                  },
                  fill: {
                    type: "gradient",
                    gradient: {
                      shadeIntensity: 1,
                      inverseColors: false,
                      opacityFrom: 0.5,
                      opacityTo: 0,
                      stops: [0, 90, 100],
                    },
                  },
                  xaxis: {
                    type: "datetime",
                  },
                }}
                height={300}
              />
            </Box>
          </Grid>
        )}
        <Box
          sx={{
            width: "100%",
            px: "1rem",
            mb: "2rem",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              bgcolor: theme?.palette?.primary?.main,
              borderRadius: "15px",
              overflow: "hidden",
              width: "100%",
              p: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              border: `1px solid ${theme?.palette?.grey?.[300]}`,
              "& *": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
              "& *:hover": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
              "&:hover *": {
                color: `${theme?.palette?.common?.white}!important`,
                borderColor: `${theme?.palette?.common?.white}!important`,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                my: "1rem",
              }}
            >
              Périodes
            </Typography>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <DatePicker
                label={"Début"}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                sx={{
                  width: "15rem",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
              <DatePicker
                label={"Fin"}
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                sx={{
                  width: "15rem",
                  "& .MuiInputBase-root": {
                    height: "41px",
                  },
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Layout>
  );
};

export default Dashboard;
