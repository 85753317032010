// page component definition
import * as React from "react";

import Layout from "../Layout";
import { useNavigate } from "react-router-dom";

import {
  useTheme,
  Stack,
  Typography,
  Box,
  Grid,
  useMediaQuery,
} from "@mui/material";

import NavigationLine from "../NavigationLine";
import { contactsCtx } from "../../context/contacts";
import { Search } from "react-feather";
import { LocationCity, Person } from "@mui/icons-material";

const Contacts = ({}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const contactsContext = React?.useContext(contactsCtx);

  const contacts = contactsContext?.contacts;

  const screen900 = useMediaQuery(theme?.breakpoints?.down(900));

  const [searchValue, setSearchValue] = React.useState("");

  const [searchedContacts, setSearchedContacts] = React.useState([]);

  const handleSearch = (event) => {
    event?.preventDefault();

    const _search = event?.target?.value;

    setSearchValue(_search);

    setSearchedContacts(
      [...contacts]?.filter((target) => {
        return Object.keys(target)?.some((key) => {
          return target[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(_search?.toString()?.toLowerCase());
        });
      })
    );
  };

  React.useEffect(() => {
    setSearchedContacts([...contacts]);
  }, [contacts]);

  return (
    <Layout>
      <Box
        sx={{
          py: "1rem",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <NavigationLine firstTitle={"Contacts"} secondTitle={"Liste"} />
          <Box
            className="text-field"
            sx={{
              py: ".3rem",
            }}
          >
            <input
              type="text"
              value={searchValue}
              onChange={handleSearch}
              placeholder={"Recherche"}
            />
            <span className="cursor-pointer">
              <Search className="stroke-black" />
            </span>
          </Box>
        </Stack>

        <Grid
          container
          sx={{
            width: "100%",
            maxHeight: !screen900 ? "calc(100vh - 160px)" : undefined,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {searchedContacts?.map((target) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    p: ".5rem",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction={"row"}
                    onClick={(event) => {
                      event?.preventDefault();

                      navigate(
                        `/apps/e-courrier/contacts/${target?.id}?id=${target?.id}`
                      );
                    }}
                    sx={{
                      bgcolor: theme?.palette?.common?.white,
                      borderRadius: "10px",
                      width: "100%",
                      px: ".7rem",
                      py: "1rem",
                      cursor: "pointer",
                      justifyContent: "space-between",
                      "&:hover": {
                        boxShadow: "0px 2px 20px -4px #cddde1",
                      },
                      // border: `2px solid ${theme?.palette?.primary?.main}`,
                      // borderLeft: `5px solid ${theme?.palette?.primary?.main}`,
                    }}
                  >
                    <Stack
                      sx={{
                        width: "max-content",
                        height: "max-content",
                        p: "1rem",
                        border: `2px solid ${theme?.palette?.primary?.main}`,
                        borderRadius: "50%",
                        overflow: "hidden",
                        mr: ".5rem",
                      }}
                    >
                      {target?.type === "corporate" ? (
                        <LocationCity
                          sx={{
                            fontSize: "50px",
                            color: theme?.palette?.primary?.main,
                          }}
                        />
                      ) : (
                        <Person
                          sx={{
                            fontSize: "50px",
                            color: theme?.palette?.primary?.main,
                          }}
                        />
                      )}
                    </Stack>
                    <Grid
                      container
                      sx={{
                        flex: 1,
                        overflowX: "hidden",
                      }}
                    >
                      {[
                        { name: "Noms", field: "name" },
                        { name: "Email", field: "email" },
                        { name: "Adresse", field: "address" },
                        { name: "Télephone", field: "phoneNumber" },
                      ]?.map((_target) => {
                        return (
                          <Grid
                            item
                            sm={_target?.name === "Nom" ? 12 : 12}
                            xs={12}
                            sx={{}}
                          >
                            <Typography
                              sx={{
                                color:
                                  _target?.name === "Noms"
                                    ? theme?.palette?.primary?.main
                                    : theme?.palette?.common?.black,
                                fontStyle:
                                  _target?.name === "Noms"
                                    ? "normal"
                                    : "italic",
                                fontSize:
                                  _target?.name === "Noms" ? "14px" : "13px",
                                fontWeight:
                                  _target?.name === "Noms"
                                    ? theme?.typography?.fontWeightBold
                                    : theme?.typography?.fontWeightRegular,
                                textTransform: "capitalize",
                                lineHeight: "15px",
                                mb:
                                  _target?.name === "Noms"
                                    ? ".2rem"
                                    : undefined,
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {target[_target?.field]}
                            </Typography>{" "}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Layout>
  );
};

export default Contacts;
