/**
 *  mailing central apis
 */

import { BASE_URL } from "../../../constants/api";
import getHeaders from "../../../utils/getHeaders";
import lookup from "../../../utils/lookup";

class MailApi {
    static async send({ payload}) {
        let status = null;

        await lookup(`${BASE_URL}/api/mailing`, {
            method: "POST",
            headers: getHeaders({}),
            body: JSON.stringify({data: payload})
        }).then(res => res.json().then(result => {
            if (result) {
                status = result
            }
        })).catch(error => {
            console.log("an error has occured when sending email", error);
            
        })

        return status
    }
}

export default MailApi
