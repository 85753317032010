// messages api central

import { BASE_URL } from "../../../constants/api"
import getHeaders from "../../../utils/getHeaders"
import lookup from "../../../utils/lookup"
import strapiSanitize from "../../../utils/strapiSanitize";

class MessagesApi {
    static async create({data}) {

        const status = {};

        await lookup(`${BASE_URL}/api/annotations`, {
            method: "POST",
            body: JSON.stringify({
                data: data
            }),
            headers: getHeaders({})
        }).then(result => result?.json().then(result => {
            console.log("received data after  created a message", result, data);
            
        })).catch(error => {
            console.log("an error has occured when creating ");
            
        })

        return status
    }

    static async getOne({ userId,}) {
        let messages = [];

        await lookup(`${BASE_URL}/api/annotations?populate=*&filters[$or][0][tos][id][$eq]=${userId}&filters[$or][1][from][id][$eq]=${userId}`, {
            headers: getHeaders({}),

        }).then(result => result.json().then(result => {


        console.log("received user's messages here", result);
            
            messages =  result?.data?.map(target => {
                return {
                  id: target?.id,
                  ...target?.attributes,
                  from: {
                    id: target?.attributes?.from?.data?.id?.toString(),
                    name: target?.attributes?.from?.data?.attributes?.username,
                  },
                  tos: target?.attributes?.tos?.data?.map(target => {
                    return {
                      id: target?.id?.toString(),
                      name: target?.attributes?.username,
                    }
                  })
                }
              });
        })).catch(error => {
            console.log("an error has occured when fetching user's messages", error);   
        });

        return messages
    }

    static async getTotalCount({ userId}) {
        let totalCount ;

        await lookup(`${BASE_URL}/api/annotations?fields[0]=id&filters[$or][0][tos][id][$eq]=${userId}&filters[$or][1][from][id][$eq]=${userId}`, {
            headers: getHeaders({}),
            method: "GET"
        }).then(res => res.json().then(result => {
            console.log("received annotations list count", result);

            const count = result?.meta?.pagination?.total
            
            if (parseInt(count) >= 0) {
                totalCount = count
            }
        })).catch(error => {
            console.log("an error has occured when trying to get the total messages count", error);
            
        })

        return {totalCount}
    }

    static async fromNotificationFrom ( {userId, fromId, documentId}) {
        let messages = [];

        if (parseInt(fromId) > 0) {
            await lookup(`${BASE_URL}/api/annotations?populate=*&filters[$or][0][$and][0][tos][id][$eq]=${userId}&filters[$or][0][$and][1][from][id][$eq]=${fromId}&filters[$or][1][$and][0][tos][id][$eq]=${fromId}&filters[$or][1][$and][1][from][id][$eq]=${userId}&filters[document][id][$eq]=${documentId}`, {
                headers: getHeaders({}),
                method: "GET"
            }).then(res => res.json().then(result => {
    
                const data = result?.data;
    
                if (Array.isArray(data)) {
                    messages = strapiSanitize({object : data})?.map(target => {
                        return {
                            ...target,
                            notification: {
                                id: target?.notification?.data?.id,
                                ...target?.notification?.data?.attributes
                            },
                            from: {
                                id: target?.from?.data?.id,
                                ...target?.from?.data?.attributes
                            },
                            tos: strapiSanitize({object: target?.tos?.data})
                        }
                    });
                }
    
                console.log("notification from notif sender data here", {messages}, {userId, fromId, documentId, url : `${BASE_URL}/api/annotations?populate=*&filters[$or][0][tos][id][$eq]=${userId}&filters[$or][1][from][id][$eq]=${fromId}&filters[document][id][$eq]=${documentId}`}, );
            })).catch(error => {
                console.log("an error has occured when fetching annotation data from notif sender", error);
                
            })
        }

        return messages
    }
}

export default MessagesApi
