// context defintion

import * as React from "react";
import { BASE_URL } from "../constants/api";
import ContactApi from "../helpers/e-courrier/requests/contacts";
import lookup from "../utils/lookup";
import { guardCtx } from "./Guard";
import { sessionCtx } from "./session";

const contactsCtx = React.createContext({});

const ContactsContext = ({ children }) => {
  const [contacts, setContacts] = React.useState([]);

  const isUserAuthenticanted = React.useContext(guardCtx)?.isUserAuthenticanted;
  const currentApp = React?.useContext(sessionCtx)?.session?.currentAppName;

  const refreshContacts = async () => {
    await ContactApi.getAll({})
      .then((contacts) => {
        console.log("received contacts", contacts);

        if (Array.isArray(contacts)) {
          setContacts(contacts);
        }
      })
      .catch((error) => {
        console.log("an error has occured when fetching all contacts");
      });
  };

  React.useEffect(() => {
    (async () => {
      if (isUserAuthenticanted) {
        if (currentApp?.toLowerCase() === "vizioo gec") {
          await refreshContacts();
        }
      }
    })();
  }, [isUserAuthenticanted, currentApp]);

  return (
    <contactsCtx.Provider
      value={{
        contacts,
        refreshContacts,
      }}
    >
      {children}
    </contactsCtx.Provider>
  );
};

export { contactsCtx };
export default ContactsContext;
