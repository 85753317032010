// component definition
import * as React from "react";

import {
  useTheme,
  Box,
  Stack,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Checkbox,
  Switch,
  FormControlLabel,
} from "@mui/material";

import DragUpload from "./DragUpload";
import { rolesCtx } from "../../context/rolesCtx";
import { contactsCtx } from "../../context/contacts";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { entitiesCtx } from "../../context/entities";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { usersCtx } from "../../context/users";
import { courriersCtx } from "../../context/courriers";
import OperationApi from "../../helpers/e-courrier/requests/operations";
import { settingsCtx } from "../../context/settings";

const senderFields = [
  {
    title: "Contact",
    field: "senderFrom",
    required: true,
  },
  {
    title: "Porteur",
    field: "submitter",
    required: true,
  },
  {
    title: "Date d'entrée",
    field: "entryDate",
    required: true,
  },
  {
    title: "Heure d'entrée",
    field: "entryTime",
    required: true,
  },
  {
    title: "Commentaires",
    field: "comments",
    required: true,
  },
];

const uploadFields = [];

const informationFields = {
  "Informations Générales": [
    {
      title: "Référence",
      field: "reference",
      required: true,
    },
    {
      title: "Nom du courrier",
      field: "name",
      required: true,
    },
    {
      title: "Type",
      field: "type",
      required: true,
      options: [
        { title: "Entrant", value: "incoming" },
        {
          title: "Sortant",
          value: "outgoing",
        },
      ],
    },
    {
      title: "A frêter",
      field: "freighted",
      required: true,
      options: [
        { title: "Non", value: false },
        {
          title: "Oui",
          value: true,
        },
      ],
    },
    {
      title: "Urgence",
      field: "emergency",
      required: true,
      options: [
        { title: "Faible", value: "low" },
        {
          title: "Moyenne",
          value: "medium",
        },
        {
          title: "Haute",
          value: "high",
        },
      ],
    },
  ],
  Classement: [
    {
      title: "Le document est un accusé reception",
      field: "isAcknowledgementOfReceipt",
    },
    {
      title: "Le document est une réponse à un courrier",
      field: "isMailResponse",
    },
    {
      title: "Courrier sous-jacent",
      field: "underlyingMail",
    },
  ],
  "Zone Frêt": [
    {
      title: "Poids",
      field: "weight",
      required: true,
      type: "number",
    },
    {
      title: "Coût total",
      field: "cost",
      required: true,
      type: "number",
    },
    {
      title: "Destination",
      field: "destination",
      required: true,
    },
  ],
  Traitement: [
    {
      title: "Secrétariat",
      field: "secretary",
      required: true,
    },
  ],
};

const Expedition = ({
  datesMap,
  agents,
  setDateMap,
  contacts,
  handleAgents,
  setFieldsMap,
  fieldsMap,
}) => {
  const theme = useTheme();

  const editedDocId = new URLSearchParams(window?.location?.search).get(
    "mail_id"
  );

  return (
    <Stack
      direction={"column"}
      sx={{
        alignItems: "flex-start",
        width: "100%",
        p: "1.5rem",
      }}
    >
      <Typography
        sx={{
          color: theme?.palette?.primary?.main,
          fontWeight: theme?.typography?.fontWeightBold,
        }}
      >
        Zone expéditeur
      </Typography>
      <Grid
        container
        sx={{
          width: "100%",
        }}
      >
        {senderFields
          ?.filter((target) =>
            editedDocId
              ? !["senderFrom", "submitter"]?.includes(target?.field)
              : true
          )
          ?.map((target, index) => {
            return target?.field === "senderFrom" ? (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                sx={{
                  p: ".5rem",
                }}
              >
                <Autocomplete
                  onChange={(event, newContact) => {
                    handleAgents("contact", newContact);
                  }}
                  size={"small"}
                  id="contacts--el"
                  options={contacts}
                  value={
                    Object.keys(agents["contact"])?.length > 0
                      ? agents["contact"]
                      : null
                  }
                  getOptionLabel={(option) => option?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>{option?.name}</li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={target?.title}
                      placeholder={target?.title}
                    />
                  )}
                />
              </Grid>
            ) : target?.field === "entryDate" ? (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                sx={{
                  p: ".5rem",
                }}
              >
                <DatePicker
                  label={target?.title}
                  value={datesMap[target?.field]}
                  onChange={(newValue) =>
                    setDateMap({ ...datesMap, [target?.field]: newValue })
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "41px",
                    },
                  }}
                />
              </Grid>
            ) : target?.field === "entryTime" ? (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                sx={{
                  p: ".5rem",
                }}
              >
                <TimePicker
                  label={target?.title}
                  value={datesMap[target?.field]}
                  onChange={(newValue) =>
                    setDateMap({
                      ...datesMap,
                      [target?.field]: newValue,
                    })
                  }
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "41px",
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                sx={{
                  p: ".5rem",
                }}
              >
                <TextField
                  name={target?.field}
                  placeholder={target?.title}
                  label={target?.title}
                  type={
                    target?.field === "password"
                      ? "password"
                      : target?.field === "email"
                      ? "email"
                      : "text"
                  }
                  size={"small"}
                  // autoComplete="new-password"
                  sx={{
                    width: "100%",
                  }}
                  required={target?.required}
                  rows={target?.field === "comments" ? 4 : 1}
                  multiline={target?.field === "comments" ? true : false}
                  onChange={(event) => {
                    event?.preventDefault();

                    setFieldsMap({
                      ...fieldsMap,
                      [target?.field]: event?.target?.value,
                    });
                  }}
                  value={fieldsMap[target?.field] || null}
                />
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};

const Informations = ({
  setFieldsMap,
  fieldsMap,
  handleAgents,
  agentsMap,
  handleDocumentSelect,
  agents,
  underlyingMail,
}) => {
  const theme = useTheme();

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const courriersContext = React.useContext(courriersCtx);
  const editedDocId = new URLSearchParams(window?.location?.search).get(
    "mail_id"
  );

  return (
    <Stack
      direction={"column"}
      sx={{
        alignItems: "flex-start",
        width: "100%",
        p: "1.5rem",
      }}
    >
      {Object.keys(informationFields)
        ?.filter(
          (key) =>
            key === "Zone Frêt"
              ? fieldsMap["freighted"]
              : ["Traitement", "Classement"]?.includes(key)
              ? !Boolean(editedDocId)
              : true
          // && (key === "Classement" ? fieldsMap["type"] === "incoming" : true)
        )
        ?.map((key, index) => {
          return (
            <Box
              key={key}
              sx={{
                width: "100%",
                mt: index > 0 ? ".5rem" : undefined,
              }}
            >
              <Typography
                sx={{
                  color: theme?.palette?.primary?.main,
                  fontWeight: theme?.typography?.fontWeightBold,
                }}
              >
                {key}
              </Typography>
              <Grid
                container
                sx={{
                  width: "100%",
                }}
              >
                {informationFields[key]
                  ?.filter((target) =>
                    editedDocId
                      ? !["type", "emergency", "reference"]?.includes(
                          target?.field
                        )
                      : true
                  )
                  ?.map((target, index) => {
                    return [
                      "type",
                      "importance",
                      "emergency",
                      "freighted",
                    ]?.includes(target?.field) ? (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        sx={{
                          p: ".2rem",
                        }}
                      >
                        <FormControl
                          variant="outlined"
                          sx={{ width: "100%" }}
                          size={"small"}
                          autoComplete="off"
                        >
                          <InputLabel>{target?.title}</InputLabel>
                          <Select
                            size={"small"}
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label={target?.title}
                            placeholder={target?.title}
                            name={target?.field}
                            required={target?.required}
                            onChange={(event) => {
                              event?.preventDefault();

                              setFieldsMap({
                                ...fieldsMap,
                                [target?.field]: event?.target?.value,
                              });
                            }}
                            value={fieldsMap[target?.field]}
                          >
                            {target?.options?.map((target, index) => {
                              return (
                                <MenuItem key={index} value={target?.value}>
                                  {target?.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : [
                        "directions",
                        "departments",
                        "teams",
                        "employees",
                        "secretary",
                      ]?.includes(target?.field) ? (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        sx={{
                          p: ".2rem",
                        }}
                      >
                        <Autocomplete
                          size={"small"}
                          onChange={(event, values) => {
                            event?.preventDefault();
                            handleAgents(target?.field, values);
                          }}
                          multiple={!["secretary"]?.includes(target?.field)}
                          id="agent--ddl"
                          options={agentsMap[target?.field]}
                          value={agents[target?.field]}
                          getOptionLabel={(option) =>
                            option?.name || option?.fullName
                          }
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option?.name || option?.fullName}
                            </li>
                          )}
                          style={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={target?.title}
                              placeholder={target?.title}
                            />
                          )}
                        />
                      </Grid>
                    ) : target?.field === "underlyingMail" ? (
                      (fieldsMap["isAcknowledgementOfReceipt"] ||
                        fieldsMap["isMailResponse"]) && (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={6}
                          sx={{
                            p: ".2rem",
                          }}
                        >
                          <Autocomplete
                            size="small"
                            disablePortal
                            onChange={handleDocumentSelect}
                            id="combo-box-demo"
                            options={[...courriersContext.pendingCourriers]}
                            // defaultValue={searchedEntities[0] || null}
                            value={underlyingMail || null}
                            getOptionLabel={(option) => `${option?.title}`}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>{`${option?.title}`}</li>
                            )}
                            // style={{ width: "100%" }}

                            sx={{
                              minWidth: "100%",
                              width: "100%",
                              maxWidth: "100%",
                            }}
                            renderInput={(params) => (
                              <TextField
                                size={"small"}
                                {...params}
                                // label={title}
                                placeholder={target?.title}
                                variant={"outlined"}
                              />
                            )}
                          />
                        </Grid>
                      )
                    ) : [
                        "isAcknowledgementOfReceipt",
                        "isMailResponse",
                      ]?.includes(target?.field) ? (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        sx={{
                          p: ".2rem",
                        }}
                      >
                        <FormControlLabel
                          onClick={(event) => {
                            event?.preventDefault();

                            if (
                              target?.field === "isAcknowledgementOfReceipt"
                            ) {
                              if (
                                !Boolean(
                                  fieldsMap["isAcknowledgementOfReceipt"]
                                )
                              ) {
                                setFieldsMap({
                                  ...fieldsMap,
                                  ["isAcknowledgementOfReceipt"]: true,
                                  ["isMailResponse"]: false,
                                });
                              } else {
                                setFieldsMap({
                                  ...fieldsMap,
                                  ["isAcknowledgementOfReceipt"]: false,
                                });
                              }
                            } else {
                              if (!Boolean(fieldsMap["isMailResponse"])) {
                                setFieldsMap({
                                  ...fieldsMap,
                                  ["isMailResponse"]: true,
                                  ["isAcknowledgementOfReceipt"]: false,
                                });
                              } else {
                                setFieldsMap({
                                  ...fieldsMap,
                                  ["isMailResponse"]: false,
                                });
                              }
                            }
                          }}
                          control={
                            <Switch checked={fieldsMap[target?.field]} />
                          }
                          label={target?.title}
                          style={{
                            fontSize: "12px",
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        sm={6}
                        sx={{
                          p: ".2rem",
                        }}
                      >
                        <TextField
                          name={target?.field}
                          placeholder={target?.title}
                          label={target?.title}
                          type={target?.type || "text"}
                          size={"small"}
                          // autoComplete="new-password"
                          sx={{
                            width: "100%",
                          }}
                          required={target?.required}
                          onChange={(event) => {
                            event?.preventDefault();

                            setFieldsMap({
                              ...fieldsMap,
                              [target?.field]: event?.target?.value,
                            });
                          }}
                          value={fieldsMap[target?.field] || null}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          );
        })}
    </Stack>
  );
};

const Upload = ({}) => {
  const theme = useTheme();

  return (
    <Stack
      direction={"column"}
      sx={{
        alignItems: "flex-start",
        width: "100%",
        p: "1.5rem",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
        }}
      >
        {uploadFields?.map((target, index) => {
          return (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              sx={{
                p: ".5rem",
              }}
            >
              <TextField
                name={target?.field}
                placeholder={target?.title}
                type={
                  target?.field === "password"
                    ? "password"
                    : target?.field === "email"
                    ? "email"
                    : "text"
                }
                size={"small"}
                // autoComplete="new-password"
                sx={{
                  width: "100%",
                }}
                required={target?.required}
                onChange={(event) => {
                  event?.preventDefault();

                  setFieldsMap({
                    ...fieldsMap,
                    [target?.field]: event?.target?.value,
                  });
                }}
                value={fieldsMap[target?.field] || null}
              />
            </Grid>
          );
        })}
      </Grid>
      <DragUpload />
    </Stack>
  );
};

const ConfigureStep = ({ step }) => {
  const theme = useTheme();

  const editedDocId = new URLSearchParams(window?.location?.search).get(
    "mail_id"
  );

  const entitiesContext = React.useContext(entitiesCtx);
  const users = React.useContext(usersCtx)?.users;

  const directions = entitiesContext.directions;
  const departments = entitiesContext.departments;
  const teams = entitiesContext.teams;

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const [underlyingMail, setUnderlyingMail] = React.useState(null);

  const handleDocumentSelect = (event, document) => {
    event?.preventDefault();

    setUnderlyingMail(document);
  };

  React.useEffect(() => {
    // store selected doc

    sessionStorage.setItem(
      "__underlying_doc__",
      JSON.stringify(underlyingMail)
    );
  }, [underlyingMail]);

  const handleAgents = (slug, newAgents) => {
    setAgents({
      ...agents,
      [slug]: newAgents,
    });
  };

  const [agents, setAgents] = React.useState({
    employees: [],
    directions: [],
    departments: [],
    teams: [],
    secretary: null,
    contact: {},
  });

  React.useEffect(() => {
    sessionStorage.setItem("__agents__", JSON.stringify(agents));
  }, [agents]);

  const agentsMap = {
    employees: users?.filter((target) => {
      return target?.id?.toString() !== sessionStorage?.getItem("userId");
    }),
    directions,
    departments,
    teams,
    secretary: users?.filter((target) => {
      return target?.id?.toString() !== sessionStorage?.getItem("userId");
    }),
  };

  console.log("current agents map", agentsMap);

  const [courrierType, setCourrierType] = React.useState("incoming");

  const handleCourrierType = (event) => {
    event?.preventDefault();

    const _type = event?.target?.value;

    setCourrierType(_type);
  };

  React.useEffect(() => {
    sessionStorage.setItem("courrier-type", courrierType);
  }, [courrierType]);

  const [datesMap, setDateMap] = React.useState({
    entryDate: dayjs(new Date().toISOString()),
    entryTime: dayjs(new Date().toISOString()),
  });

  React.useEffect(() => {
    sessionStorage?.setItem("__dates__", JSON.stringify(datesMap));
  }, [datesMap]);

  const contacts = React.useContext(contactsCtx)?.contacts;

  const [fieldsMap, setFieldsMap] = React.useState({
    ...Object.keys(informationFields["Informations Générales"])
      ?.filter((key) =>
        Array.isArray(informationFields["Informations Générales"][key]?.options)
      )
      .reduce((obj, key) => {
        obj[informationFields["Informations Générales"][key]?.field] =
          informationFields["Informations Générales"][key]?.options[0]?.value;

        return obj;
      }, {}),
  });

  console.log("current fields map", fieldsMap);

  React.useEffect(() => {
    if (!fieldsMap?.freighted) {
      ["weight", "cost", "destination"]?.forEach((key) => {
        delete fieldsMap[key];
      });
    }

    console.log("current fieldsMap", fieldsMap);

    sessionStorage.setItem("__fields__", JSON.stringify(fieldsMap));
  }, [fieldsMap]);

  const settingContext = React.useContext(settingsCtx);

  const refPrefix = settingContext?.referencePrefix;
  const orgName = settingContext?.companyObj?.sigle;

  React.useEffect(() => {
    let properRefNo = "Aucune";

    (async () => {
      if (fieldsMap.type === "outgoing" && !Boolean(editedDocId)) {
        await OperationApi.getReference({ orgName, refPrefix })
          .then((refObj) => {
            sessionStorage.setItem("proper-reference", refObj.text);

            properRefNo = refObj.text;
          })
          .catch((error) => {
            console.log("an error has occured fetching reference", error);
          });
      }

      setFieldsMap({
        ...fieldsMap,
        reference: properRefNo,
      });
    })();
  }, [fieldsMap.type]);

  const finalElement = {
    1: (
      <Expedition
        agents={agents}
        contacts={contacts}
        handleAgents={handleAgents}
        datesMap={datesMap}
        setDateMap={setDateMap}
        fieldsMap={fieldsMap}
        setFieldsMap={setFieldsMap}
      />
    ),
    2: (
      <Informations
        agents={agents}
        agentsMap={agentsMap}
        fieldsMap={fieldsMap}
        handleAgents={handleAgents}
        setFieldsMap={setFieldsMap}
        underlyingMail={underlyingMail}
        handleDocumentSelect={handleDocumentSelect}
      />
    ),
    3: <Upload />,
  };

  return finalElement[step];
};

export default ConfigureStep;
